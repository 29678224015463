import React from "react";
import {convertIntoINRV2} from "../../utils/helpers";

interface OrderDetailsCardProps {
  totalItems: number;
  priceIncludingGST: number;
  discount: number;
  deliveryCharge: number | null | undefined;
  orderTotal: number;
  couponDiscount?: number;
}

const OrderDetails: React.FC<OrderDetailsCardProps> = ({
  totalItems,
  priceIncludingGST,
  discount,
  deliveryCharge,
  orderTotal,
  couponDiscount,
}) => {
  return (
    <div className="order-summary-details-container">
      <div className="order-summary-details-flex-container">
        <div>
          <span className="order-summary-details-item-text">Items</span>
        </div>
        <div>
          <span className="order-summary-details-item-text">{totalItems}</span>
        </div>
      </div>
      <div className="order-summary-details-flex-container">
        <div>
          <span className="order-summary-details-item-text">Price</span>
        </div>
        <div>
          <span className="order-summary-details-item-text">
            ₹{priceIncludingGST}
          </span>
        </div>
      </div>

      {couponDiscount ? (
        <div className="order-summary-details-flex-container">
          <div>
            <span
              className="order-summary-details-item-text"
              style={{color: "#0B801E"}}>
              Coupon Discount
            </span>
          </div>
          <div>
            <span
              className="order-summary-details-item-text"
              style={{color: "#0B801E"}}>
              -₹{couponDiscount}
            </span>
          </div>
        </div>
      ) : null}

      {discount ? (
        <div className="order-summary-details-flex-container">
          <div>
            <span
              className="order-summary-details-item-text"
              style={{color: "#0B801E"}}>
              Discount
            </span>
          </div>
          <div>
            <span
              className="order-summary-details-item-text"
              style={{color: "#0B801E"}}>
              -₹{discount}
            </span>
          </div>
        </div>
      ) : null}

      <div className="order-summary-details-flex-container">
        <div>
          <span className="order-summary-details-item-text">
            Delivery charge
          </span>
        </div>
        <div>
          <span className="order-summary-details-item-text">
            {/* ₹{deliveryCharge} */}
            {deliveryCharge === 0
              ? "Free"
              : convertIntoINRV2(deliveryCharge?.toString() || "")}
          </span>
        </div>
      </div>

      <div className="order-summary-details-flex-container">
        <div>
          <span className="order-summary-details-order-total">Order Total</span>
        </div>
        <div>
          <span className="order-summary-details-order-total">
            ₹{orderTotal}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
