/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import "./OrderSummary.css";
import {
  calculateDeliveryChargesBuyNow,
  getAddressStringFromObject,
} from "../../utils/helpers";
import {AppDispatch, RootState} from "../../interceptors/store";
import {useDispatch, useSelector} from "react-redux";
import {getStoreFrontDetailsThunk} from "../../features/User/UserSlice";
import Juspay from "../Juspay/Juspay";
import {useLocation} from "react-router";
import {DeleteSvg} from "../SvgImages/DeleteSvg/DeleteSvg";

interface OrderSummaryCard {
  setIsAddAddressOpen: Dispatch<SetStateAction<boolean | false>>;
  product: any;
  storeId: string;
  couponDiscount: number;
  setCartValue: Dispatch<SetStateAction<number | 0>>;
}

interface Product {
  product_id: string;
  name: string;
  seller_name: string;
  cost_price: number;
  selling_price: number;
  size: string;
  color: string;
  quantity: number;
  imageUrl: string;
  varient_id: string;
  cartIds: number[];
}

const OrderSummary: React.FC<OrderSummaryCard> = ({
  product,
  storeId,
  setCartValue,
  couponDiscount,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const currentUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

  useEffect(() => {
    dispatch(
      getStoreFrontDetailsThunk({store_name: "", storeId, type: "details"}),
    );
  }, [dispatch, storeId]);

  const {details} = useSelector((state: RootState) => state.user.storeFront);
  const {user, selectedAddress} = useSelector((state: RootState) => state.user);
  const [productData, setProductData] = useState<Product>({
    product_id: product.product_id,
    name: product.name,
    seller_name: details?.store_name ?? "",
    cost_price: product.cost_price,
    selling_price: product.selling_price,
    size: product?.variantValueDetails[0]?.varient_name ?? "",
    color: product?.variantValueDetails[1]?.varient_name ?? "",
    quantity: 1,
    imageUrl: product?.images[0],
    varient_id: product.varient_id,
    cartIds: [],
  });

  useEffect(() => {
    setProductData(prev => ({
      ...prev,

      product_id: product.product_id,
      name: product.name,
      seller_name: details?.store_name ?? "",
      cost_price: product.cost_price,
      selling_price: product.selling_price,
      color: product.variantValueDetails[0]?.varient_name ?? "",
      size: product.variantValueDetails[1]?.varient_name ?? "",
      quantity: 1,
      imageUrl: product?.images[0],
      varient_id: product.varient_id,
      cartIds: [],
    }));
  }, [product]);

  const [deliveryCharge, setDeliveryCharge] = useState<number>(0);
  const [checkoutAmount, setCheckoutAmount] = useState<number>(
    product.selling_price + deliveryCharge - couponDiscount,
  );
  const [initiatePayment, setInitiatePayment] = useState(false);
  const [, setPaymentLoading] = useState(false);

  // Update delivery charge and checkout amount when product or store details change
  useEffect(() => {
    const fetchDeliveryFee = async () => {
      if (details) {
        const charge = await calculateDeliveryChargesBuyNow(
          details,
          productData,
        );
        setDeliveryCharge(charge);
      }
    };

    fetchDeliveryFee();
  }, [details, product]);

  // Update checkout amount whenever productData quantity or delivery charge changes
  useEffect(() => {
    const productPrice = productData.quantity * productData.selling_price;
    const totalAmount =
      productPrice + deliveryCharge - Math.floor(couponDiscount);
    setCheckoutAmount(totalAmount);
    setCartValue(totalAmount);
  }, [productData, deliveryCharge, couponDiscount]);

  const increaseQuantity = () => {
    if (product.product_total_quantity > productData.quantity) {
      setProductData(prevProductData => ({
        ...prevProductData,
        quantity: prevProductData.quantity + 1,
      }));
    } else {
      console.log("throw error");
    }
  };

  const decreaseQuantity = () => {
    if (productData.quantity > 1) {
      setProductData(prevProductData => ({
        ...prevProductData,
        quantity: prevProductData.quantity - 1,
      }));
    }
  };

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    console.log("Image failed to load, setting fallback...");
    e.currentTarget.src =
      "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png";
  };

  const productVariant =
    productData.color && productData.size !== ""
      ? productData.color + "/" + productData.size
      : productData.color || productData.size;

  return (
    <div className="delivery-card-container-main">
      <div className="order-summary-card-header-container">
        {/* Left Section */}
        <div className="order-summary-card-header-left-container">
          <span className="order-summary-card">Order summary</span>
        </div>
      </div>

      <div className="order-summary-product-card">
        <div className="order-summary-product-card-img">
          <img src={productData.imageUrl} onError={handleError} />
        </div>

        <div className="order-summary-product-details">
          <div className="order-summary-product-content">
            <p className="order-summary-product-brand">
              {productData.seller_name}
            </p>
            <p className="order-summary-product-name">{productData.name}</p>
            <p className="order-summary-product-variant">{productVariant}</p>
          </div>

          <div className="product-quantity-controls">
            <div className="quantity-selector">
              <button className="quantity-button" onClick={decreaseQuantity}>
                {productData.quantity == 1 ? (
                  <DeleteSvg width={16} height={16} />
                ) : (
                  "-"
                )}
              </button>
              <div className="quantity-display">
                {productData.quantity.toString().padStart(2, "0")}
              </div>
              <button className="quantity-button" onClick={increaseQuantity}>
                +
              </button>
            </div>

            <div className="order-summary-product-price">
              <div className="order-summary-price-original">
                ₹{product.cost_price}
              </div>
              <div className="order-summary-price-discounted">
                ₹{product.selling_price}
              </div>
            </div>
          </div>
        </div>
      </div>

      <OrderDetails
        totalItems={productData.quantity}
        priceIncludingGST={product.selling_price}
        discount={product.cost_price - product.selling_price}
        deliveryCharge={deliveryCharge}
        orderTotal={checkoutAmount ?? 0}
        couponDiscount={couponDiscount}
      />

      <div
        className="order-summary-details-checkout-button"
        onClick={() => {
          setInitiatePayment(true);
        }}>
        <button type="submit" className="address-form-submit-button-active">
          Checkout ₹{checkoutAmount}
        </button>
      </div>

      {/* payment gateway section */}
      {initiatePayment && (
        <Juspay
          userId={user?.user_id || ""}
          initiate={initiatePayment}
          redirectUrl={currentUrl}
          // streamId={streamId}
          product={productData}
          addressId={selectedAddress?.id?.toString() || ""}
          storeId={storeId || ""}
          deliveryAddress={getAddressStringFromObject(selectedAddress)}
          amount={parseInt(checkoutAmount?.toString() || "0")}
          onInitiationComplete={() => {
            setPaymentLoading(false);
          }}
          onInitiationFailure={() => {
            setPaymentLoading(false);
            setInitiatePayment(false);
          }}
          onCancel={() => {
            setPaymentLoading(false);
            setInitiatePayment(false);
            // onClose();
          }}
          type="buyNow"
        />
      )}
    </div>
  );
};

interface OrderDetailsCardProps {
  totalItems: number;
  priceIncludingGST: number;
  discount: number;
  deliveryCharge: number | null | undefined;
  orderTotal: number;
  couponDiscount: number;
}

const OrderDetails: React.FC<OrderDetailsCardProps> = ({
  totalItems,
  priceIncludingGST,
  deliveryCharge,
  orderTotal,
  couponDiscount,
}) => {
  return (
    <div className="order-summary-details-container">
      <div className="order-summary-details-flex-container">
        <div>
          <span className="order-summary-details-item-text">No. of item</span>
        </div>
        <div>
          <span className="order-summary-details-item-text">{totalItems}</span>
        </div>
      </div>
      <div className="order-summary-details-flex-container">
        <div>
          <span className="order-summary-details-item-text">Price</span>
        </div>
        <div>
          <span className="order-summary-details-item-text">
            ₹{priceIncludingGST}
          </span>
        </div>
      </div>

      {couponDiscount > 0 ? (
        <div className="order-summary-details-flex-container">
          <div>
            <span
              className="order-summary-details-item-text"
              style={{color: "#0B801E"}}>
              Discount
            </span>
          </div>
          <div>
            <span
              className="order-summary-details-item-text"
              style={{color: "#0B801E"}}>
              -₹{Math.floor(couponDiscount)}
            </span>
          </div>
        </div>
      ) : null}

      <div className="order-summary-details-flex-container">
        <div>
          <span className="order-summary-details-item-text">
            Delivery charge
          </span>
        </div>
        <div>
          <span className="order-summary-details-item-text">
            ₹{deliveryCharge}
          </span>
        </div>
      </div>

      <div className="order-summary-details-flex-container">
        <div>
          <span className="order-summary-details-order-total">Order Total</span>
        </div>
        <div>
          <span className="order-summary-details-order-total">
            ₹{orderTotal}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
