import React, {useEffect, ReactNode, useRef} from "react";
import "./BottomSheetV2.style.css";

interface BottomSheetV2Props {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  footer?: ReactNode;
}

const BottomSheetV2: React.FC<BottomSheetV2Props> = ({
  isOpen,
  onClose,
  children,
  footer,
}) => {
  // const [isDragging, setIsDragging] = useState(false);
  // const [currentHeight, setCurrentHeight] = useState(0);
  // const [isClosing, setIsClosing] = useState(false);
  const sheetRef = useRef<HTMLDivElement>(null);
  // const startYRef = useRef(0);
  // const startHeightRef = useRef(0);
  const contentHeightRef = useRef(0);
  const contentRef = useRef<HTMLDivElement | null>(null); // Ref for the content

  useEffect(() => {
    if (isOpen && sheetRef.current && contentRef.current) {
      document.body.style.overflow = "hidden";

      requestAnimationFrame(() => {
        if (sheetRef.current && contentRef.current) {
          const contentHeight = contentRef.current.scrollHeight;
          const sheetHeight = sheetRef.current.scrollHeight;
          const viewportHeight = window.innerHeight;
          let heightInVh =
            ((contentHeight + (sheetHeight - contentHeight)) / viewportHeight) *
            100;
          heightInVh = Math.min(heightInVh, 90); // Cap at 90vh
          contentHeightRef.current = heightInVh; // Save for snap logic

          // setCurrentHeight(heightInVh);
        }
      });
    } else {
      document.body.style.overflow = "";
      // setCurrentHeight(0);
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen, children]);

  // const updateHeight = (height: number) => {
  //   const clampedHeight = Math.min(Math.max(height, 0), 90);
  //   setCurrentHeight(clampedHeight);
  //   if (sheetRef.current) {
  //     sheetRef.current.style.height = `${clampedHeight}vh`;
  //   }
  // };

  // const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
  //   setIsDragging(true);
  //   const pageY =
  //     "touches" in e ? e.touches[0].pageY : (e as React.MouseEvent).pageY;
  //   startYRef.current = pageY;
  //   startHeightRef.current = currentHeight;
  // };

  // const handleDragging = (e: MouseEvent | TouchEvent) => {
  //   if (!isDragging) return;
  //   const pageY = "touches" in e ? e.touches[0].pageY : (e as MouseEvent).pageY;
  //   const delta = startYRef.current - pageY;
  //   const newHeight =
  //     startHeightRef.current + (delta / window.innerHeight) * 100;

  //   updateHeight(newHeight);
  // };

  const handleClose = () => {
    // setCurrentHeight(0);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  // const handleDragStop = () => {
  //   if (!isDragging) return;
  //   setIsDragging(false);

  //   setCurrentHeight(prevHeight => {
  //     if (prevHeight < 25) {
  //       handleClose();
  //       return 0;
  //     } else if (prevHeight > 50) {
  //       return 90; // Expand to full content
  //     } else {
  //       return currentPageHeight; // Snap to middle of content
  //     }
  //   });
  // };

  // useEffect(() => {
  //   const handleMouseMove = (e: MouseEvent) => handleDragging(e);
  //   const handleTouchMove = (e: TouchEvent) => handleDragging(e);

  //   if (isDragging) {
  //     document.addEventListener("mousemove", handleMouseMove);
  //     document.addEventListener("touchmove", handleTouchMove);
  //     document.addEventListener("mouseup", handleDragStop);
  //     document.addEventListener("touchend", handleDragStop);
  //   }

  //   return () => {
  //     document.removeEventListener("mousemove", handleMouseMove);
  //     document.removeEventListener("touchmove", handleTouchMove);
  //     document.removeEventListener("mouseup", handleDragStop);
  //     document.removeEventListener("touchend", handleDragStop);
  //   };
  // }, [isDragging]);

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (
      (e.target as HTMLElement).classList.contains("bottom-sheet-v2-overlay")
    ) {
      handleClose();
    }
  };

  return (
    <div
      className={`bottom-sheet-v2-overlay ${isOpen ? "open" : ""}`}
      onClick={handleOverlayClick}>
      <div className={`bottom-sheet-v2 ${isOpen ? "open" : ""}`} ref={sheetRef}>
        <div
          className="dragbar-container"
          // onMouseDown={handleDragStart}
          // onTouchStart={handleDragStart}
        >
          <div className="drag-bar" />
        </div>
        <div ref={contentRef} className="bottom-sheet-v2-content">
          {children}
        </div>
        {footer && <div className="bottom-sheet-v2-footer">{footer}</div>}
      </div>
    </div>
  );
};

export default BottomSheetV2;
