import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../interceptors/store";
import {getLiveStreamsThunk} from "../../features/LiveStream/LiveStreamSlice";
import {useEffect} from "react";
import {getStreamStatus} from "../../utils/streamUtils"; // Utility function to get stream status
import "./LiveStreamCarausal.css";
import CaretRight from "../SvgImages/CaretRight/CaretRight";
import {useNavigate} from "react-router";

// Skeleton loader component
const SkeletonLoader = () => (
  <div className="live-carousal-skeleton-card">
    <div className="live-carousal-skeleton-image"></div>
    <div className="live-carousal-skeleton-badge"></div>
    <div className="live-carousal-skeleton-text"></div>
    <div className="live-carousal-skeleton-text"></div>
    <div className="live-carousal-skeleton-logo"></div>
  </div>
);

const LiveStreamCarausal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const {streams, loading, error} = useSelector(
    (state: RootState) => state.liveStream,
  );

  useEffect(() => {
    dispatch(getLiveStreamsThunk({}));
  }, [dispatch]);

  if (loading) {
    return (
      <div className="live-carousal-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <h2
            className="live-carousel-title"
            onClick={() => {
              console.log("redirect to top live page");
            }}>
            Top Live
          </h2>
          <CaretRight width={16} height={16} />
        </div>
        <div className="live-carousal-card-container">
          {/* Display skeleton loaders while loading */}
          {[...Array(5)].map((_, index) => (
            <SkeletonLoader key={index} />
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return <div>Error fetching live streams: {error}</div>;
  }

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src =
      "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"; // Fallback image
  };

  return (
    <div className="live-carousal-container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <h2
          className="live-carousel-title"
          onClick={() => {
            console.log("redirect to top live page");
          }}>
          Top Live
        </h2>
        <CaretRight width={16} height={16} />
      </div>

      {streams.length === 0 ? (
        <div>No live streams available at the moment.</div>
      ) : (
        <div className="live-carousal-card-container">
          {streams.map(stream => {
            if (!stream.thumbnail) return null;

            const status = getStreamStatus(stream);

            return (
              <div
                key={stream.id}
                className="live-carousal-card"
                onClick={() => {
                  navigate(`/stream/${stream.id}`);
                }}>
                <img
                  src={stream.thumbnail}
                  alt={stream.title}
                  className="live-carousal-card-image"
                />

                <div
                  className="live-carousal-card-badge"
                  style={{
                    background: getBadgeColor(status),
                  }}>
                  {status}
                </div>

                <div className="live-carousal-card-content">
                  <div className="live-carousal-card-logo">
                    <img
                      // src={
                      //   "https://img.freepik.com/free-vector/colorful-bird-illustration-gradient_343694-1741.jpg"
                      // }

                      src={stream.seller_profile_url}
                      alt={stream.title}
                      height={50}
                      width={50}
                      style={{borderRadius: "25px"}}
                      onError={handleError}
                    />
                  </div>

                  <div className="live-carousal-card-title">{stream.title}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const getBadgeColor = (status: string) => {
  switch (status) {
    case "Live":
      return "var(--surface-status)";
    case "Upcoming":
      return "#3897f1";
    default:
      return "#111827";
  }
};

export default LiveStreamCarausal;
