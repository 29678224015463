import * as React from "react";

export interface HomeSvgProps {
  width: number;
  height: number;
  color?: string;
}

export const PurpleHomeSvg = function PurpleHomeSvg(props: HomeSvgProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="House">
        <path
          id="Vector"
          d="M19.25 10.3126V18.5626C19.25 18.7449 19.1776 18.9198 19.0486 19.0487C18.9197 19.1777 18.7448 19.2501 18.5625 19.2501H13.75C13.5677 19.2501 13.3928 19.1777 13.2639 19.0487C13.1349 18.9198 13.0625 18.7449 13.0625 18.5626V14.0938C13.0625 14.0027 13.0263 13.9152 12.9618 13.8508C12.8974 13.7863 12.8099 13.7501 12.7188 13.7501H9.28125C9.19008 13.7501 9.10265 13.7863 9.03818 13.8508C8.97372 13.9152 8.9375 14.0027 8.9375 14.0938V18.5626C8.9375 18.7449 8.86507 18.9198 8.73614 19.0487C8.6072 19.1777 8.43234 19.2501 8.25 19.2501H3.4375C3.25516 19.2501 3.0803 19.1777 2.95136 19.0487C2.82243 18.9198 2.75 18.7449 2.75 18.5626V10.3126C2.75017 9.94799 2.89515 9.59837 3.15305 9.34064L10.028 2.46564C10.2859 2.20797 10.6355 2.06323 11 2.06323C11.3645 2.06323 11.7141 2.20797 11.972 2.46564L18.847 9.34064C19.1049 9.59837 19.2498 9.94799 19.25 10.3126Z"
          fill="#38317A"
        />
      </g>
    </svg>
  );
};
