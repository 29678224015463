import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const RatingSvg: React.FC<SvgProps> = ({height, width, color}) => {
  return (
    // <svg
    //   width={width}
    //   height={height}
    //   viewBox="0 0 21 20"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg">
    //   <mask
    //     id="mask0_1243_33852"
    //     maskUnits="userSpaceOnUse"
    //     x="0"
    //     y="0"
    //     width="21"
    //     height="20">
    //     <rect
    //       x="0.666672"
    //       width={width}
    //       height={height}
    //       fill={color ? color : "#D9D9D9"}
    //     />
    //   </mask>
    //   <g mask="url(#mask0_1243_33852)">
    //     <path
    //       d="M8.04166 14.0211L10.6667 12.4377L13.2917 14.0419L12.6042 11.0419L14.9167 9.0419L11.875 8.77107L10.6667 5.93773L9.45833 8.75023L6.41666 9.02107L8.72916 11.0419L8.04166 14.0211ZM10.6667 13.9073L7.42479 15.8623C7.30396 15.9329 7.18291 15.9625 7.06166 15.9513C6.94041 15.94 6.83069 15.8992 6.7325 15.8288C6.63416 15.7582 6.55826 15.6661 6.50479 15.5523C6.45146 15.4386 6.44291 15.3127 6.47916 15.1748L7.33979 11.4938L4.47771 9.01628C4.37076 8.92336 4.30264 8.81572 4.27333 8.69336C4.24389 8.571 4.25159 8.45211 4.29646 8.33669C4.34132 8.22142 4.40597 8.12718 4.49041 8.05398C4.57486 7.98079 4.69028 7.93294 4.83666 7.91044L8.61375 7.58044L10.0802 4.1044C10.1335 3.9751 10.2144 3.87947 10.3229 3.81753C10.4314 3.75558 10.546 3.72461 10.6667 3.72461C10.7874 3.72461 10.9019 3.75558 11.0104 3.81753C11.1189 3.87947 11.1998 3.9751 11.2531 4.1044L12.7196 7.58044L16.4967 7.91044C16.6431 7.93294 16.7585 7.98079 16.8429 8.05398C16.9274 8.12718 16.992 8.22142 17.0369 8.33669C17.0817 8.45211 17.0894 8.571 17.06 8.69336C17.0307 8.81572 16.9626 8.92336 16.8556 9.01628L13.9935 11.4938L14.8542 15.1748C14.8904 15.3127 14.8819 15.4386 14.8285 15.5523C14.7751 15.6661 14.6992 15.7582 14.6008 15.8288C14.5026 15.8992 14.3929 15.94 14.2717 15.9513C14.1504 15.9625 14.0294 15.9329 13.9085 15.8623L10.6667 13.9073Z"
    //       fill={color ? color : "#6B7280"}
    //     />
    //   </g>
    // </svg>
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_14_2216)">
        <path
          d="M14.6431 7.17815L11.8306 9.60502L12.6875 13.2344C12.7348 13.4314 12.7226 13.638 12.6525 13.8281C12.5824 14.0182 12.4576 14.1833 12.2937 14.3025C12.1298 14.4217 11.9343 14.4896 11.7319 14.4977C11.5294 14.5059 11.3291 14.4538 11.1562 14.3481L7.99999 12.4056L4.84187 14.3481C4.66901 14.4532 4.46893 14.5048 4.26683 14.4963C4.06473 14.4879 3.86963 14.4199 3.70612 14.3008C3.5426 14.1817 3.41798 14.0169 3.34793 13.8272C3.27789 13.6374 3.26556 13.4312 3.31249 13.2344L4.17249 9.60502L1.35999 7.17815C1.20705 7.04597 1.09644 6.87166 1.04198 6.67699C0.987517 6.48232 0.99161 6.27592 1.05375 6.08356C1.11589 5.89121 1.23332 5.72142 1.39138 5.59541C1.54944 5.4694 1.74113 5.39274 1.94249 5.37502L5.62999 5.07752L7.05249 1.63502C7.12949 1.44741 7.26054 1.28693 7.42897 1.17398C7.59741 1.06104 7.79563 1.00073 7.99843 1.00073C8.20123 1.00073 8.39945 1.06104 8.56788 1.17398C8.73632 1.28693 8.86737 1.44741 8.94437 1.63502L10.3662 5.07752L14.0537 5.37502C14.2555 5.39209 14.4477 5.46831 14.6064 5.59415C14.765 5.71999 14.883 5.88984 14.9455 6.08243C15.008 6.27502 15.0123 6.48178 14.9579 6.6768C14.9034 6.87183 14.7926 7.04644 14.6394 7.17877L14.6431 7.17815Z"
          fill={color ? color : "#039855"}
          transform="translate(2, 1)" // Move the path 5 units to the right
        />
      </g>
    </svg>
  );
};

export default RatingSvg;
