import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import {BrowserRouter} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Provider} from "react-redux";
import {store} from "./interceptors/store";
import "./theme/global.css";

const AppWrapper: React.FC = () => {
  return (
    <Provider store={store}>
      <ToastContainer
        className="toast-container"
        style={{zIndex: 1000000}}
        limit={1}
        pauseOnHover={true}
        position="top-right"
        autoClose={4000}
        theme="colored"
      />
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement, // specify the element type
);

root.render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
);

// Optional: report web vitals setup
reportWebVitals();
