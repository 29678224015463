/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {api, apiConfig} from "../../interceptors/api";
import {
  CategoryResponseProps,
  GetCityStateByPincodePayload,
  GetCityStateByPincodeResponse,
  GetStoreFrontDetailsResponse,
  GetStoreFrontAboutResponse,
  GetStoreReviewDetailsResponse,
  StoreRatingTypeCount,
  GetSubCategoryResponseProps,
  GetUSerReminderResponse,
  GetFeaturedStoresResponse,
  GetZoopRecommendsResponse,
  GetZoopRecommendsPayload,
  GetProductReviewsResponse,
  GetProductReviewsPayload,
  PostFollowUnfollowPayload,
  PostFollowUnfollowResponse,
  GetZoopTopReelsResponse,
} from "./UserInterface";

export const getUserData = async () => {
  try {
    const response = await api.get(`${apiConfig.userUrl}/api/user/buyer`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch departments data");
    // Add more API functions as needed
  }
};

export const updateUserData = async (payload: any) => {
  try {
    const respose = await api.patch(
      `${apiConfig.userUrl}/api/user/update_buyer_details`,
      payload,
    );
    return respose.data;
  } catch (error: any) {
    throw new Error("Failed to update user info");
  }
};

export const getCityStateByPincode = async (
  payload: GetCityStateByPincodePayload,
): Promise<GetCityStateByPincodeResponse> => {
  try {
    const respose = await api.get(
      `${apiConfig.userUrl}/api/user/pincode/${payload.pincode}`,
    );
    return respose.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getStoreFrontDetailsApi = async (
  storeId: string,
  type: string,
  store_name?: string,
): Promise<GetStoreFrontDetailsResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.userUrl}/api/store/store_front`,
      {
        params: {store_id: storeId, type, store_name: store_name},
      },
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch store front details");
  }
};

export const getCategoriesApi = async (): Promise<CategoryResponseProps> => {
  try {
    const response = await api.get(`${apiConfig.userUrl}/api/category`);
    return response.data;
  } catch (error: any) {
    throw new Error(
      error && error.response && error.response.data.error
        ? error.response.data.error
        : "Failed to fetch categories",
    );
  }
};

export const getSubCategoriesApi = async (
  categoryId: number,
): Promise<GetSubCategoryResponseProps> => {
  try {
    const response = await api.get(
      `${apiConfig.userUrl}/api/category/${categoryId}`,
    );
    return response.data;
  } catch (error: any) {
    throw new Error(
      error && error.response && error.response.data.error
        ? error.response.data.error
        : "Failed to fetch categories",
    );
  }
};

export const getStoreFrontAboutApi = async (
  storeId: string,
  type: string,
): Promise<GetStoreFrontAboutResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.userUrl}/api/store/store_front`,
      {
        params: {store_id: storeId, type},
      },
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch store front about");
  }
};

export const getStoreReviewDetailsApi = async (
  storeId: string,
): Promise<GetStoreReviewDetailsResponse> => {
  try {
    const response = await api.get(`${apiConfig.userUrl}/api/user/get_review`, {
      params: {store_id: storeId, type: "store"},
    });

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch store front details");
  }
};

export const getStoreRatingCountApi = async (
  storeId: string,
): Promise<StoreRatingTypeCount> => {
  try {
    const response = await api.get(
      `${apiConfig.userUrl}/api/user/rating_type_count`,
      {
        params: {store_id: storeId},
      },
    );

    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch store front details");
  }
};

export const addUserReminder = async (
  payload: any,
): Promise<GetUSerReminderResponse> => {
  try {
    const response = await api.post(
      `${apiConfig.userUrl}/api/user/add_reminder `,
      payload,
    );

    return response.data;
  } catch (error: any) {
    console.log("Error", error);
    throw new Error(
      error && error.response && error.response.data.message
        ? error.response.data.message
        : "Failed to add reminder",
    );
  }
};

export const getFeaturedStoresApi = async (
  payload: any,
): Promise<GetFeaturedStoresResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.userUrl}/api/store/get_featured`,
      {
        params: {...payload},
      },
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch Featured store");
  }
};

export const getZoopRecommendsApi = async (
  payload: GetZoopRecommendsPayload,
): Promise<GetZoopRecommendsResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.userUrl}/api/store/get_featured`,
      {
        params: {...payload},
      },
    );
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch Zoop recommends");
  }
};

export const getZoopToopReelsApi = async (
  payload: GetZoopRecommendsPayload,
): Promise<GetZoopTopReelsResponse> => {
  try {
    const response = await api.get(`${apiConfig.inventoryUrl}/api/video`);
    return response.data;
  } catch (error) {
    throw new Error("Failed to fetch Zoop recommends");
  }
};

export const getProductReviewsApi = async (
  payload: GetProductReviewsPayload,
): Promise<GetProductReviewsResponse> => {
  try {
    const response = await api.get(`${apiConfig.userUrl}/api/user/get_review`, {
      params: {...payload},
    });
    return response.data;
  } catch (error: any) {
    throw new Error(
      error && error.response && error.response.data.error
        ? error.response.data.error
        : "Failed to fetch product reviews",
    );
  }
};

export const postFollowUnfollowApi = async (
  payload: PostFollowUnfollowPayload,
): Promise<PostFollowUnfollowResponse> => {
  try {
    const response = await api.post(
      `${apiConfig.userUrl}/api/user/follow_unfollow`,
      payload,
    );
    return response.data;
  } catch (error: any) {
    throw new Error(
      error && error.response && error.response.data.error
        ? error.response.data.error
        : "Failed to follow/unfollow",
    );
  }
};
