import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import "./CouponCardV2.css";
import {DiscountPercentageSvg} from "../SvgImages/DiscountPercentageSvg/DiscountPercentageSvg";
import Loader from "../../Loader/Loader";
import {useCoupons} from "../../hooks/useCoupons";
import {DropdownUpSvg} from "../SvgImages/DropdownUpSvg/DropdownUpSvg";
import DropdownSvg from "../SvgImages/DropdownSvg/DropdownSvg";

interface CouponCardProps {
  setCouponDiscount: Dispatch<SetStateAction<number>>;
  setCouponApplied: Dispatch<SetStateAction<boolean>>;
  cartValue: number;
  cartId: number;
}

const CouponCardV2: React.FC<CouponCardProps> = ({
  cartValue,
  cartId,
  setCouponDiscount,
  setCouponApplied,
}) => {
  const {
    coupon,
    isOpen,
    setIsOpen,
    coupons,
    customCode,
    setCustomCode,
    applyLoader,
    errorMessage,
    calculateDiscount,
    handleCouponSelect,
    applyCustomCode,
  } = useCoupons(cartValue, cartId);

  const [currentDate, setCurrentDate] = useState(new Date());

  // Update current date every minute
  useEffect(() => {
    const intervalId = setInterval(() => setCurrentDate(new Date()), 60000);
    return () => clearInterval(intervalId);
  }, []);

  // Update coupon discount and status when coupon changes
  useEffect(() => {
    if (coupon && coupon?.coupon_discount > 0) {
      setCouponDiscount(coupon.coupon_discount);
      setCouponApplied(true);
    }
  }, [coupon, setCouponDiscount, setCouponApplied]);

  const handleCustomCodeSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      if (customCode.trim()) {
        applyCustomCode(customCode);
        setCustomCode("");
      }
    },
    [customCode, applyCustomCode, setCustomCode],
  );

  const toggleDropdown = useCallback(
    () => setIsOpen(prev => !prev),
    [setIsOpen],
  );

  // Function to check if coupon is expired
  const isCouponExpired = (validUntil?: string): boolean => {
    if (!validUntil) return false;
    return new Date(validUntil) < currentDate;
  };

  return (
    <div className="coupon-card-container">
      <div className="coupon-card-header-container" onClick={toggleDropdown}>
        <div className="coupon-card-header-left-container">
          <DiscountPercentageSvg
            color="var(--content-active)"
            width={20}
            height={20}
          />
          {coupon ? (
            <div style={{display: "flex", flexDirection: "column"}}>
              <span className="coupon-card-header-applied-text">
                ₹{coupon.coupon_discount.toFixed(2)} saved with {coupon.code}
              </span>
              <span className="coupon-card-header-text">View all coupons</span>
            </div>
          ) : (
            <span className="coupon-card-header-text">Apply Coupon</span>
          )}
        </div>
        <div className="coupon-card-header-right-container">
          <div className="arrow-right-svg-container"></div>
          {!coupon ? (
            isOpen ? (
              <DropdownUpSvg
                width={16}
                height={16}
                color="var(--content-action)"
              />
            ) : (
              <DropdownSvg
                width={16}
                height={16}
                color="var(--content-action)"
              />
            )
          ) : (
            <p className="coupon-card-change-button">Applied</p>
          )}
        </div>
      </div>

      {isOpen && (
        <div className="coupon-dropdown-container">
          {applyLoader && <Loader />}

          <form
            onSubmit={handleCustomCodeSubmit}
            className="apply-coupon-input">
            <input
              type="text"
              value={customCode}
              onChange={e => setCustomCode(e.target.value.toUpperCase())}
              placeholder="Enter coupon code"
            />
            <button type="submit" disabled={customCode.length === 0}>
              Apply
            </button>
          </form>

          {errorMessage && (
            <p className="error-text">
              <span>{errorMessage}</span>
            </p>
          )}

          <div className="available-coupons-container">
            {coupons.length > 0 ? (
              <ul className="coupons-list">
                {coupons.map((availableCoupon, index) => {
                  const expired = isCouponExpired(availableCoupon.valid_until);

                  if (availableCoupon.status === "DEACTIVATED") return null;

                  return (
                    <li
                      key={index}
                      className="coupon-item"
                      onClick={() =>
                        !expired && handleCouponSelect(availableCoupon)
                      }>
                      <div className="coupon-details">
                        <span
                          className={`coupon-code ${!expired ? "" : "expired"}`}>
                          {availableCoupon.code}
                        </span>
                        <span
                          className={`coupon-description ${!expired ? "" : "expired"}`}>
                          SAVE {calculateDiscount(availableCoupon)} on this
                          order
                        </span>
                        <span className="coupon-condition">
                          GET FLAT{" "}
                          {availableCoupon.discount_type === "FIXED"
                            ? `₹${parseInt(availableCoupon.discount_value)}`
                            : `${parseInt(availableCoupon.discount_value)}%`}
                          {availableCoupon.max_discount_value &&
                            ` upto ₹${parseInt(availableCoupon.max_discount_value)}`}
                        </span>
                      </div>
                      <button
                        className={
                          !expired
                            ? "coupon-select-btn"
                            : "coupon-select-btn-expired"
                        }>
                        Apply
                      </button>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <p className="no-coupons-message">
                No coupons available at the moment
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CouponCardV2;
