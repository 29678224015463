import React, {useEffect, useState} from "react";
import {setAuthentication} from "../../../features/Authentication/AuthenticationSlice";
import cookieUtils from "../../../utils/cookies";
import {AppDispatch} from "../../../interceptors/store";
import {useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router";
import {getIndividualOrdersThunk} from "../../../features/Order/OrderSlice";
import {IndividualOrderItem} from "../../../features/Order/OrderInterface";
import OrderStatusCard from "../OrderDetailsPage/components/OrderStatusCard/OrderStatusCard";
import OrderProductCard from "../../../components/OrderProductCard/OrderProductCard";
import "./OrderStatusPage.style.css";

const OrderStatusPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const params = useParams<{id: string}>();

  const [, setLoading] = useState<boolean>(true);
  const [, setIsReviewable] = useState<boolean>(false);
  const [item, setItem] = useState<IndividualOrderItem>();
  const [refreshAfterReviewSubmitted, setrefreshAfterReviewSubmitted] =
    useState<boolean>(false);

  useEffect(() => {
    if (cookieUtils.getCookie("token")) {
      dispatch(setAuthentication(true));
    } else {
      navigate("/");
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    getOrderByOrderItemId();
    setrefreshAfterReviewSubmitted(false);
  }, [params.id, refreshAfterReviewSubmitted]);

  const getOrderByOrderItemId = async () => {
    setLoading(true);
    try {
      setIsReviewable(false);
      const action = await dispatch(
        getIndividualOrdersThunk({order_item_id: Number(params.id)}),
      );
      if (getIndividualOrdersThunk.fulfilled.match(action)) {
        // success
        setLoading(false);
        const orderDetail = action?.payload?.data?.orders?.[0];
        if (orderDetail) {
          setItem(orderDetail);
          if (orderDetail?.status === "delivered") {
            setIsReviewable(true);
          }
        }
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  return (
    <div className="order-status-page-container">
      <div className="order-details-heading">Order Status</div>
      <OrderStatusCard
        orderItemCount={item?.quantity}
        orderNum={item?.order_id}
        buyerDetails={item?.buyer_details}
        currentStatus={item?.status}
        selfShipDetails={
          Object.keys(item?.self_shipping_details || {}).length > 0
        }
        selfShip={item?.self_shipping_details}
        statusType="OrderStatus"
        orderID={params.id}
        status={item?.status}
      />
      <div className="order-status-product-container">
        <OrderProductCard
          productName={item?.product_name}
          productDescription={item?.product_description}
          productQuantity={item?.quantity}
          variants={item?.variants}
          productImage={item?.product_image_details[0]}
        />
        <div className="order-status-view-order-button">
          <div
            className="order-status-view-order-button-btn"
            onClick={() => {
              navigate(`/orders/${item?.order_ref_id}`);
            }}>
            View Order
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStatusPage;
