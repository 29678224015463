import React, {useEffect, useCallback, ReactNode} from "react";
import "./BottomSheetV2.css";

interface BottomSheetProps {
  header: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  footer?: ReactNode;
}

const BottomSheetV2: React.FC<BottomSheetProps> = ({
  header,
  isOpen,
  onClose,
  children,
  footer,
}) => {
  const handleOverlayClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (
        (e.target as HTMLElement).classList.contains("bottom-sheet-overlay-v2")
      ) {
        onClose();
      }
    },
    [onClose],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.body.style.overflow = "";
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.body.style.overflow = "";
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, handleKeyDown]);

  return (
    <div
      className={`bottom-sheet-overlay-v2 ${isOpen ? "open" : ""}`}
      onClick={handleOverlayClick}
      aria-hidden={!isOpen}>
      <div
        className={`bottom-sheet-v2 ${isOpen ? "open" : ""}`}
        role="dialog"
        aria-modal="true">
        <div className="bottom-sheet-header-v2">
          <span className="bottom-sheet-header-text-v2">{header}</span>
          <button
            className="close-button-v2"
            onClick={onClose}
            aria-label="Close">
            &times;
          </button>
        </div>
        <div className="bottom-sheet-content-v2">{children}</div>
        {footer && <div className="bottom-sheet-footer-v2">{footer}</div>}
      </div>
    </div>
  );
};

export default BottomSheetV2;
