import React from "react";
import ProfileOptionItem from "./ProfileOptionItem";
import OrderPackageSvg from "../../components/SvgImages/Package/Package";
// import ProfileAddress from "../../components/SvgImages/ProfileAddress/ProfileAddress";
import ContactSvg from "../../components/SvgImages/Contact/Contact";

const options = [
  {
    icon: <OrderPackageSvg width={24} height={24} />,
    label: "My Orders",
    description: "Order tracking",
    route: "orders",
  },
  // {
  //   icon: <ProfileAddress width={24} height={24} />,
  //   label: "Saved Address",
  //   description: "Add address",
  // },
  {
    icon: <ContactSvg width={24} height={24} />,
    label: "Help Center",
    description: "Contact support",
    route: "support",
  },
];

const ProfileOptions: React.FC = () => {
  return (
    <>
      <div className="profile-options">
        {options.map((option, index) => (
          <ProfileOptionItem key={index} {...option} />
        ))}
      </div>
    </>
  );
};

export default ProfileOptions;
