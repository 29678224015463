import React from "react";
import "./Footer.css"; // Import the CSS file for styling
import PromotionBanner from "../PromotionBanner/PromotionBanner";

const FooterV2 = () => {
  // Footer links data
  const footerSections = [
    {
      title: "Information",
      links: [
        [
          {name: "About", url: "https://seller.zooplive.com/about/"},
          {name: "Blog", url: "https://seller.zooplive.com/blog/"},
          // { name: "Careers", url: "/careers" }
          {
            name: "Become a Seller",
            url: "https://seller.zooplive.com/",
          },
          {name: "Contact Us", url: "https://seller.zooplive.com/contact/"},
        ],
      ],
    },
    {
      title: "Social",
      links: [
        [
          {
            name: "Facebook",
            url: "https://www.facebook.com/people/India-Live-Shopping-Bazaar-Zoop/61556276234916/",
          },
          {name: "X (twitter)", url: "/about/"},
          {
            name: "Instagram",
            url: "https://www.instagram.com/zoop.live/?igsh=d3h3YWF0b2Nid2t2",
          },
          {name: "LinkedIn", url: "https://www.linkedin.com/company/zoop-live"},
        ],
      ],
    },
    {
      title: "Legal",
      links: [
        [
          {
            name: "Buyer Protection Policy",
            url: "https://seller.zooplive.com/buyerprotectionpolicy/",
          },
          {
            name: "Shipping and Return Policies",
            url: "https://storage.googleapis.com/zoop-public-bucket/Shipping%2C%20Exchange%2C%20refunds%20and%20Returns%20Policy.docx%2030%20Oct.pdf",
          },
          {
            name: "Terms of Service",
            url: "https://storage.googleapis.com/zoop-public-bucket/Terms%20of%20Use-Buyer%20App%2016July.pdf",
          },
          {
            name: "Privacy Policy",
            url: "https://seller.zooplive.com/privacy-policy/",
          },
        ],
      ],
    },
    {
      title: "Contact",
      links: [
        [
          {
            name: "M-202, Pioneer Park, Sector 61, Gurgaon",
            url: "https://www.google.com/maps/search/?q=M-202,+Pioneer+Park,+Sector+61,+Gurgaon",
          },
          {name: "hello@fluxlabs.in", url: "mailto:hello@fluxlabs.in"},
          {name: "+91 962-6812-398", url: "tel:+919626812398"},
        ],
      ],
    },
  ];

  return (
    <footer className="footer-container">
      <img
        className="w-[64.48px] h-7 object-cover"
        alt="Asset"
        src="https://storage.googleapis.com/zoop-public-bucket/web-zoop-website/ZoopLogoV2.svg"
      />

      <PromotionBanner
        bgColor="var(--surface-tertiary)"
        textColor="var(--content-primary)"
        percentColor="var(--content-active)"
        style={{
          marginLeft: "0px",
          width: "90%",
        }}
      />

      <div className="footer-links-container">
        {/* Left Column - Information */}
        <div>
          <h4 className="footer-title">{footerSections[0].title}</h4>
          <div className="footer-links-column">
            {footerSections[0].links[0].map((link, linkIndex) => (
              <a key={linkIndex} href={link.url} className="footer-link">
                {link.name}
              </a>
            ))}
          </div>
        </div>

        {/* Right Column - Social */}
        <div>
          <h4 className="footer-title">{footerSections[1].title}</h4>
          <div className="footer-links-column">
            {footerSections[1].links[0].map((link, linkIndex) => (
              <a key={linkIndex} href={link.url} className="footer-link">
                {link.name}
              </a>
            ))}
          </div>
        </div>
      </div>

      {/* Other Sections - Legal and Contact */}
      {footerSections.slice(2).map((section, sectionIndex) => (
        <div key={sectionIndex} className="footer-section">
          <h4 className="footer-title">{section.title}</h4>
          <div className="footer-links-column">
            {section.links[0].map((link, linkIndex) => (
              <a key={linkIndex} href={link.url} className="footer-link">
                {link.name}
              </a>
            ))}
          </div>
        </div>
      ))}

      <div className="footer-copyright">@2024FluxLabsAI Private Limited</div>
    </footer>
  );
};

export default FooterV2;
