import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../interceptors/store";
import {Wishlist} from "../../../features/Product/ProductInterface";
import "./StoreCollectionsPage.style.css";
import {useNavigate} from "react-router-dom";
export const StoreCollectionsPage = () => {
  const {storeCollection} = useSelector((state: RootState) => state.product);
  console.log(storeCollection);
  const navigate = useNavigate();
  return (
    <div className="store-collections-page-container">
      <div className="store-collections-page-collection-list">
        {storeCollection.data.map((collection: Wishlist, key: number) => (
          <div
            className="store-collections-page-collection-card"
            key={key}
            onClick={() => {
              navigate(`/storefront/collection/${collection.id}`);
            }}>
            <div className="store-collections-page-collection-card-header">
              <span className="store-collections-page-collection-card-header-name">
                {collection.name}
              </span>
              <span className="store-collections-page-collection-card-header-item-count">
                {collection.item_count} Items
              </span>
            </div>
            <div className="store-collections-page-collection-card-image">
              <img
                className="store-collections-page-collection-card-image-img"
                src={
                  collection.image_url ||
                  "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"
                }
                alt={collection.name}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
