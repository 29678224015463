import React from "react";
import LiveStreamCarausal from "../components/LiveStreamCarausel/LiveStreamCarausal";
import TopPicks from "../components/TopPicks/TopPicks";
import {LiveCardComponent} from "../components/LiveCards/LiveCard";
import RecommendedProduct from "../components/RecommendedProduct/RecommendProduct";
import TopReels from "../components/TopReels/TopReels";
import FeaturedStores from "../components/FeaturedStores/FeaturedStores";
import ShopByCategory from "../components/ShopByCategory/ShopByCategory";
import PromotionBanner from "../components/PromotionBanner/PromotionBanner";
// import ZoopFooterSvg from "../components/SvgImages/ZoopFooterSvg/ZoopFooterSvg";
// import FooterHeart from "../components/SvgImages/FooterHeart/FooterHeart";
import FooterV2 from "../components/Footer/FooterV2";

const HomeScreen = () => {
  return (
    <div
      style={{
        background: "var(--surface-secondary)",
      }}>
      <LiveStreamCarausal />

      <PromotionBanner />

      <ShopByCategory />

      <TopPicks />

      {/* top live streams */}
      <LiveCardComponent />

      {/* to picks*/}
      <RecommendedProduct />

      {/* top reels */}
      <TopReels />

      {/* stores */}
      <FeaturedStores />
      {/* 
      <footer className="storefront-footer">
        <div className="storefront-footer-box">
          <p className="storefront-footer-zoop-header">Welcome to </p>
          <p className="storefront-footer-zoop-subheader">ZOOPVERSE </p>
          <p className="storefront-footer-made-in">
            Made with <FooterHeart width={24} height={24} /> in Gurgaon.
          </p>
        </div>
      </footer>
 */}

      <FooterV2 />
    </div>
  );
};

export default HomeScreen;
