import React from "react";
import {SvgProps} from "../ChatSvg/ChatSvg";

const ReturnArrowSvg: React.FC<SvgProps> = ({width, height, color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24">
      <g clipPath="url(#clip0_60_31187)">
        <path
          fill={color ? color : "#141414"}
          d="M21.75 10.5a6.006 6.006 0 0 1-6 6H4.81l3.22 3.22a.75.75 0 1 1-1.06 1.06l-4.5-4.5a.75.75 0 0 1 0-1.06l4.5-4.5a.75.75 0 1 1 1.06 1.06L4.81 15h10.94a4.5 4.5 0 1 0 0-9H7.5a.75.75 0 0 1 0-1.5h8.25a6.006 6.006 0 0 1 6 6"></path>
      </g>
      <defs>
        <clipPath id="clip0_60_31187">
          <path fill="#fff" d="M0 0h24v24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
export default ReturnArrowSvg;
