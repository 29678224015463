import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getStoreCollectionThunk} from "../../../../../features/Product/ProductSlice";
import {AppDispatch} from "../../../../../interceptors/store";
import {RootState} from "../../../../../interceptors/store";
import "./StoreCollections.style.css";
import {useNavigate} from "react-router-dom";
import {Wishlist} from "../../../../../features/Product/ProductInterface";
import RightArrowSvg from "../../../../../components/SvgImages/RightArrowSvg/RightArrowSvg";
const StoreCollections = ({storeId}: {storeId: string}) => {
  const {storeCollection} = useSelector((state: RootState) => state.product);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  useEffect(() => {
    getWishlists();
  }, [storeId]);

  const getWishlists = async () => {
    try {
      const action = await dispatch(
        getStoreCollectionThunk({
          store_id: storeId,
        }),
      );
      if (getStoreCollectionThunk.fulfilled.match(action)) {
        // success
      } else {
        console.log("Error while fetching wishlists");
      }
    } catch (error) {
      console.log("Error", error);
    }
  };

  return (
    <>
      {storeCollection.data.length > 0 && (
        <div className="storefront-store-collections">
          <div className="storefront-store-collections-header">
            <span className="storefront-store-collections-header-title">
              Store Collecitons
            </span>
            <div
              onClick={() => {
                navigate(`/storefront/${storeId}/collections`);
              }}
              className="storefront-store-collections-header-view-all">
              <span className="storefront-store-collections-header-view-all-text">
                View
              </span>
              <RightArrowSvg height={18} width={18} />
            </div>
          </div>
          <div className="storefront-store-collections-list">
            {storeCollection.data.map((collection: Wishlist, index: number) => (
              <div
                className="storefront-store-collections-list-item"
                key={index}
                onClick={() => {
                  navigate(`/storefront/collection/${collection.id}`);
                }}>
                <img
                  src={
                    collection.image_url
                      ? collection.image_url
                      : "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"
                  }
                  alt={""}
                  className="storefront-store-collections-list-item-image"
                />
                <div className="storefront-store-collections-list-item-overlay"></div>
                <span className="storefront-store-collections-list-item-name">
                  {collection.name}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default StoreCollections;
