import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const StarFilledRatingSvg: React.FC<SvgProps> = ({height, width, color}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="Star">
        <path
          d="M10.9823 5.38355L8.87294 7.2037L9.5156 9.92574C9.55105 10.0735 9.54192 10.2285 9.48936 10.371C9.43679 10.5136 9.34315 10.6374 9.22025 10.7268C9.09736 10.8162 8.95074 10.8672 8.7989 10.8732C8.64706 10.8793 8.49682 10.8403 8.36716 10.761L5.99997 9.30417L3.63138 10.761C3.50173 10.8398 3.35168 10.8785 3.2001 10.8722C3.04852 10.8659 2.9022 10.8148 2.77957 10.7255C2.65693 10.6362 2.56346 10.5126 2.51093 10.3703C2.45839 10.228 2.44914 10.0733 2.48435 9.92574L3.12935 7.2037L1.01997 5.38355C0.905267 5.28441 0.822311 5.15368 0.781463 5.00768C0.740615 4.86168 0.743685 4.70688 0.79029 4.56261C0.836896 4.41834 0.924971 4.29101 1.04352 4.1965C1.16206 4.10199 1.30582 4.0445 1.45685 4.0312L4.22247 3.80808L5.28935 1.2262C5.34709 1.08549 5.44538 0.965133 5.57171 0.880425C5.69804 0.795717 5.8467 0.750488 5.9988 0.750488C6.1509 0.750488 6.29956 0.795717 6.42589 0.880425C6.55222 0.965133 6.6505 1.08549 6.70825 1.2262L7.77466 3.80808L10.5403 4.0312C10.6916 4.044 10.8358 4.10117 10.9548 4.19555C11.0737 4.28993 11.1622 4.41732 11.2091 4.56176C11.256 4.7062 11.2592 4.86127 11.2184 5.00754C11.1775 5.15381 11.0944 5.28477 10.9795 5.38402L10.9823 5.38355Z"
          fill={color ? color : "#38317A"}
        />
      </g>
    </svg>
  );
};

export default StarFilledRatingSvg;
