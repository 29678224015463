import React, {useState} from "react";
import ProfileOptionItem from "./ProfileOptionItem";
import BottomSheet from "../../components/mobile/BottomSheet/BottomSheet";
import cookieUtils from "../../utils/cookies";
import Gavel from "../../components/SvgImages/Gavel/Gavel";
import ShieldStar from "../../components/SvgImages/ShieldStar/ShieldStar";
import TruckSvg from "../../components/SvgImages/TruckSvg/TruckSvg";
import SignOut from "../../components/SvgImages/SignOut/SignOutSvg";

const options = [
  {
    icon: <Gavel width={24} height={24} />,
    label: "Terms of Service",
    description: "",
    route: "term_of_service",
  },
  {
    icon: <ShieldStar width={24} height={24} />,
    label: "Privacy Policy",
    description: "",
    route: "privacy_policy",
  },
  {
    icon: <TruckSvg width={24} height={24} />,
    label: "Shipping Policy",
    description: "",
    route: "shipping_policy",
  },
];

const LegalProfile: React.FC = () => {
  // State to control bottom sheet visibility
  const [isLogoutBottomSheetOpen, setIsLogoutBottomSheetOpen] = useState(false);

  // Function to handle logout
  const handleLogout = () => {
    localStorage.clear();
    cookieUtils.removeCookie("token");
    window.location.href = "/";
  };

  return (
    <>
      <div className="profile-options">
        {options.map((option, index) => (
          <ProfileOptionItem key={index} {...option} />
        ))}
      </div>

      <div
        className="profile-logout"
        onClick={() => {
          setIsLogoutBottomSheetOpen(!isLogoutBottomSheetOpen);
        }}>
        <h3>Logout</h3>
      </div>

      <BottomSheet
        header={<SignOut width={25} height={25} />}
        isOpen={isLogoutBottomSheetOpen}
        onClose={() => setIsLogoutBottomSheetOpen(false)}>
        <div className="header-logout-content-container">
          <p className="header-logout-content-text">
            Are you sure you want to logout?
          </p>
          <p className="header-logout-content-subtext">
            You will need to re-login to check your details
          </p>
          <div className="header-logout-actions">
            <button
              className="logout-button-cancel"
              onClick={() => setIsLogoutBottomSheetOpen(false)}>
              Cancel
            </button>
            <button className="logout-yes-button" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      </BottomSheet>
    </>
  );
};

export default LegalProfile;
