import React from "react";
import {SvgProps} from "../ChatSvg/ChatSvg";

const BuyerProtectionVaultSvg: React.FC<SvgProps> = ({
  width,
  height,
  color,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24">
      <g id="Vault">
        <path
          id="Vector"
          fill={color ? color : "#039855"}
          d="M20.25 3.75H3.75a1.5 1.5 0 0 0-1.5 1.5V18a1.5 1.5 0 0 0 1.5 1.5h1.5V21a.75.75 0 1 0 1.5 0v-1.5h10.5V21a.75.75 0 1 0 1.5 0v-1.5h1.5a1.5 1.5 0 0 0 1.5-1.5V5.25a1.5 1.5 0 0 0-1.5-1.5m0 14.25H3.75V5.25h16.5v6h-1.564a4.5 4.5 0 1 0 0 1.5h1.564zm-4.703-6.75a1.5 1.5 0 1 0 0 1.5h1.61a3 3 0 1 1 0-1.5z"></path>
      </g>
    </svg>
  );
};
export default BuyerProtectionVaultSvg;
