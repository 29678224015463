import React, {useEffect} from "react";
import ProfileHeader from "./ProfileHeader";
import ProfileOptions from "./ProfileOption";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../interceptors/store";
import {getUserThunk} from "../../features/User/UserSlice";
// import { useHistory } from "react-router-dom";
import "./Profile.css";
import LegalProfile from "./LegalProfile";

const ProfilePage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  // const history = useHistory();
  useEffect(() => {
    dispatch(getUserThunk());
  }, [dispatch]);

  const {user} = useSelector((state: RootState) => state.user);

  return (
    <div className="profile-container">
      <ProfileHeader user={user} />
      {/* <Collections />  Uncomment if needed */}
      <ProfileOptions />

      <LegalProfile />
    </div>
  );
};

export default ProfilePage;
