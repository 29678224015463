import React, {useEffect} from "react";
import {useNavigate, useLocation, matchPath} from "react-router-dom";
import useMobileSize from "./hooks/useMobileSize";
import "./App.css";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useDispatch, useSelector} from "react-redux";
import "promise-polyfill/src/polyfill";
import {getUserThunk} from "./features/User/UserSlice";
import {setAuthentication} from "./features/Authentication/AuthenticationSlice";
import ReactGA from "react-ga4";
// import {getLiveStreamsThunk} from "./features/LiveStream/LiveStreamSlice";
import {AppDispatch, RootState} from "./interceptors/store";

import Header from "./components/mobile/Header/Header";

import cookieUtils from "./utils/cookies";

import RoutesConfig from "./routes-config";
import {useStorefrontDetection} from "./hooks/useStoreFrontDetection";
import "./theme/global.css";
import BottomNav from "./components/BottomNavBar/BottomNavBar";
const excludeHeader: string[] = [
  "/login",
  "/stream/:id/live",
  "/checkout/:id",
  "/product/:id",
  "/storefront/:id",
  "/orders/status/:id",
  "/orders/:id",
];

// Initialize Google Analytics with your Tracking ID
ReactGA.initialize(process.env.REACT_APP_TRACKING_NUMBER);

const App: React.FC = () => {
  const isMobile = useMobileSize(1000);
  const dispatch = useDispatch<AppDispatch>();
  const {isSignedIn} = useSelector((state: RootState) => state.authentication);
  const navigate = useNavigate();
  const location = useLocation();
  const isStoreFront = useStorefrontDetection();

  useEffect(() => {
    onPageLoad();
  }, [location.pathname]);

  const onPageLoad = () => {
    if (cookieUtils.getCookie("token")) {
      dispatch(setAuthentication(true));
    }
  };

  useEffect(() => {
    if (isSignedIn) {
      fetchUser();
    }
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      isSignedIn,
      isMobile,
      userId: localStorage.getItem("userId") || null,
    });
  }, [isSignedIn]);

  const fetchUser = async () => {
    try {
      const action = await dispatch(getUserThunk());
      if (getUserThunk.fulfilled.match(action)) {
        const data = action.payload?.user;

        if (
          data &&
          data.first_name &&
          data.last_name &&
          data.email &&
          data.user_name
        ) {
          localStorage.setItem("username", data?.user_name);
          localStorage.setItem("showChat", "true");
          localStorage.setItem("switchMobileView", "true");
          if (location.pathname === "/") {
            navigate("/");
          }
        }
      } else {
        toast.error("Error fetching user");
        dispatch(setAuthentication(false));
      }
    } catch {
      toast.error("Error fetching user");
      dispatch(setAuthentication(false));
    }
  };

  const shouldRenderHeader = !excludeHeader.some(path =>
    matchPath({path, end: true}, location.pathname),
  );

  // const renderMobileView = !["/", "/product/:id", "/products", "/search"].some(
  //   path => matchPath({path, end: true}, location.pathname),
  // );

  return (
    <div className="main-wrapper">
      {shouldRenderHeader && !isStoreFront && <Header key="header-menu" />}
      {/* Use RoutesConfig here */}
      <RoutesConfig />
      <BottomNav isSignedIn={isSignedIn} />
    </div>
  );
};

export default App;
