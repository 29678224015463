import * as React from "react";

export interface HomeSvgProps {
  width: number;
  height: number;
  color?: string;
}

export const PurpleBagSvg = function PurpleBagSvg(props: HomeSvgProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="ShoppingBag">
        <path
          id="Vector"
          d="M18.5625 3.4375H3.4375C3.07283 3.4375 2.72309 3.58237 2.46523 3.84023C2.20737 4.09809 2.0625 4.44783 2.0625 4.8125V17.1875C2.0625 17.5522 2.20737 17.9019 2.46523 18.1598C2.72309 18.4176 3.07283 18.5625 3.4375 18.5625H18.5625C18.9272 18.5625 19.2769 18.4176 19.5348 18.1598C19.7926 17.9019 19.9375 17.5522 19.9375 17.1875V4.8125C19.9375 4.44783 19.7926 4.09809 19.5348 3.84023C19.2769 3.58237 18.9272 3.4375 18.5625 3.4375ZM11 11.6875C9.90633 11.6864 8.85778 11.2514 8.08444 10.4781C7.3111 9.70472 6.87614 8.65617 6.875 7.5625C6.875 7.38016 6.94743 7.2053 7.07636 7.07636C7.2053 6.94743 7.38016 6.875 7.5625 6.875C7.74484 6.875 7.9197 6.94743 8.04864 7.07636C8.17757 7.2053 8.25 7.38016 8.25 7.5625C8.25 8.29185 8.53973 8.99132 9.05546 9.50704C9.57118 10.0228 10.2707 10.3125 11 10.3125C11.7293 10.3125 12.4288 10.0228 12.9445 9.50704C13.4603 8.99132 13.75 8.29185 13.75 7.5625C13.75 7.38016 13.8224 7.2053 13.9514 7.07636C14.0803 6.94743 14.2552 6.875 14.4375 6.875C14.6198 6.875 14.7947 6.94743 14.9236 7.07636C15.0526 7.2053 15.125 7.38016 15.125 7.5625C15.1239 8.65617 14.6889 9.70472 13.9156 10.4781C13.1422 11.2514 12.0937 11.6864 11 11.6875Z"
          fill="#38317A"
        />
      </g>
    </svg>
  );
};
