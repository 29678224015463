import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
}

const ContactSvg: React.FC<SvgProps> = ({height, width}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_178_20746)">
        <path
          d="M18.9272 5.12437C18.03 4.21817 16.9629 3.49783 15.7869 3.0046C14.611 2.51137 13.3493 2.25494 12.0741 2.25H12C9.41414 2.25 6.93419 3.27723 5.10571 5.10571C3.27723 6.93419 2.25 9.41414 2.25 12L2.25 17.25C2.25 17.8467 2.48705 18.419 2.90901 18.841C3.33097 19.2629 3.90326 19.5 4.5 19.5H6C6.59674 19.5 7.16903 19.2629 7.59099 18.841C8.01295 18.419 8.25 17.8467 8.25 17.25V13.5C8.25 12.9033 8.01295 12.331 7.59099 11.909C7.16903 11.4871 6.59674 11.25 6 11.25H3.78375C3.92839 9.68619 4.51578 8.196 5.47709 6.9541C6.43839 5.71219 7.73377 4.77003 9.21141 4.23803C10.689 3.70603 12.2877 3.60624 13.82 3.95035C15.3524 4.29446 16.7548 5.06822 17.8631 6.18094C19.2177 7.54247 20.0509 9.33662 20.2172 11.25H18C17.4033 11.25 16.831 11.4871 16.409 11.909C15.9871 12.331 15.75 12.9033 15.75 13.5V17.25C15.75 17.8467 15.9871 18.419 16.409 18.841C16.831 19.2629 17.4033 19.5 18 19.5H20.25C20.25 20.0967 20.0129 20.669 19.591 21.091C19.169 21.5129 18.5967 21.75 18 21.75L12.75 21.75C12.5511 21.75 12.3603 21.829 12.2197 21.9697C12.079 22.1103 12 22.3011 12 22.5C12 22.6989 12.079 22.8897 12.2197 23.0303C12.3603 23.171 12.5511 23.25 12.75 23.25L18 23.25C18.9946 23.25 19.9484 22.8549 20.6517 22.1516C21.3549 21.4484 21.75 20.4946 21.75 19.5L21.75 12C21.7549 10.7246 21.5081 9.46077 21.0237 8.28093C20.5393 7.10108 19.8268 6.02841 18.9272 5.12437ZM6 12.75C6.19891 12.75 6.38968 12.829 6.53033 12.9697C6.67098 13.1103 6.75 13.3011 6.75 13.5V17.25C6.75 17.4489 6.67098 17.6397 6.53033 17.7803C6.38968 17.921 6.19891 18 6 18H4.5C4.30109 18 4.11032 17.921 3.96967 17.7803C3.82902 17.6397 3.75 17.4489 3.75 17.25L3.75 12.75H6ZM18 18C17.8011 18 17.6103 17.921 17.4697 17.7803C17.329 17.6397 17.25 17.4489 17.25 17.25V13.5C17.25 13.3011 17.329 13.1103 17.4697 12.9697C17.6103 12.829 17.8011 12.75 18 12.75H20.25L20.25 18H18Z"
          fill="#141414"
        />
      </g>
      <defs>
        <clipPath id="clip0_178_20746">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ContactSvg;
