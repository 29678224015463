/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  getStoreFrontDetailsThunk,
  setSelectedAddressThunk,
} from "../../../features/User/UserSlice";
// import BottomSheet from "../BottomSheet/BottomSheet";
import CustomRadio from "../../CustomRadio/CustomRadio";
import AddAddress, {
  Address,
  defaultDeliveryAddress,
} from "../AddAddress/AddAddress";
import OrderDetails from "../OrderDetails/OrderDetails";
import {getAddressStringFromObject} from "../../../utils/helpers";
import Juspay from "../../Juspay/Juspay";
import {useLocation} from "react-router";
import Loader from "../../../Loader/Loader";
import {AppDispatch, RootState} from "../../../interceptors/store";

import "./SelectAddress.style.css";
import {EditSvg} from "../../SvgImages/EditSvg/EditSvg";
import BottomSheetV2 from "../BottomSheet/BottomSheetV2";

interface AddressLabelProps {
  addressName: string;
  deliveryAddress: string;
  isSelected: boolean;
}

const AddressLabel: React.FC<AddressLabelProps> = ({
  addressName,
  deliveryAddress,
  isSelected = false,
}) => {
  return (
    <div className="address-label-container">
      <div
        style={{
          border: `1px solid ${isSelected ? "#03a685" : "black"}`,
          borderRadius: "0.5rem",
          padding: "0.2rem 0.3rem 0.2rem 0.3rem",
          width: "fit-content",
          color: isSelected ? "#03a685" : "black",
          marginBottom: "0.3rem",
        }}>
        <span className="address-label-header-text">{addressName}</span>
      </div>
      <div style={{color: isSelected ? "#03a685" : "black"}}>
        <span className="address-label-body-text">{deliveryAddress}</span>
      </div>
    </div>
  );
};

interface FooterProps {
  product: any; // Define a more specific type if possible
  deliveryCharge: number;
  onClose: () => void;
  onPaymentInitiate: (data: any) => void;
  loading: boolean;
}

const Footer: React.FC<FooterProps> = ({
  product,
  deliveryCharge,
  onClose,
  onPaymentInitiate,
  loading,
}) => {
  const {selectedAddress} = useSelector((state: RootState) => state.user);

  const onSubmit = async () => {
    onPaymentInitiate({});
  };

  return (
    <div
      className="select-address-action-wrapper-container"
      style={{
        width: window.innerWidth > 700 ? "94%" : "90%",
      }}>
      <div className="select-address-action-wrapper">
        <button className="select-address-negative-button" onClick={onClose}>
          Add new address
        </button>
      </div>
      <div className="select-address-action-wrapper">
        <button
          className="select-address-positive-button"
          onClick={onSubmit}
          disabled={!selectedAddress}>
          {loading ? (
            <React.Fragment>
              <div className="payment-spinner" />
            </React.Fragment>
          ) : (
            <span>
              Pay (₹
              {+product.selling_price + +deliveryCharge})
            </span>
          )}
        </button>
      </div>
    </div>
  );
};

interface SelectAddressProps {
  storeId: string;
  open: boolean;
  onClose: () => void;
  product: any; // Define a more specific type for product
  streamId?: string;
}

const SelectAddress: React.FC<SelectAddressProps> = ({
  storeId,
  open,
  onClose,
  product,
  streamId,
}) => {
  const [isOpenAddAddress, setIsAddAddressOpen] = useState<boolean>(false);
  const {user, selectedAddress} = useSelector((state: any) => state.user);
  const [storeDetails, setStoreDetails] = useState<any>(); // Type this according to the expected data
  const [deliveryCharge, setDeliveryCharge] = useState<number>(0);
  const [initiatePayment, setInitiatePayment] = useState<boolean>(false);
  const [paymentLoading, setPaymentLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<Address | null>();

  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const currentUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

  useEffect(() => {
    if (user && user.address && user.address.length === 0) {
      onClose();
      setIsAddAddressOpen(true);
      setAddress(null);
    }
  }, [user?.address, open]);

  useEffect(() => {
    if (storeId) {
      getstoreDetails();
    }
  }, [storeId]);

  useEffect(() => {
    if (storeDetails && product?.selling_price) {
      calculateDeliveryCharges();
    }
  }, [storeDetails, product?.selling_price]);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedAddressThunk({addressId: e.target.value as string}));
  };

  const getstoreDetails = async () => {
    try {
      const action = await dispatch(
        getStoreFrontDetailsThunk({store_name: "", storeId, type: "details"}),
      );

      if (getStoreFrontDetailsThunk.fulfilled.match(action)) {
        if (action && action?.payload?.data) {
          setStoreDetails(action?.payload?.data);
        }
      }
    } catch (error) {
      console.error("Error fetching store details", error);
    }
  };

  const calculateDeliveryCharges = () => {
    if (!storeDetails) return;

    const deliveryDiscount = storeDetails.delivery_discount
      ? parseInt(storeDetails.delivery_discount.toString())
      : 0;
    const freeDeliveryLowerLimit = storeDetails.free_delivery_amount
      ? parseInt(storeDetails.free_delivery_amount.toString())
      : 1000000000;
    const genericDeliveryFee = storeDetails.generic_delivery_fee
      ? parseInt(storeDetails.generic_delivery_fee.toString())
      : 0;

    let tempDeliveryCharge = 0;
    if (product?.selling_price) {
      if (
        parseInt(product?.selling_price.toString()) < freeDeliveryLowerLimit
      ) {
        tempDeliveryCharge = genericDeliveryFee - deliveryDiscount;
      }
    }

    setDeliveryCharge(tempDeliveryCharge);
  };

  return (
    <>
      <BottomSheetV2
        header="Select address"
        isOpen={open}
        onClose={onClose}
        footer={
          paymentLoading || !initiatePayment ? (
            <Footer
              product={product}
              deliveryCharge={deliveryCharge}
              loading={paymentLoading}
              onClose={() => {
                onClose();
                setIsAddAddressOpen(true);
                setAddress(null);
              }}
              onPaymentInitiate={() => {
                setInitiatePayment(true);
                setPaymentLoading(true);
              }}
            />
          ) : (
            <div />
          )
        }>
        <div className="select-address-container">
          <div className="select-address-input-container">
            {user &&
              user.address.map((item: any, index: number) => (
                <div key={index} style={{marginBottom: "1rem"}}>
                  <CustomRadio
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          columnGap: "1rem",
                          width: "95%",
                        }}>
                        <AddressLabel
                          addressName={item?.address_name}
                          deliveryAddress={getAddressStringFromObject(item)}
                          isSelected={selectedAddress?.id === item?.id}
                        />
                        <span
                          onClick={() => {
                            setAddress({
                              id: item?.id ?? -1,
                              address_line_1: item?.address_details_line1,
                              address_line_2: item?.address_details_line2,
                              pincode: item?.pincode ?? -1,
                              state: item?.state ?? "",
                              city: item?.city ?? "",
                              save_as: item?.address_name,
                            });
                            setIsAddAddressOpen(true);
                          }}>
                          <EditSvg width={20} height={20} />
                        </span>
                      </div>
                    }
                    value={item?.id}
                    name="address"
                    checked={selectedAddress?.id === item?.id}
                    onChange={handleRadioChange}
                  />
                </div>
              ))}

            <OrderDetails
              quantity={1}
              price={product?.cost_price}
              discount={
                parseInt(product?.cost_price) - parseInt(product?.selling_price)
              }
              deliveryCharge={deliveryCharge}
            />
          </div>
        </div>
      </BottomSheetV2>

      {isOpenAddAddress && (
        <AddAddress
          open={isOpenAddAddress}
          onClose={() => {
            setIsAddAddressOpen(false);
            setAddress(null);
          }}
          onSuccess={() => {
            setPaymentLoading(true);
            setInitiatePayment(true);
          }}
          addressDisabled={paymentLoading}
          initialDeliveryAddress={address ?? defaultDeliveryAddress}
          type={address?.id ? "edit" : "add"}
        />
      )}

      {initiatePayment && (
        <Juspay
          userId={user?.user_id}
          initiate={initiatePayment}
          redirectUrl={currentUrl}
          streamId={streamId}
          product={product}
          addressId={selectedAddress?.id}
          storeId={storeId}
          deliveryAddress={getAddressStringFromObject(selectedAddress)}
          amount={
            parseInt(product.selling_price) +
            parseInt(deliveryCharge?.toString())
          }
          onInitiationComplete={() => {
            setPaymentLoading(false);
            setIsAddAddressOpen(false);
            setAddress(null);
          }}
          onInitiationFailure={() => {
            setPaymentLoading(false);
            setInitiatePayment(false);
          }}
          onCancel={() => {
            setPaymentLoading(false);
            setInitiatePayment(false);
            onClose();
          }}
        />
      )}

      {paymentLoading && <Loader />}
    </>
  );
};

export default SelectAddress;
