import React from "react";
import "./ProductSection.style.css";
import {Product} from "../../../../../features/Inventory/InventoryInterface";
import {useNavigate} from "react-router";
import SearchV2Svg from "../../../../../components/SvgImages/SearchV2Svg/SearchV2Svg";
import RatingStarSvg from "../../../../../components/SvgImages/RatingStarSvg/RatingStarSvg";
interface ProductDetailsProps {
  products: Product[];
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  currentPage: number;
  totalPages: number;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

const ProductSection: React.FC<ProductDetailsProps> = ({
  products,
  setCurrentPage,
  currentPage,
  totalPages,
  searchText,
  setSearchText,
}) => {
  const navigate = useNavigate();

  return (
    <section className="storefront-product-section-product-page">
      <div className="storefront-product-section-product-page-header-container">
        <div className="storefront-product-section-product-page-header">
          <div className="storefront-product-section-product-page-search-bar">
            <span className="storefront-product-section-product-page-search-bar-icon">
              <SearchV2Svg
                height={24}
                width={24}
                color="var(--content-secondary)"
              />
            </span>
            <input
              type="text"
              placeholder="Search 'Men's tshirt'"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              className="storefront-product-section-product-page-search-bar-input"
            />
          </div>
        </div>
        <div className="storefront-product-section-product-page-header-actions">
          {/* <div>Filter</div>
          <div>Sort</div> */}
        </div>
      </div>
      <div>
        <div className="storefront-product-section-product-grid">
          {/* Sample products */}
          {products.map((product, index) => (
            <div
              key={index}
              className="storefront-product-section-product-card"
              onClick={() => {
                navigate(`/product/${product.id}`);
              }}>
              <div className="storefront-product-section-product-image-box">
                {(parseInt(product.min_product_price) -
                  parseInt(product.min_selling_price) >
                  0 ||
                  product.product_total_quantity == 0) && (
                  <div className="storefront-product-section-tag-container">
                    <div
                      className="storefront-product-section-tag-cover"
                      style={{
                        backgroundColor:
                          product.product_total_quantity == 0
                            ? "#F3F4F6"
                            : "var(--surface-status)",
                      }}>
                      <span
                        className="storefront-product-section-tag-cover-text"
                        style={{
                          color:
                            product.product_total_quantity == 0
                              ? "#374151"
                              : "#fef2f2",
                        }}>
                        {product.product_total_quantity == 0
                          ? "Sold Out"
                          : (
                              ((parseInt(product.min_product_price) -
                                parseInt(product.min_selling_price)) /
                                parseInt(product.min_product_price)) *
                              100
                            ).toFixed(0) + "% off"}
                      </span>
                    </div>
                  </div>
                )}
                {product?.product_rating && (
                  <div className="storefront-product-section-rating">
                    <div className="storefront-product-section-rating-star">
                      <RatingStarSvg height={16} width={16} />
                      <span className="storefront-product-section-rating-text">
                        {product?.product_rating.toFixed(2)}
                      </span>
                    </div>
                    <div className="storefront-product-section-rating-count">
                      <span className="storefront-product-section-rating-text">
                        {`(${product?.review_count})`}
                      </span>
                    </div>
                  </div>
                )}
                <img
                  src={
                    product.productImageDetails[0] &&
                    product.productImageDetails.length !== 0
                      ? product.productImageDetails[0].image_url
                      : "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"
                  }
                  alt="Product"
                  className="storefront-product-section-product-image"
                />
              </div>
              <div className="storefront-product-section-info">
                <div className="storefront-product-section-name">
                  {product.product_name}
                </div>
                <div className="storefront-product-section-description">
                  {product.product_description}
                </div>
                <div className="storefront-product-section-price">
                  ₹{product.min_selling_price}{" "}
                  {parseInt(product.min_product_price) -
                    parseInt(product.min_selling_price) >
                    0 && (
                    <span className="storefront-product-section-original-price">
                      ₹{product.min_product_price}
                    </span>
                  )}
                </div>
              </div>
              {/* <div className="storefront-product-section-rating">
                <span className='storefront-product-section-product-rating'>★ 4.2 (173)</span>
              </div> */}
            </div>
          ))}
        </div>
      </div>

      {products.length > 0 && (
        <div className="storefront-pagination-container">
          <button
            className="storefront-pagination-arrow"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev: number) => prev - 1)}>
            &#8249;
          </button>
          <div className="storefront-pagination-numbers">
            Page
            <span
              className={`storefront-pagination-number ${currentPage === 1 ? "active" : ""}`}
              onClick={() => setCurrentPage(1)}>
              1
            </span>
            {currentPage > 3 && (
              <span className="storefront-pagination-dots">...</span>
            )}
            {currentPage > 2 && (
              <span
                className="storefront-pagination-number"
                onClick={() => setCurrentPage(currentPage - 1)}>
                {currentPage - 1}
              </span>
            )}
            {currentPage !== 1 && currentPage !== totalPages && (
              <span className="storefront-pagination-number active">
                {currentPage}
              </span>
            )}
            {currentPage < totalPages - 1 && (
              <span
                className="storefront-pagination-number"
                onClick={() => setCurrentPage(currentPage + 1)}>
                {currentPage + 1}
              </span>
            )}
            {currentPage < totalPages - 2 && (
              <span className="storefront-pagination-dots">...</span>
            )}
            {totalPages > 1 && (
              <span
                className={`storefront-pagination-number ${
                  currentPage === totalPages ? "active" : ""
                }`}
                onClick={() => setCurrentPage(totalPages)}>
                {totalPages}
              </span>
            )}
          </div>
          <button
            className="storefront-pagination-arrow"
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((prev: number) => prev + 1)}>
            &#8250;
          </button>
        </div>
      )}
    </section>
  );
};

export default ProductSection;
