import React, {useState} from "react";
import "./UseLoginModal.style.css";
import {LoginPhone} from "./LoginPhone/LoginPhone";
import {LoginPhoneOTP} from "./LoginPhoneOTP/LoginPhoneOTP";
import {LoginPhoneUserDetails} from "./LoginPhoneUserDetails/LoginPhoneUserDetails";

export const LoginModal = ({onSuccess}: {onSuccess: () => void}) => {
  const [mobileNumber, setMobileNumber] = useState<string>("");
  const [messageId, setMessageId] = useState<string>("");
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false);
  const [isOtpVerified, setIsOtpVerified] = useState<boolean>(false);

  return (
    <div className="login-modal-container">
      {isOtpVerified ? (
        <LoginPhoneUserDetails onSuccess={onSuccess} />
      ) : isOtpSent ? (
        <LoginPhoneOTP
          mobileNumber={mobileNumber}
          messageId={messageId}
          setMessageId={setMessageId}
          setIsOtpVerified={setIsOtpVerified}
          setIsOtpSent={setIsOtpSent}
        />
      ) : (
        <LoginPhone
          mobileNumber={mobileNumber}
          setMessageId={setMessageId}
          setMobileNumber={setMobileNumber}
          setIsOtpSent={setIsOtpSent}
        />
      )}
    </div>
  );
};
