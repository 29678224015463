import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const RatingStarSvg: React.FC<SvgProps> = ({height, width, color}) => {
  return (
    // <svg
    //   width={width}
    //   height={height}
    //   viewBox="0 0 21 20"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg">
    //   <mask
    //     id="mask0_1243_33852"
    //     maskUnits="userSpaceOnUse"
    //     x="0"
    //     y="0"
    //     width="21"
    //     height="20">
    //     <rect
    //       x="0.666672"
    //       width={width}
    //       height={height}
    //       fill={color ? color : "#D9D9D9"}
    //     />
    //   </mask>
    //   <g mask="url(#mask0_1243_33852)">
    //     <path
    //       d="M8.04166 14.0211L10.6667 12.4377L13.2917 14.0419L12.6042 11.0419L14.9167 9.0419L11.875 8.77107L10.6667 5.93773L9.45833 8.75023L6.41666 9.02107L8.72916 11.0419L8.04166 14.0211ZM10.6667 13.9073L7.42479 15.8623C7.30396 15.9329 7.18291 15.9625 7.06166 15.9513C6.94041 15.94 6.83069 15.8992 6.7325 15.8288C6.63416 15.7582 6.55826 15.6661 6.50479 15.5523C6.45146 15.4386 6.44291 15.3127 6.47916 15.1748L7.33979 11.4938L4.47771 9.01628C4.37076 8.92336 4.30264 8.81572 4.27333 8.69336C4.24389 8.571 4.25159 8.45211 4.29646 8.33669C4.34132 8.22142 4.40597 8.12718 4.49041 8.05398C4.57486 7.98079 4.69028 7.93294 4.83666 7.91044L8.61375 7.58044L10.0802 4.1044C10.1335 3.9751 10.2144 3.87947 10.3229 3.81753C10.4314 3.75558 10.546 3.72461 10.6667 3.72461C10.7874 3.72461 10.9019 3.75558 11.0104 3.81753C11.1189 3.87947 11.1998 3.9751 11.2531 4.1044L12.7196 7.58044L16.4967 7.91044C16.6431 7.93294 16.7585 7.98079 16.8429 8.05398C16.9274 8.12718 16.992 8.22142 17.0369 8.33669C17.0817 8.45211 17.0894 8.571 17.06 8.69336C17.0307 8.81572 16.9626 8.92336 16.8556 9.01628L13.9935 11.4938L14.8542 15.1748C14.8904 15.3127 14.8819 15.4386 14.8285 15.5523C14.7751 15.6661 14.6992 15.7582 14.6008 15.8288C14.5026 15.8992 14.3929 15.94 14.2717 15.9513C14.1504 15.9625 14.0294 15.9329 13.9085 15.8623L10.6667 13.9073Z"
    //       fill={color ? color : "#6B7280"}
    //     />
    //   </g>
    // </svg>
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_169_1068)">
        <path
          d="M10.9823 5.38361L8.87297 7.20377L9.51563 9.9258C9.55108 10.0736 9.54195 10.2285 9.48939 10.3711C9.43682 10.5137 9.34318 10.6375 9.22028 10.7269C9.09739 10.8163 8.95077 10.8672 8.79893 10.8733C8.64709 10.8794 8.49685 10.8404 8.36719 10.7611L6 9.30423L3.63141 10.7611C3.50176 10.8399 3.35171 10.8786 3.20013 10.8723C3.04855 10.8659 2.90223 10.8149 2.7796 10.7256C2.65696 10.6363 2.56349 10.5127 2.51096 10.3704C2.45842 10.228 2.44917 10.0734 2.48438 9.9258L3.12938 7.20377L1.02 5.38361C0.905297 5.28447 0.822341 5.15374 0.781493 5.00774C0.740645 4.86174 0.743715 4.70694 0.790321 4.56267C0.836926 4.41841 0.925001 4.29107 1.04355 4.19656C1.16209 4.10205 1.30585 4.04456 1.45688 4.03127L4.2225 3.80814L5.28938 1.22627C5.34712 1.08555 5.44541 0.965194 5.57174 0.880486C5.69807 0.795778 5.84673 0.750549 5.99883 0.750549C6.15093 0.750549 6.29959 0.795778 6.42592 0.880486C6.55225 0.965194 6.65054 1.08555 6.70828 1.22627L7.77469 3.80814L10.5403 4.03127C10.6916 4.04406 10.8358 4.10123 10.9548 4.19561C11.0738 4.28999 11.1622 4.41738 11.2091 4.56182C11.256 4.70626 11.2593 4.86133 11.2184 5.0076C11.1776 5.15387 11.0945 5.28483 10.9795 5.38408L10.9823 5.38361Z"
          fill={color ? color : "#EEEEEE"}
        />
      </g>
    </svg>
  );
};

export default RatingStarSvg;
