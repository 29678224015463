import React, {Dispatch, SetStateAction} from "react";
import {useNavigate} from "react-router";
import "./MultipleProductCart.css";
import {CartItemDetails, GroupedStore} from "../../features/Cart/CartInterface";
import {DeleteSvg} from "../SvgImages/DeleteSvg/DeleteSvg";
import Loader from "../CustomLoader/CustomLoader";
// import { convertIntoINRV2 } from "../../utils/helpers";
interface SingleCartCardProps {
  cartDetails: GroupedStore; // Grouped store with cart details
  onDelete: (product: CartItemDetails) => void; // Callback to delete product from the cart
  onUpdateQuantity: (
    id: number,
    quantity: number,
    product: CartItemDetails,
  ) => void; // Callback to update quantity of a product
  quantityLoaders: {[key: number]: boolean}; // Track loading state for individual products
  setQuantityLoaders: Dispatch<SetStateAction<{[key: number]: boolean}>>; // Setter for the loading state object
}

interface Variant {
  label?: string;
  value?: string;
}

export const SingleCart: React.FC<SingleCartCardProps> = ({
  cartDetails,
  onUpdateQuantity,
  quantityLoaders,
  // onDelete,
  setQuantityLoaders,
}) => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleIncrease = (product: any) => {
    const newQuantity = product.quantity + 1;
    setQuantityLoaders(prev => ({...prev, [product.id]: true}));
    onUpdateQuantity(product.id, newQuantity, product);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDecrease = (product: any) => {
    const newQuantity = product.quantity - 1;
    onUpdateQuantity(product.id, newQuantity, product);
  };

  // const handleRemove = (product: any) => {
  //   onDelete(product);
  // };

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src =
      "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"; // Fallback image
  };

  return (
    <>
      {cartDetails?.products.map((product, index) => (
        <div className="seller_store_card_container" key={index}>
          <div className="cart-product-container">
            {/* Left */}
            <div className="cart-product-left-container">
              <div className="cart-image-container">
                <img
                  onError={handleError}
                  src={product.image}
                  alt={product.product_name}
                  className="product-image-v2"
                  onClick={() => {
                    navigate(`/product/${product.product_id}`);
                  }}
                />
              </div>
            </div>

            {/* Right */}
            <div className="cart-product-right-container">
              <p className="product-name-v2" title={product.product_name}>
                {product.store_name}
              </p>
              <p
                className="product-description-v2"
                title={product.product_description}>
                {product.product_name}
              </p>

              {product?.variants &&
                product.variants.length > 0 &&
                product.variants[0].label !== "No Variant" && (
                  <div
                    style={{
                      display: "flex",
                    }}>
                    {product?.variants?.map(
                      (variant: Variant, index: number) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: index > 1 ? "none" : "flex",
                            }}>
                            <p
                              className="order-summary-product-variant"
                              style={{
                                margin: 0,
                              }}>
                              {/* {variant?.label?.toLowerCase().includes("size")
                                ? ""
                                : variant?.label}{" "}
                               {variant?.value} */}
                              {index == 1 ? "/" : ""}
                              {variant?.value}
                            </p>
                          </div>
                        );
                      },
                    )}
                  </div>
                )}

              <div className="product_controls">
                <div className="product-quantity-controls">
                  <div className="quantity-selector">
                    <button
                      className="quantity-button"
                      onClick={() => handleDecrease(product)}>
                      {product.quantity == 1 ? (
                        <DeleteSvg width={16} height={16} />
                      ) : (
                        "-"
                      )}
                    </button>
                    <div className="quantity-display">
                      <span className="quantity_text">
                        {" "}
                        {quantityLoaders[product.id] ? (
                          <Loader size={15} />
                        ) : (
                          product.quantity
                        )}
                      </span>
                    </div>
                    <button
                      className="quantity-button"
                      onClick={() => {
                        handleIncrease(product);
                      }}>
                      +
                    </button>
                  </div>

                  <div className="order-summary-product-price">
                    <div className="order-summary-price-original">
                      ₹{product.product_price}
                    </div>
                    <div className="order-summary-price-discounted">
                      ₹{product.product_selling_price}
                    </div>
                  </div>
                </div>
              </div>

              {/* <p className="price-text">
                {convertIntoINRV2(product.product_selling_price)}{" "}
                {Number(product.product_price) >
                  Number(product.product_selling_price) && (
                    <span className="mrp-text">
                      <span className="line-through">
                        {convertIntoINRV2(product.product_price)}
                      </span>{" "}
                      <span className="discount-text">
                        {Math.round(
                          ((Number(product.product_price) -
                            Number(product.product_selling_price)) *
                            100) /
                          Number(product.product_price),
                        )}
                        % off
                      </span>
                    </span>
                  )}
              </p> */}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
