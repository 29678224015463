/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {Dispatch, SetStateAction, useState} from "react";
import "./OrderReview.css";
import DeliveryCard from "./DeliveryCard";
import OrderSummary from "./OrderSummary";
import CouponCardV2 from "../CouponCardV2/CouponcardV2";
import CouponSuccessModal from "../CouponPage/CouponSuccessModal";

interface AddressFormProps {
  onSubmit: (addressData: Address) => void;
  setIsAddAddressOpen: Dispatch<SetStateAction<boolean | false>>;
  address?: Address;
  product: any;
  storeId: string;
  setAddressType?: Dispatch<SetStateAction<string>>;
  setAddress: Dispatch<SetStateAction<Address | undefined>>;
  cartId: number;
}

const OrderReview: React.FC<AddressFormProps> = ({
  product,
  storeId,
  setIsAddAddressOpen,
  setAddressType,
  setAddress,
  address,
  cartId,
}) => {
  const [couponDiscount, setCouponDiscount] = useState<number>(0);
  const [cartValue, setCartValue] = useState<number>(0);
  const [couponApplied, setCouponApplied] = useState<boolean>(false);

  return (
    <div className="address-bottom-sheet-order-summary">
      <div className="order-summary-delivery-address">
        <DeliveryCard
          setIsAddAddressOpen={setIsAddAddressOpen}
          setAddressType={setAddressType}
          setAddress={setAddress} // ✅ Pass this to DeliveryCard
          address={address}
        />

        <CouponCardV2
          cartValue={cartValue}
          cartId={cartId}
          setCouponDiscount={setCouponDiscount}
          setCouponApplied={setCouponApplied}
        />
        <OrderSummary
          setIsAddAddressOpen={setIsAddAddressOpen}
          product={product}
          storeId={storeId}
          setCartValue={setCartValue}
          couponDiscount={couponDiscount}
        />
      </div>

      <CouponSuccessModal
        isOpen={couponApplied}
        onClose={() => setCouponApplied(false)}
        savedAmount={couponDiscount}
        actualAmount={cartValue}
      />
    </div>
  );
};

export interface Address {
  receiver_name: string;
  address_line_1: string;
  address_line_2: string;
  pincode: string;
  state: string;
  city: string;
  save_as: string;
  id?: number;
}

export default OrderReview;
