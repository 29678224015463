import React from "react";
import "./PromotionBanner.css";

interface PromotionBannerProps {
  offerText?: string;
  subtext?: string;
  iconUrl?: string;
  bgColor?: string;
  textColor?: string;
  percentColor?: string;
  style?: React.CSSProperties;
}

const PromotionBanner: React.FC<PromotionBannerProps> = ({
  offerText = "10% OFF",
  subtext = "Download Zoop app",
  iconUrl,
  bgColor = "",
  textColor = "",
  percentColor = "",
  style = {},
}) => {
  const handleBannerClick = () => {
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.fluxlabs.zoop";
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      window.location.href =
        "https://apps.apple.com/us/app/zoop-discover-and-shop-live/id6670531808";
    }
  };

  return (
    <div
      className="promotion-banner-container"
      onClick={handleBannerClick}
      style={{backgroundColor: bgColor, ...style}}>
      <div className="promotion-banner-content">
        <p style={{color: textColor}}>
          Get{" "}
          <span
            style={{
              color: percentColor
                ? percentColor
                : "var(--content-inverted-action)",
            }}>
            {offerText}
          </span>{" "}
          on your first order
        </p>
        <p className="promotion-banner-subtext" style={{color: textColor}}>
          {subtext}
        </p>
      </div>
      <div className="promotion-banner-icon">
        <img
          src={
            iconUrl ||
            "https://storage.googleapis.com/zoop-public-bucket/web-zoop-website/banner-icon.svg"
          }
          width={40}
          height={40}
          alt="Promotion Icon"
        />
      </div>
    </div>
  );
};

export default PromotionBanner;
