import * as React from "react";

export interface HomeSvgProps {
  width: number;
  height: number;
  color?: string;
}

export const PurpleUserProfile = function PurpleUserProfile(
  props: HomeSvgProps,
) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g id="UserCircle">
        <path
          id="Vector"
          d="M14.7813 10.3125C14.7813 11.0604 14.5595 11.7914 14.144 12.4132C13.7285 13.0351 13.138 13.5197 12.447 13.8059C11.7561 14.0921 10.9958 14.167 10.2623 14.0211C9.52883 13.8752 8.85507 13.5151 8.32626 12.9862C7.79744 12.4574 7.43731 11.7837 7.29141 11.0502C7.14551 10.3167 7.22039 9.55641 7.50658 8.86548C7.79278 8.17454 8.27743 7.58399 8.89925 7.16851C9.52108 6.75302 10.2521 6.53125 11 6.53125C12.0025 6.53239 12.9636 6.93113 13.6725 7.64001C14.3814 8.34888 14.7801 9.31 14.7813 10.3125ZM19.9375 11C19.9375 12.7677 19.4133 14.4956 18.4313 15.9654C17.4492 17.4352 16.0534 18.5807 14.4202 19.2572C12.7871 19.9336 10.9901 20.1106 9.25638 19.7658C7.52268 19.4209 5.93017 18.5697 4.68024 17.3198C3.43031 16.0698 2.57909 14.4773 2.23424 12.7436C1.88938 11.0099 2.06637 9.21288 2.74283 7.57977C3.41929 5.94665 4.56483 4.55081 6.0346 3.56874C7.50436 2.58668 9.23233 2.0625 11 2.0625C13.3696 2.065 15.6414 3.00743 17.317 4.683C18.9926 6.35856 19.935 8.6304 19.9375 11ZM18.5625 11C18.5614 9.9821 18.355 8.97487 17.9557 8.03856C17.5564 7.10224 16.9724 6.25607 16.2386 5.55065C15.5048 4.84522 14.6362 4.29503 13.6849 3.93298C12.7335 3.57093 11.719 3.40446 10.7018 3.44352C6.65414 3.59992 3.42633 6.97125 3.4375 11.0215C3.44138 12.8653 4.12141 14.6436 5.34875 16.0196C5.84859 15.2946 6.48351 14.6729 7.21875 14.1883C7.28144 14.1469 7.35596 14.1272 7.43093 14.1321C7.50589 14.1371 7.57716 14.1665 7.63383 14.2158C8.56813 15.0239 9.76213 15.4686 10.9974 15.4686C12.2327 15.4686 13.4267 15.0239 14.361 14.2158C14.4177 14.1665 14.489 14.1371 14.5639 14.1321C14.6389 14.1272 14.7134 14.1469 14.7761 14.1883C15.5123 14.6726 16.1481 15.2944 16.6487 16.0196C17.8821 14.6386 18.5634 12.8516 18.5625 11Z"
          fill="#38317A"
        />
      </g>
    </svg>
  );
};
