import React from "react";
import "./OrdersPageCard.style.css";
import {Variant} from "../../../../../features/Order/OrderInterface";
import {capitalizeFirstLetter} from "../../../../../utils/helpers";
import OrderProductCard from "../../../../../components/OrderProductCard/OrderProductCard";

// Define the props for OrdersPageCard component
interface OrdersPageCardProps {
  productName: string;
  productDescription: string;
  productQuantity: number;
  productImage: string;
  orderStatus: string | null;
  statusDate: string;
  variants: Variant[];
}

const OrdersPageCard: React.FC<OrdersPageCardProps> = ({
  productName,
  productDescription,
  productQuantity,
  productImage,
  orderStatus,
  statusDate,
  variants,
}) => {
  // Function to capitalize the first letter of a string

  // Function to convert a timestamp to a formatted date
  const convertTimestamp = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  return (
    <div className="orders-page-card">
      <div className="orders-page-card-header">
        <div className={`orders-page-header ${orderStatus}`}>
          {capitalizeFirstLetter(
            orderStatus === "placed"
              ? "Order Placed"
              : orderStatus === "accepted"
                ? "Order Confirmed"
                : orderStatus || "Status unknown",
          )}
        </div>
        <div className="orders-page-sub-header">
          On {convertTimestamp(statusDate)}
        </div>
      </div>

      <OrderProductCard
        productImage={productImage}
        productName={productName}
        productDescription={productDescription}
        productQuantity={productQuantity}
        variants={variants}
      />
    </div>
  );
};

export default OrdersPageCard;
