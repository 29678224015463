import React, {Dispatch, SetStateAction, useState} from "react";
import "./DeliveryCard.css";
import PencilIconSvg from "../SvgImages/PencilSimple/PencilSimple";
import {AppDispatch, RootState} from "../../interceptors/store";
import {getAddressStringFromObject} from "../../utils/helpers";
import {setSelectedAddressThunk} from "../../features/User/UserSlice";
import {useDispatch, useSelector} from "react-redux";
import {FiPlus} from "react-icons/fi";

interface DeliveryCard {
  setIsAddAddressOpen: Dispatch<SetStateAction<boolean | false>>;
  setAddressType?: Dispatch<SetStateAction<string>>;
  setAddress: Dispatch<SetStateAction<AddressData | undefined>>;
  address?: AddressData;
}

export interface AddressData {
  receiver_name: string;
  address_line_1: string;
  address_line_2: string;
  pincode: string;
  state: string;
  city: string;
  save_as: string;
  id?: number;
}

const DeliveryCard: React.FC<DeliveryCard> = ({
  setIsAddAddressOpen,
  setAddressType,
  setAddress,
}) => {
  const {user, selectedAddress} = useSelector((state: RootState) => state.user);
  const [changeAddress, setChangeAddress] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedAddressThunk({addressId: e.target.value as string}));
    setChangeAddress(!changeAddress);
  };

  return (
    <div className="delivery-card-container-main">
      <div className="delivery-address-card-header-container">
        {/* Left Section */}
        <div className="delivery-address-card-header-left-container">
          <span className="delivery-card">Delivery address</span>
        </div>
        {/* Right Section */}
        <div className="delivery-address-card-header-right-container">
          <button
            className="delivery-card-change-button"
            onClick={() => {
              setChangeAddress(!changeAddress);
            }}
            style={{
              display: user?.address && user.address.length > 1 ? "" : "none",
            }}>
            Change
          </button>
        </div>
      </div>

      {!changeAddress ? (
        <div className="delivery-card-address-container">
          <div>
            <span className="delivery-card-address-buyer-name-text">
              {getAddressStringFromObject(selectedAddress)}
            </span>
            <span className="delivery-address-text">
              {`${selectedAddress?.city}, ${selectedAddress?.pincode}, ${selectedAddress?.state}`}
            </span>
          </div>

          <div className="delivery-card-change-address">
            <p className="delivery-type">Home</p>
            <span
              onClick={() => {
                setIsAddAddressOpen(true);
                if (setAddressType) {
                  setAddressType("edit");
                }

                if (setAddress) {
                  setAddress({
                    id: selectedAddress?.id ?? -1,
                    address_line_1:
                      selectedAddress?.address_details_line1 ?? "",
                    address_line_2:
                      selectedAddress?.address_details_line2 ?? "",
                    pincode: selectedAddress?.pincode?.toString() ?? "",
                    state: selectedAddress?.state ?? "",
                    city: selectedAddress?.city ?? "",
                    save_as: selectedAddress?.address_type ?? "",
                    receiver_name: selectedAddress?.address_name ?? "",
                  });
                }
              }}>
              <PencilIconSvg height={20} width={20} />
            </span>
          </div>
        </div>
      ) : (
        <div>
          {user?.address.map((address, index) => {
            return (
              <label className="delivery-custom-radio" key={index}>
                <input
                  type="radio"
                  value={address?.id ? address.id.toLocaleString() : ""}
                  name="address"
                  checked={selectedAddress?.id === address?.id}
                  onChange={handleRadioChange}
                />

                <div className="delivery-card-address-container-radio">
                  <div>
                    <span className="delivery-card-address-buyer-name-text">
                      {getAddressStringFromObject(address)}
                    </span>
                    <span className="delivery-address-text">
                      {`${address?.city}, ${address?.pincode}, ${address?.state}`}
                    </span>
                  </div>

                  <div className="delivery-card-change-address">
                    <p className="delivery-type">{address.address_type}</p>
                    <span
                      onClick={() => {
                        setIsAddAddressOpen(true);
                        if (setAddressType) {
                          setAddressType("edit");
                        }

                        if (setAddress) {
                          setAddress({
                            id: selectedAddress?.id ?? -1,
                            address_line_1:
                              selectedAddress?.address_details_line1 ?? "",
                            address_line_2:
                              selectedAddress?.address_details_line2 ?? "",
                            pincode: selectedAddress?.pincode?.toString() ?? "",
                            state: selectedAddress?.state ?? "",
                            city: selectedAddress?.city ?? "",
                            save_as: selectedAddress?.address_type ?? "",
                            receiver_name: selectedAddress?.address_name ?? "",
                          });
                        }
                      }}>
                      <PencilIconSvg height={20} width={20} />
                    </span>
                  </div>
                </div>
              </label>
            );
          })}
        </div>
      )}

      <div
        className="delivery-card-add-new-address"
        onClick={() => {
          setIsAddAddressOpen(true);
          if (setAddressType) {
            setAddressType("add");
          }
        }}>
        <span style={{marginLeft: "10px"}}>
          <FiPlus size={16} />
        </span>
        <span> Use Different Address</span>
      </div>
    </div>
  );
};

export default DeliveryCard;
