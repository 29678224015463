import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./Cart.style.css";
import MultipleProductCart from "../../../components/Cart/MultipleProductCart";
import {AppDispatch, RootState} from "../../../interceptors/store";
import {getCartDetailsThunk} from "../../../features/Cart/CartSlice";

import {GroupedStore} from "../../../features/Cart/CartInterface";

import {BackArrow} from "../../../components/SvgImages/BackArrow/BackArrow";
import OrdersPageCardSkeleton from "../OrdersPage/components/Skeleton/OrdersPageCardSkeleton";

const CartsPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [selectedProduct, setSelectedProduct] = useState<GroupedStore | null>(
    null,
  );

  const {cartDetails, loading} = useSelector((state: RootState) => state.cart);

  const fetchCartData = async () => {
    await dispatch(getCartDetailsThunk({}));
  };

  useEffect(() => {
    fetchCartData();
  }, []);

  const handlebackArrow = () => {
    if (selectedProduct) {
      setSelectedProduct(null);
    }
  };

  return (
    <div>
      <div className="cart-list-heading">
        {selectedProduct ? (
          <span className="back-arrow" onClick={handlebackArrow}>
            <BackArrow width={20} height={20} />
          </span>
        ) : null}
        Cart
      </div>

      <>
        <div className="cart_subheader_text">
          {cartDetails.length} {cartDetails.length === 1 ? "item" : "items"} in
          your cart
        </div>
        <div>
          {loading ? (
            Array.from({length: 4}).map((_, index) => (
              <OrdersPageCardSkeleton key={`stream-${index}`} />
            ))
          ) : (
            <MultipleProductCart
              cartDetails={cartDetails}
              selectedProduct={selectedProduct}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default CartsPage;
