import React, {Dispatch, SetStateAction} from "react";
import "./CouponCard.css";
import {DiscountPercentageSvg} from "../SvgImages/DiscountPercentageSvg/DiscountPercentageSvg";
import {MdKeyboardArrowRight} from "react-icons/md";
interface Coupon {
  code: string;
  description: string;
  discount_type: string;
  condition: string;
  discount_value: string;
  coupon_id?: string;
  final_amt: number;
  cart_value: number;
}

interface CouponCardProps {
  // estimatedDelivery: string;
  couponApplied: boolean;
  coupon: Coupon | null;
  setCouponPage: Dispatch<SetStateAction<boolean | false>>;
}

const CouponCard: React.FC<CouponCardProps> = ({setCouponPage, coupon}) => {
  const onSelectCoupon = () => {
    setCouponPage(true);
  };

  const finalAmount = (coupon?.cart_value || 0) - (coupon?.final_amt || 0);

  return (
    <div className="coupon-card-container" onClick={onSelectCoupon}>
      <div className="coupon-card-header-container">
        {/* Left Section */}
        <div className="coupon-card-header-left-container">
          <div className="home-svg-container">
            <DiscountPercentageSvg
              color={"var(--content-active)"}
              width={20}
              height={20}
            />
          </div>

          {coupon ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}>
              <span className="coupon-card-header-applied-text">
                ₹{finalAmount.toFixed(2)} saved with {coupon?.code}
              </span>
              <span className="coupon-card-header-text">view all coupon</span>
            </div>
          ) : (
            <span className="coupon-card-header-text">Apply Coupon</span>
          )}
        </div>
        {/* Right Section */}
        <div
          className="coupon-card-header-right-container"
          onClick={onSelectCoupon}>
          <div className="arrow-right-svg-container"></div>
          {!coupon ? (
            <MdKeyboardArrowRight />
          ) : (
            <p className="coupon-card-change-button">Applied</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CouponCard;
