import React, {useState, useEffect} from "react";
import "./BottomNavBar.css";
import {GreyHomeSvg} from "../SvgImages/HomeSvg/GreyHomeSvg";
import {UserProfileSvg} from "../SvgImages/UserProfile/UserProfile";
import {CartSvg} from "../SvgImages/Cart/Cart";
import BottomSheetV2 from "../mobileV2/BottomSheetV2/BottomSheetV2";
import {LoginModal} from "../mobileV2/LoginModal/useLoginModal";
import {matchPath, useLocation, useNavigate} from "react-router";
import {PurpleHomeSvg} from "../SvgImages/HomeSvg/PurpleHouse";
import {PurpleUserProfile} from "../SvgImages/UserProfile/PurpleUserProfile";
import {PurpleBagSvg} from "../SvgImages/Cart/PurpleBagSvg";

interface BottomNavProps {
  isSignedIn: boolean;
}

const BottomNav: React.FC<BottomNavProps> = ({isSignedIn}) => {
  const [visible, setVisible] = useState(true);
  const [loginModal, setLoginModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const renderNavBar = !["/stream/:id/live"].some(path =>
    matchPath({path, end: true}, location.pathname),
  );

  let lastScrollY = window.scrollY;

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setVisible(false);
      } else {
        setVisible(true);
      }
      lastScrollY = window.scrollY;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div
        className={`bottom-nav ${visible && renderNavBar ? "show" : "hide"}`}>
        <div
          className={`nav-item ${activeIndex === 0 ? "active" : ""}`}
          onClick={() => {
            setActiveIndex(0);
            navigate("/");
          }}>
          {activeIndex === 0 || location.pathname === "/" ? (
            <PurpleHomeSvg width={22} height={22} />
          ) : (
            <GreyHomeSvg width={22} height={22} />
          )}
        </div>

        <div
          className={`nav-item ${activeIndex === 1 ? "active" : ""}`}
          onClick={() => {
            if (isSignedIn === true) {
              navigate("/profile");
              setActiveIndex(1);
            } else {
              setLoginModal(true);
            }
          }}>
          {activeIndex === 1 || location.pathname === "/profile" ? (
            <PurpleUserProfile width={22} height={22} />
          ) : (
            <UserProfileSvg width={22} height={22} />
          )}
        </div>

        <div
          className={`nav-item ${activeIndex === 2 ? "active" : ""}`}
          onClick={() => {
            if (isSignedIn === true) {
              navigate("/checkout");
              setActiveIndex(2);
            } else {
              // setLoginModal(true);
            }
          }}>
          {activeIndex === 2 || location.pathname === "/checkout" ? (
            <PurpleBagSvg width={22} height={22} />
          ) : (
            <CartSvg width={22} height={22} />
          )}
        </div>
      </div>
      <BottomSheetV2
        isOpen={loginModal}
        onClose={() => {
          setLoginModal(false);
        }}>
        <LoginModal
          onSuccess={() => {
            setLoginModal(false);
          }}
        />
      </BottomSheetV2>
    </>
  );
};

export default BottomNav;
