import React, {useEffect, useRef, useState} from "react";
import "./StoreInfo.style.css";
import RatingSvg from "../../../../../components/SvgImages/RatingSvg/RatingSvg";
import ShareSvg from "../../../../../components/SvgImages/ShareSvg/ShareSvg";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../../../../interceptors/store";
import {postFollowUnfollowThunk} from "../../../../../features/User/UserSlice";
import {toast} from "react-toastify";
import {RootState} from "../../../../../interceptors/store";
import BottomSheetV2 from "../../../../../components/mobileV2/BottomSheetV2/BottomSheetV2";
import {LoginModal} from "../../../../../components/mobileV2/LoginModal/useLoginModal";
import Loader from "../../../../../components/CustomLoader/CustomLoader";
interface StoreInfoProps {
  storeName: string | undefined;
  freeDeliveryAmount: number | undefined;
  email: string | undefined;
  profilePhoto: string | undefined;
  isActive: boolean | undefined;
  storeRating: number | undefined;
  pastMonthOrder: string | undefined;
  storeDescription: string | undefined;
  productCount: number;
  isStoreFollowed: boolean;
  storeId: string;
}

const StoreInfo: React.FC<StoreInfoProps> = ({
  storeName,
  // freeDeliveryAmount,
  profilePhoto,
  // isActive,
  storeRating,
  pastMonthOrder,
  storeDescription,
  // productCount,
  isStoreFollowed,
  storeId,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const descriptionRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const [storeFollowingLoading, setStoreFollowingLoading] = useState(false);
  const {isSignedIn} = useSelector((state: RootState) => state.authentication);
  const [loginModal, setLoginModal] = useState<boolean>(false);
  useEffect(() => {
    // Check if the text exceeds two lines
    if (descriptionRef.current) {
      const isOverflowing =
        descriptionRef.current.scrollHeight >
        descriptionRef.current.clientHeight;
      setIsOverflowing(isOverflowing);
    }
  }, [storeDescription]);

  const handleToggle = () => {
    setIsExpanded(prev => !prev);
  };

  const onPressFollow = async (
    storeId: string,
    type: string,
    actionType: string,
  ) => {
    const payload = {
      followed_id: storeId,
      followed_type: type,
      action: actionType,
    };

    if (!isSignedIn) {
      setLoginModal(true);
      return;
    }

    try {
      setStoreFollowingLoading(true);
      const action = await dispatch(postFollowUnfollowThunk(payload));

      setStoreFollowingLoading(false);
      if (postFollowUnfollowThunk.fulfilled.match(action)) {
        toast.success(
          `${actionType === "follow" ? "Followed" : "Unfollowed"} successfully`,
        );
      } else {
        toast.error("Failed to Follow/Unfollow");
      }
    } catch (err) {
      toast.error("Failed to Follow/Unfollow");
      console.log(err);
    }
  };
  return (
    <section className="storefront-store-info">
      <div className="storefront-store-info-store-header">
        <img
          src={profilePhoto}
          alt="Store Profile"
          className="storefront-store-info-store-avatar"
        />
        <div className="storefront-store-info-store-details">
          <div
            className="storefront-store-info-store-name"
            style={{textAlign: "left"}}>
            {storeName}
          </div>
          <div className="storefront-store-info-store-stats">
            <div className="storefront-store-info-store-stats-badge">
              <RatingSvg height={16} width={16} />
              {storeRating || "N/A"}
            </div>
            <div className="storefront-store-info-store-stats-badge">
              {pastMonthOrder || "N/A"} Orders
            </div>
          </div>
        </div>
        <div
          style={{display: "flex", flexDirection: "row", columnGap: "0.5rem"}}>
          <div
            onClick={() => {
              const url = window.location.href; // Detect the current hosted URL
              if (navigator.share) {
                navigator
                  .share({
                    title: storeName ?? "Check out!!!",
                    text: `Check out ${storeName ?? ""} on Zoop :`,
                    url: url, // Use the detected URL
                  })
                  .then(() => console.log("Shared successfully!"))
                  .catch(error => console.error("Error sharing:", error));
              } else {
                alert("Your browser does not support the Web Share API.");
              }
            }}>
            <ShareSvg height={24} width={24} />
          </div>
        </div>
      </div>
      <div>
        {storeDescription && (
          <div
            ref={descriptionRef}
            className={`storefront-store-info-store-description ${isExpanded ? "full" : ""}`}>
            {storeDescription}
          </div>
        )}
        {isOverflowing && (
          <button onClick={handleToggle} className="read-more-button">
            {isExpanded ? "Show Less" : "Read More"}
          </button>
        )}
      </div>
      <div className="storefront-store-info-store-buttons">
        {isStoreFollowed ? (
          <button
            className={`storefront-store-info-store-buttons-unfollow`}
            onClick={() => {
              onPressFollow(storeId, "store", "unfollow");
            }}>
            {storeFollowingLoading ? <Loader size={12} /> : "Following"}
          </button>
        ) : (
          <button
            className="storefront-store-info-store-buttons-follow"
            onClick={() => {
              onPressFollow(storeId, "store", "follow");
            }}>
            {storeFollowingLoading ? <Loader size={12} /> : "Follow"}
          </button>
        )}
        <button className="storefront-store-info-store-buttons-message">
          Message
        </button>
      </div>
      <BottomSheetV2
        isOpen={loginModal}
        onClose={() => {
          setLoginModal(false);
        }}>
        <LoginModal
          onSuccess={() => {
            setLoginModal(false);
          }}
        />
      </BottomSheetV2>
    </section>
  );
};

export default StoreInfo;
