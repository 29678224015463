import React from "react";
import "./RecommendProduct.css";
import {useSelector} from "react-redux";
import {RootState} from "../../interceptors/store";
// import {Hashtag} from "../SvgImages/Hashtag/Hashtag";
import {useNavigate} from "react-router";
import {RecommendProductSkeleton} from "./RecommendProductSkeleton";
// import CaretRight from "../SvgImages/CaretRight/CaretRight";

const RecommendedProduct = () => {
  const {zoopRecommends} = useSelector((state: RootState) => state.user);

  return (
    <>
      <div className="recommendation-main">
        <div className="top-picks-header">
          <h3>Recommended for you</h3>
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}>
            {/* <h2>See more </h2>
            <CaretRight width={16} height={16} /> */}
          </span>
        </div>

        <div className="recommendation-container">
          {zoopRecommends.length == 0
            ? Array(4)
                .fill(0)
                .map((_, index) => (
                  <div className="recommended-product" key={index}>
                    <RecommendProductSkeleton />
                  </div>
                ))
            : zoopRecommends.slice(0, 4).map((item, index) => {
                const discount = Math.ceil(
                  ((parseInt(item.product_price) -
                    parseInt(item.selling_price)) /
                    parseInt(item.product_price)) *
                    100,
                );
                return (
                  <div className="recommended-product" key={index}>
                    <ProductCard
                      image={item.feature_media_details[0].image_url}
                      discount={discount}
                      rating={0}
                      reviews={0}
                      product_id={item.product_id}
                      price={parseInt(item.selling_price)}
                      originalPrice={parseInt(item.product_price)}
                      name={item.name || ""}
                      description={item.tag_line}
                    />
                  </div>
                );
              })}
        </div>
      </div>
    </>
  );
};

export default RecommendedProduct;

interface ProductCardProps {
  image: string; // URL of the product image
  discount?: number; // Discount percentage (optional)
  rating: number; // Rating value
  reviews: number; // Number of reviews
  price: number; // Current price
  originalPrice: number; // Original price
  name: string; // Product name
  description: string; // Product description
  product_id: number;
}

const ProductCard: React.FC<ProductCardProps> = ({
  image,
  discount = 0,
  rating = 0,
  reviews,
  price,
  originalPrice,
  name,
  description,
  product_id,
}) => {
  const navigate = useNavigate();

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src =
      "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"; // Fallback image
  };

  return (
    <div
      className="recommended-products-product-card"
      onClick={() => {
        navigate(`/product/${product_id}`);
      }}>
      <div className="recommended-products-product-card-image">
        <img src={image} alt={name} onError={handleError} />
        {discount > 0 ? (
          <div className="top-picks-discount-badge">{discount}% off</div>
        ) : (
          ""
        )}
        {rating > 0 ? (
          <div className="top-picks-rating">
            <span className="top-picks-rating-value">⭐ {rating}</span>
            <span className="top-picks-reviews">({reviews})</span>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="top-picks-card-content">
        <h3 className="top-picks-product-name">{name}</h3>
        <p className="top-picks-product-description">{description}</p>
        <div className="top-picks-price-section">
          <span className="top-picks-price">₹{price}</span>
          <span className="top-picks-original-price">₹{originalPrice}</span>
        </div>
      </div>
    </div>
  );
};
