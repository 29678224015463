import * as React from "react";

export interface HomeSvgProps {
  width: number;
  height: number;
  color?: string;
}

export const GreyHomeSvg = function GreyHomeSvg(props: HomeSvgProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 22 22"
      fill={props.color ? props.color : "#AFAFAF"}
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.847 9.33991L11.972 2.46491C11.7141 2.20724 11.3645 2.0625 11 2.0625C10.6355 2.0625 10.2859 2.20724 10.028 2.46491L3.15305 9.33991C3.02475 9.46726 2.92304 9.61884 2.85385 9.78585C2.78466 9.95286 2.74936 10.132 2.75 10.3127V18.5627C2.75 18.7451 2.82243 18.9199 2.95137 19.0489C3.0803 19.1778 3.25516 19.2502 3.4375 19.2502H8.9375C9.11984 19.2502 9.2947 19.1778 9.42364 19.0489C9.55257 18.9199 9.625 18.7451 9.625 18.5627V13.7502H12.375V18.5627C12.375 18.7451 12.4474 18.9199 12.5764 19.0489C12.7053 19.1778 12.8802 19.2502 13.0625 19.2502H18.5625C18.7448 19.2502 18.9197 19.1778 19.0486 19.0489C19.1776 18.9199 19.25 18.7451 19.25 18.5627V10.3127C19.2506 10.132 19.2153 9.95286 19.1462 9.78585C19.077 9.61884 18.9753 9.46726 18.847 9.33991ZM17.875 17.8752H13.75V13.0627C13.75 12.8804 13.6776 12.7055 13.5486 12.5766C13.4197 12.4477 13.2448 12.3752 13.0625 12.3752H8.9375C8.75516 12.3752 8.5803 12.4477 8.45136 12.5766C8.32243 12.7055 8.25 12.8804 8.25 13.0627V17.8752H4.125V10.3127L11 3.43772L17.875 10.3127V17.8752Z"
        fill={props.color ? props.color : "#AFAFAF"}
      />
    </svg>
  );
};
