import React from "react";
import {useNavigate} from "react-router-dom";

interface ProfileOptionProps {
  icon: React.ReactNode;
  label: string;
  description?: string;
  route?: string;
}

const ProfileOptionItem: React.FC<ProfileOptionProps> = ({
  icon,
  label,
  description,
  route,
}) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (route) {
      if (route === "orders") {
        navigate("/orders");
      } else if (route === "support") {
        window.location.href = "https://seller.zooplive.com/contact/";
      } else if (route == "term_of_service") {
        window.location.href =
          "https://storage.googleapis.com/zoop-public-bucket/Terms%20of%20Use-Buyer%20App%2016July.pdf";
      } else if (route == "privacy_policy") {
        window.location.href = "https://seller.zooplive.com/privacy-policy/";
      } else if (route == "shipping_policy") {
        window.location.href =
          "https://storage.googleapis.com/zoop-public-bucket/Shipping%2C%20Exchange%2C%20refunds%20and%20Returns%20Policy.docx%2030%20Oct.pdf";
      }
    }
  };

  return (
    <div className="profile-option" onClick={handleNavigation}>
      <span className="option-icon">{icon}</span>
      <div className="option-details">
        <p className="option-label">{label}</p>
        {description && <p className="option-description">{description}</p>}
      </div>
    </div>
  );
};

export default ProfileOptionItem;
