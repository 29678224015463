import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../interceptors/store";
import "./ShopByCategory.css";

import {useNavigate} from "react-router";

const ShopByCategory: React.FC = () => {
  const {categories} = useSelector((state: RootState) => state.user);

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src =
      "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"; // Fallback image
  };

  const navigate = useNavigate();

  return (
    <div className="shop-category-container">
      <h2>Shop by category</h2>
      <div className="categories-scroll">
        <div className="categories-wrapper">
          {categories.map((category, index) => {
            if (category.thumbnail_url == null) {
              return;
            }
            return (
              <div
                key={index}
                className="category-card"
                onClick={() => {
                  navigate(`/products?categories=${category.id}`);
                }}>
                <div className="image-container">
                  <img
                    src={category.thumbnail_url}
                    alt={category.name}
                    onError={handleError}
                  />
                  <div className="category-name-content">
                    <p className="category-name">{category.name}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ShopByCategory;
