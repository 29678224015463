import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const StarUnfilledRatingSvg: React.FC<SvgProps> = ({height, width, color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16">
      <g id="Star">
        <path
          id="Vector"
          fill={color ? color : "#AFAFAF"}
          d="m14.643 7.178-2.812 2.427.857 3.63a1.026 1.026 0 0 1-1.532 1.113L8 12.406l-3.158 1.942a1.026 1.026 0 0 1-1.53-1.113l.86-3.63L1.36 7.178a1.029 1.029 0 0 1 .582-1.803l3.688-.297 1.422-3.443a1.023 1.023 0 0 1 1.892 0l1.422 3.443 3.688.297a1.03 1.03 0 0 1 .585 1.804z"></path>
      </g>
    </svg>
  );
};

export default StarUnfilledRatingSvg;
