import BottomSheet from "../BottomSheet/BottomSheet";
import "./SelectAddressV2.css";
import CustomRadio from "../../CustomRadio/CustomRadio";
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {Dispatch, SetStateAction, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../interceptors/store";
import {setSelectedAddressThunk} from "../../../features/User/UserSlice";
import AddAddress, {
  // Address,
  defaultDeliveryAddress,
} from "../AddAddress/AddAddress";
import {getAddressStringFromObject} from "../../../utils/helpers";
import Footer from "./SelectAddressFooter";

interface SelectAddressProps {
  // storeId: string;
  open: boolean;
  onClose: () => void;
  product: any; // Define a more specific type for product
  isOpenAddAddress: boolean;
  setIsAddAddressOpen: Dispatch<SetStateAction<boolean | false>>;
}

interface Address {
  id?: string | number;
  address_line_1: string;
  address_line_2: string;
  pincode: string;
  state: string;
  city: string;
  save_as: string;
}
interface AddressLabelProps {
  id: number;
  addressName: string;
  deliveryAddress: string;
  addressLine1: string;
  addressLine2: string;
  mobile: string | undefined;
  city: string;
  state: string;
  pincode: string;
  selectedId: number | null;
  setIsAddAddressOpen: Dispatch<SetStateAction<boolean | false>>;
  setAddress: Dispatch<SetStateAction<Address | null>>;
}

export const SelectAddressV2: React.FC<SelectAddressProps> = ({
  open,
  onClose,
  isOpenAddAddress,
  setIsAddAddressOpen,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {user, selectedAddress} = useSelector((state: RootState) => state.user);
  const [address, setAddress] = useState<Address | null>(null);

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedAddressThunk({addressId: e.target.value as string}));
  };

  return (
    <>
      <BottomSheet
        header="Select Delivery Address"
        isOpen={open}
        onClose={onClose}
        footer={
          <>
            <Footer
              onClose={() => {
                onClose();
                setIsAddAddressOpen(true);
                setAddress(null);
              }}
            />
          </>
        }>
        <div className="selectAddressV2-container">
          <div className="selectAddressV2-topStrip">
            <div className="selectAddressV2-savedAddressText">
              SAVED ADDRESS
            </div>
            <div className="selectAddressV2-addNewAddress" data-position="top">
              {" "}
            </div>
          </div>
          <div className="selectAddressV2-addressContainer">
            {user?.address &&
              user?.address.map((item: any, index: number) => (
                <div key={index}>
                  <CustomRadio
                    className="custom-radio-address-v2"
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          columnGap: "1rem",
                          width: "100%",
                        }}>
                        <AddressLabel
                          id={item?.id}
                          addressName={item?.address_name}
                          addressLine1={item?.address_details_line1}
                          addressLine2={item?.address_details_line2}
                          deliveryAddress={getAddressStringFromObject(item)}
                          mobile={user?.mobile}
                          city={item?.city}
                          state={item?.state}
                          pincode={item?.pincode}
                          selectedId={selectedAddress?.id || -1}
                          setIsAddAddressOpen={setIsAddAddressOpen}
                          setAddress={setAddress}
                        />
                      </div>
                    }
                    value={item?.id}
                    name="address"
                    checked={selectedAddress?.id === item?.id}
                    onChange={handleRadioChange}
                  />
                </div>
              ))}
          </div>
        </div>
      </BottomSheet>

      {isOpenAddAddress && (
        <AddAddress
          open={isOpenAddAddress}
          onClose={() => {
            setIsAddAddressOpen(false);
            setAddress(null);
          }}
          onSuccess={() => {
            setIsAddAddressOpen(false);
            setAddress(null);
          }}
          addressDisabled={false}
          initialDeliveryAddress={address ?? defaultDeliveryAddress}
          type={address?.id ? "edit" : "add"}
        />
      )}
    </>
  );
};

const AddressLabel: React.FC<AddressLabelProps> = ({
  id,
  addressName,
  addressLine1,
  addressLine2,
  mobile,
  city,
  state,
  pincode,
  selectedId,
  setAddress,
  setIsAddAddressOpen,
}) => {
  return (
    <div style={{width: "100%", paddingBottom: "9px"}}>
      <div
        className={`selectAddressV2 ${selectedId === id ? "" : "collapsed"}`}>
        <div
          className="selectAddressV2-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingRight: "8px",
            marginTop: "25px",
            position: "absolute",
            right: "5px",
          }}>
          {/* <div className="selectAddressV2-name">
             {"Address Name  "} 
             {isDefault && <span className="default-tag">(Default)</span>} 
          </div> */}
          <div
            className="selectAddressV2-address-type"
            style={{
              color: selectedId != id ? "grey" : "",
              border: selectedId != id ? "1px solid grey" : "1px solid #03a685",
              marginLeft: "auto",
            }}>
            {addressName}
          </div>
        </div>
        <div className="selectAddressV2-details-address">
          <div className="address-line">{addressLine1}</div>
          <div className="address-line">{addressLine2}</div>
          <div className="address-location">{`${city}, ${state} - ${pincode}`}</div>
          <div className="selectAddressV2-mobile">
            <span>Mobile: </span>
            <span style={{fontWeight: "600", color: "black"}}>{mobile}</span>
          </div>

          {selectedId === id && (
            <div style={{display: "flex"}}>
              <div className="selectAddressV2-base-button">
                <div>DELIVERING HERE</div>
              </div>

              <span
                style={{
                  marginTop: "6px",
                  marginLeft: "10px",
                }}
                onClick={() => {
                  setAddress({
                    id: id ?? -1,
                    address_line_1: addressLine1,
                    address_line_2: addressLine1,
                    pincode: pincode ?? -1,
                    state: state ?? "",
                    city: city ?? "",
                    save_as: addressName,
                  });
                  setIsAddAddressOpen(true);
                }}>
                {/* <EditSvg width={20} height={20} /> */}
                <div
                  className="selectAddressV2-address-edit"
                  style={{
                    color: "black",
                  }}>
                  EDIT
                </div>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
