import React from "react";
import "./ProgressBar.css";

interface Step {
  label: string;
  completed: boolean;
  active: boolean;
}

interface ProgressBarProps {
  steps: Step[];
  currentStep: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({steps, currentStep}) => {
  const progressWidth = (currentStep / (steps.length - 1)) * 100; // Percentage of progress

  return (
    <div className="progress-container">
      <div className="progress-bar">
        <div className="step-hr-container">
          <hr className="step-hr" />
          <hr
            className="step-hr completed"
            style={{width: `${progressWidth}%`}}
          />
        </div>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div className="step-container">
              <div
                className={`step-circle ${step.completed ? "completed" : ""} ${step.active ? "active" : ""}`}></div>
              <div className="step-label">{step.label}</div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
