import React, {useEffect} from "react";
import "./TopReels.css";
import {useDispatch, useSelector} from "react-redux";
import {getZoopTopReelsThunk} from "../../features/User/UserSlice";
import {AppDispatch, RootState} from "../../interceptors/store";
// import { useNavigate } from "react-router";
import HLSPlayer from "../HslPlayer/HslPlayer";
// import CaretRight from "../SvgImages/CaretRight/CaretRight";

const TopReels = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getZoopTopReelsThunk({key_name: "product_pricing_id", limit: 8}));
  }, [dispatch]);

  const {topReels} = useSelector((state: RootState) => state.user);

  return (
    <div className="top-reels-container">
      <div className="top-picks-header">
        <h3>Top Reels</h3>
        <span
          style={{
            display: "flex",
            alignItems: "center",
          }}>
          {/* <h2>View all </h2>
          <CaretRight width={16} height={16} /> */}
        </span>
      </div>

      <div className="top-reels-card-container">
        {topReels.map((item, index) => {
          return (
            <div className="top-picks-product" key={index}>
              <ProductCard image={item.url} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const hlsContainerStyle: React.CSSProperties = {
  marginTop: 0,
  padding: 0,
  aspectRatio: 9 / 16,
  width: "99px",
  height: "176px",
  borderRadius: "10px",
  zIndex: 1,
};

const hlsVideoStyle: React.CSSProperties = {
  borderRadius: "10px",
};

interface ProductCardProps {
  image: string; // URL of the product image
}

const ProductCard: React.FC<ProductCardProps> = ({image}) => {
  // const [isPlaying, setIsPlaying] = useState(false);

  // const handleVideoEnd = () => {
  //     setIsPlaying(false);
  // };

  // const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
  //     e.currentTarget.src =
  //         "https://shop-sg.doverstreetmarket.com/cdn/shop/files/basicstussyziphoodnavy.webp?v=1727398608&width=1116"; // Fallback image
  // };

  return (
    <div className="top-reels-product-card">
      <div className="top-reels-card-image">
        <HLSPlayer
          hlsContainerStyle={hlsContainerStyle}
          hlsVideoStyle={hlsVideoStyle}
          isMuted={true}
          data={{public_url: image}}
          externalHeaders={false}
        />
      </div>
    </div>
  );
};

export default TopReels;
