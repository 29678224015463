/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from "react";
import "./ProductDetails.css";
import {useLocation, useNavigate, useParams} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {setAuthentication} from "../../features/Authentication/AuthenticationSlice";
import {getProductThunk} from "../../features/Inventory/InventorySlice";
import Loader from "../../Loader/Loader";
import {convertIntoINR, findByKey} from "../../utils/helpers";
import cookieUtils from "../../utils/cookies";
import {colourCode} from "../../constants/colourCode";
import {AppDispatch, RootState} from "../../interceptors/store";
import {
  ProductById,
  ProductPricingDetail,
} from "../../features/Inventory/InventoryInterface";
import {AddProductToCartPayload} from "../../features/Cart/CartInterface";
import {addProductToCartThunk} from "../../features/Cart/CartSlice";
import {
  GetProductReviewsPayload,
  ReviewProduct,
} from "../../features/User/UserInterface";
import {getProductReviewsThunk} from "../../features/User/UserSlice";
// import useMobileSize from "../../hooks/useMobileSize";
import Carousel from "../../components/mobile/Carousel/Carousel";
import Slider from "../../components/Slider/Slider";
// import ImageModal from "../../components/modal/ImageModal/ImageModal";
// import SelectAddress from "../../components/mobile/SelectAddress/SelectAddress";
import HLSPlayer from "../../components/HslPlayer/HslPlayer";
import BottomSheetV2 from "../../components/mobileV2/BottomSheetV2/BottomSheetV2";
import {LoginModal} from "../../components/mobileV2/LoginModal/useLoginModal";
import ExpandMoreSvg from "../../components/SvgImages/ExpandMoreSvg/ExpandMoreSvg";
import ExpandLessSvg from "../../components/SvgImages/ExpandLessSvg/ExpandLessSvg";
import BuyerProtectionVaultSvg from "../../components/SvgImages/BuyerProtectionVaultSvg/EmailSvg";
import ReturnArrowSvg from "../../components/SvgImages/ReturnArrowSvg/ReturnArrowSvg";
import StarFilledSvg from "../../components/SvgImages/StarFilledSvg/StarFilledSvg";
import ProductReviewCard from "./Components/ProductReviewCard/ProductReviewCard";
import RatingSvg from "../../components/SvgImages/RatingSvg/RatingSvg";
import ShareSvg from "../../components/SvgImages/ShareSvg/ShareSvg";
import ShippingSvg from "../../components/SvgImages/ShippingSvg/ShippingSvg";
import AddressBottomSheet from "../../components/AddressBottomSheet/AddressBottomSheet";
import {BuyNowModal} from "../../components/mobileV2/BuyNowModal/BuyNowModal";

const ProductDetails: React.FC = () => {
  const [product, setProduct] = useState<ProductById | null>(null);
  const [selectedVariantIndex, setSelectedVariantIndex] = useState<number>(-1);
  const [variantValues, setVariantValues] = useState<{[key: string]: any}>({});
  const [selectedVariantValue, setSelectedVariantValue] = useState<{
    [key: string]: string | number;
  }>({});
  const [isBuyNowInitiated, setIsBuyNowInitiated] = useState<boolean>(false);
  const [isAddAddressInitiated, setIsAddAddressInitiated] =
    useState<boolean>(false);
  const [totalReviewCount, setTotalReviewCount] = useState(0);
  const [loadingMoreReviews, setLoadingMoreReviews] = useState<boolean>(false);
  // const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [, setReviewImages] = useState<string[]>([]);
  const [productReviews, setProductReviews] = useState<ReviewProduct[]>([]);
  // const [currentImage, setCurrentImage] = useState<string>("");

  const {storeFront} = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const params = useParams<{id: string}>();
  const location = useLocation();
  const [addToCart, setAddToCart] = useState<boolean>(false);
  const {loading} = useSelector((state: RootState) => state.cart);
  // const [visibleReviews, setVisibleReviews] = useState(5);
  // const isMobile = true;
  const [pauseVideo, setPauseVideo] = useState(false);

  // const INITIAL_REVIEW_COUNT = 5;
  const streamId = location?.state?.stream_id;

  const {isSignedIn} = useSelector((state: RootState) => state.authentication);
  const [loginModal, setLoginModal] = useState(false);
  const [isBuyerProtectionOpen, setIsBuyerProtectionOpen] = useState(false);
  const [isKeyDetailsOpen, setIsKeyDetailsOpen] = useState(false);
  const [isShippingPolicyOpen, setIsShippingPolicyOpen] = useState(false);
  const [isReturnPolicyOpen, setIsReturnPolicyOpen] = useState(false);
  const [isProductDescriptionOpen, setIsProductDescriptionOpen] =
    useState(false);
  const [buyNowAllowed, setBuyNowAllowed] = useState(false);
  const [productData, setProductData] = useState<any>(null);
  useEffect(() => {
    if (cookieUtils.getCookie("token")) {
      dispatch(setAuthentication(true));
    }
  }, []);

  const [cartId, setCartId] = useState<number>();

  useEffect(() => {
    if (params.id) {
      fetchProduct();
      fetchProductReviews();
    }
  }, [params.id]);

  useEffect(() => {
    if (product) {
      populateVariants();
    }
  }, [product]);

  // useEffect(() => {
  //   const priceAmount = product?.max_selling_price
  //     ? product?.max_selling_price
  //     : "null";
  //   const currency = "INR";
  //   const availability =
  //     product?.quantity || 0 > 0 ? "in stock" : "out of stock";

  //   const priceAmountMeta = document.querySelector(
  //     'meta[property="og:price:amount"]',
  //   ) as HTMLMetaElement | null;
  //   const priceCurrencyMeta = document.querySelector(
  //     'meta[property="og:price:currency"]',
  //   ) as HTMLMetaElement | null;
  //   const productTypeMeta = document.querySelector(
  //     'meta[property="og:type"]',
  //   ) as HTMLMetaElement | null;
  //   const availabilityMeta = document.querySelector(
  //     'meta[property="og:availability"]',
  //   ) as HTMLMetaElement | null;

  //   // Set new content dynamically if the meta tags exist
  //   if (priceAmountMeta) priceAmountMeta.setAttribute("content", priceAmount);
  //   if (priceCurrencyMeta) priceCurrencyMeta.setAttribute("content", currency);
  //   if (productTypeMeta) productTypeMeta.setAttribute("content", "product");
  //   if (availabilityMeta)
  //     availabilityMeta.setAttribute("content", availability);

  //   const jsonLdScript = document.getElementById(
  //     "json-ld",
  //   ) as HTMLScriptElement | null;

  //   if (jsonLdScript) {
  //     // Replace the placeholders with actual dynamic values
  //     const jsonLdData = {
  //       "@context": "https://schema.org",
  //       "@type": "Product",
  //       "@id": `/product/${product?.product_name}#product`, // dynamic URL
  //       name: product?.product_name, // dynamic product name
  //       description: product?.product_description, // dynamic product description
  //       "@availability": "http://schema.org/InStock",
  //     };

  //     // Ensure we are updating the innerHTML with a JSON string
  //     jsonLdScript.innerHTML = JSON.stringify(jsonLdData);
  //   }
  // }, [product]);

  useEffect(() => {
    if (
      product &&
      selectedVariantValue &&
      Object.keys(selectedVariantValue).length
    ) {
      onChangeSelectedVariantValue();
    }
  }, [product, selectedVariantValue]);

  const fetchProduct = async () => {
    try {
      const payload = {
        product_id: params.id ?? "",
      };
      const action = await dispatch(getProductThunk(payload));

      if (getProductThunk.fulfilled.match(action)) {
        if (action?.payload?.product) {
          const temp = action.payload.product;
          if (temp) {
            const tempProductPricingDetails: ProductPricingDetail[] = [];

            temp.productPricingDetails?.forEach((variant, index) => {
              tempProductPricingDetails.push({
                ...variant,
                product_price: variant.product_price,
                cost_price: variant.product_price,
                selling_price: variant.selling_price,
                varient_id: variant.id,
              });

              const defaultVariant = temp?.productPricingDetails[0];
              if (
                defaultVariant.variantValueDetails &&
                defaultVariant.variantValueDetails.length > 0
              ) {
                const tempSelectedVariantValue: {
                  [key: string]: string | number;
                } = {};
                defaultVariant.variantValueDetails.map(variantValue => {
                  if (variantValue?.varientCategory?.[0]?.varient_name) {
                    tempSelectedVariantValue[
                      variantValue?.varientCategory?.[0]?.varient_name
                    ] = variantValue.varient_value;
                  }
                });

                setSelectedVariantValue(tempSelectedVariantValue);
                setSelectedVariantIndex(index);
              }
            });

            temp["productPricingDetails"] = tempProductPricingDetails;
            if (temp.product_videos.length > 0) {
              temp["productImageDetails"].splice(1, 0, temp.product_videos[0]);
            }

            setProduct(temp);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchProductReviews = async (page = 1) => {
    if (loadingMoreReviews) {
      return;
    }
    setLoadingMoreReviews(true);
    const payload: GetProductReviewsPayload = {
      product_id: params.id ?? "",
      type: "product",
      limit: 6,
      page,
    };
    try {
      const action = await dispatch(getProductReviewsThunk(payload));
      if (getProductReviewsThunk.fulfilled.match(action)) {
        const reviewDetails = action.payload.review_details;
        setTotalReviewCount(action.payload.count);
        setProductReviews([...reviewDetails.reviews]);
        const allReviewImages = reviewDetails.reviews.flatMap(
          review => review.media_urls || [],
        );
        setReviewImages([...allReviewImages]);
      } else {
        console.log("Error fetching reviews");
      }
    } catch (error) {
      console.log("error fetching reviews", error);
    } finally {
      setLoadingMoreReviews(false);
    }
  };

  const populateVariants = () => {
    const tempVariants: {[key: string]: any} = {};
    product?.productPricingDetails?.forEach(variant =>
      variant.variantValueDetails?.forEach(variantValue => {
        if (variantValue?.varientCategory?.[0]?.varient_name) {
          if (tempVariants[variantValue?.varientCategory?.[0]?.varient_name]) {
            const ob = findByKey(
              tempVariants[variantValue?.varientCategory?.[0]?.varient_name],
              "variant_value_id",
              variantValue.id,
            );

            if (!ob) {
              tempVariants[
                variantValue?.varientCategory?.[0]?.varient_name
              ].push({
                name: variantValue.varient_name,
                value: variantValue.varient_value,
                variant_value_id: variantValue.id,
              });
            }
          } else {
            tempVariants[variantValue?.varientCategory?.[0]?.varient_name] = [
              {
                name: variantValue.varient_name,
                value: variantValue.varient_value,
                variant_value_id: variantValue.id,
              },
            ];
          }
        }
      }),
    );
    setVariantValues(tempVariants);
  };

  const findVariantIndex = (data: any, selectedVariantValue: any) => {
    return data.findIndex((item: any) => {
      // Check if all variant values match
      return item.variantValueDetails.every((variant: any) => {
        const categoryName = variant.varientCategory[0].varient_name;
        const value = variant.varient_value;
        // Match against the selected values
        return selectedVariantValue[categoryName] === value;
      });
    });
  };

  const onChangeSelectedVariantValue = () => {
    const index = findVariantIndex(
      product?.productPricingDetails,
      selectedVariantValue,
    );

    setSelectedVariantIndex(index);

    const productData = product?.["productPricingDetails"]?.[index];

    if (productData) {
      productData["images"] = product?.["productImageDetails"]?.map(
        (url: string) => ({
          src: url,
          alt: "Product Image",
        }),
      );
    }
    if (productData?.product_total_quantity) {
      setBuyNowAllowed(productData?.product_total_quantity > 0);
    } else {
      setBuyNowAllowed(false);
    }
    setProductData(productData);
  };

  const isVariantAvailable = (
    VarientValue: number | string,
    VariantName: string,
  ) => {
    const tempSelectedVariantValue = {...selectedVariantValue};
    tempSelectedVariantValue[VariantName] = VarientValue;

    const index = findVariantIndex(
      product?.productPricingDetails,
      tempSelectedVariantValue,
    );

    const result =
      product?.productPricingDetails?.[index]?.["product_total_quantity"] &&
      product?.productPricingDetails?.[index]?.["product_total_quantity"] > 0;
    if (result) {
      return true;
    }
    return false;
  };

  const addProductTocart = async () => {
    const payload: AddProductToCartPayload = {
      type: "buyNow",
      product_id: product?.id.toString() ?? "",
      product_pricing_details_id:
        product?.["productPricingDetails"]?.[
          selectedVariantIndex
        ].varient_id?.toString() ?? "",
      quantity: "1",
    };

    try {
      const action = await dispatch(addProductToCartThunk(payload));
      if (addProductToCartThunk.fulfilled.match(action)) {
        setAddToCart(true);
        setCartId(action.payload.cartId);
        // setLoading(false)
      } else {
        setAddToCart(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  if (!product) {
    return <Loader />;
  }

  const handleVideoPause = () => {
    setPauseVideo(!pauseVideo);
  };

  const hlsContainerStyle: React.CSSProperties = {
    marginTop: 0,
    padding: 0,
    width: "300px",
    height: "450px",
  };

  // const hlsContainerStyleDesktop: React.CSSProperties = {
  //   marginTop: 0,
  //   padding: 0,
  //   width: "354px",
  //   height: "502px",
  // };

  // const dummyImage = [
  //   "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png",
  //   "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png",
  //   "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png",
  // ]

  return (
    <div className="product-details">
      <Carousel
        jumpToIndex={0}
        showIndicator={true}
        showNavigation={product?.productImageDetails?.length > 1}>
        {product?.productImageDetails?.map((image, index) => (
          <div key={index} className="product-img-container">
            <div key={index}>
              {index === 1 && product?.product_videos?.length > 0 ? (
                <div className="productImage" onClick={handleVideoPause}>
                  <HLSPlayer
                    data={{
                      public_url: product?.product_videos[0],
                    }}
                    isMuted={true}
                    hlsVideoStyle={hlsContainerStyle}
                    pauseVideo={pauseVideo}
                  />
                </div>
              ) : (
                <img
                  alt="Thumbnail"
                  className="productImage"
                  src={image ?? "/avatar.png"}
                />
              )}
              {product?.productImageDetails?.length > 1 && (
                <div className="product-img-pagination-container">
                  <div className="product-img-pagination-container-text">
                    {index + 1}/{product?.productImageDetails?.length}
                  </div>
                </div>
              )}
              {product?.product_rating && (
                <div className="product-img-container-rating">
                  <div className="product-img-container-rating-text">
                    <RatingSvg height={16} width={16} />
                    {product?.product_rating.toFixed(2)}
                  </div>
                </div>
              )}
              <div className="product-img-container-right-container">
                <div
                  className="product-img-container-right-container-share-button"
                  onClick={() => {
                    const url = window.location.href; // Detect the current hosted URL
                    if (navigator.share) {
                      navigator
                        .share({
                          title: product?.product_name ?? "Check out!!!",
                          text: `Check out ${product?.product_name ?? ""} on Zoop :`,
                          url: url, // Use the detected URL
                        })
                        .then(() => console.log("Shared successfully!"))
                        .catch(error => console.error("Error sharing:", error));
                    } else {
                      alert("Your browser does not support the Web Share API.");
                    }
                  }}>
                  <ShareSvg
                    height={20}
                    width={20}
                    color="var(--content-inverted)"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>

      <div className="product-details-content-container">
        <div className="product-header-container">
          <div className="product-header-left">
            <div className="product-header-left-discount-container">
              {parseInt(
                product?.productPricingDetails?.[selectedVariantIndex]?.[
                  "product_price"
                ],
              ) -
                parseInt(
                  product?.productPricingDetails?.[selectedVariantIndex]?.[
                    "selling_price"
                  ],
                ) !=
              0 ? (
                <div className="product-details-discount-percent">
                  {parseInt(
                    Math.ceil(
                      (100 *
                        (parseInt(
                          product?.productPricingDetails?.[
                            selectedVariantIndex
                          ]?.["product_price"]?.toString(),
                        ) -
                          parseInt(
                            product?.productPricingDetails?.[
                              selectedVariantIndex
                            ]?.["selling_price"]?.toString(),
                          ))) /
                        parseInt(
                          product?.productPricingDetails?.[
                            selectedVariantIndex
                          ]?.["product_price"]?.toString(),
                        ),
                    ).toString(),
                  )}
                  {`% off`}
                </div>
              ) : (
                <div />
              )}
              {product?.productPricingDetails?.[selectedVariantIndex]?.[
                "product_total_quantity"
              ] <= 1 ? (
                <div className="product-details-soldout-container">
                  {product?.productPricingDetails?.[selectedVariantIndex]?.[
                    "product_total_quantity"
                  ] < 1
                    ? "Out of Stock"
                    : "Only 1 in Stock!"}
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="product-price-container">
              {selectedVariantValue &&
              Object.keys(selectedVariantValue).length &&
              selectedVariantIndex != -1 ? (
                <React.Fragment>
                  <div className="product-details-price">
                    <div className="product-details-selling-price">
                      ₹
                      {convertIntoINR(
                        product?.productPricingDetails?.[
                          selectedVariantIndex
                        ]?.["selling_price"],
                      )}
                    </div>
                    {parseInt(
                      product?.productPricingDetails?.[selectedVariantIndex]?.[
                        "product_price"
                      ],
                    ) -
                      parseInt(
                        product?.productPricingDetails?.[
                          selectedVariantIndex
                        ]?.["selling_price"],
                      ) !=
                    0 ? (
                      <div className="product-details-discount-price">
                        ₹
                        {convertIntoINR(
                          product?.productPricingDetails?.[
                            selectedVariantIndex
                          ]?.["product_price"],
                        )}
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </React.Fragment>
              ) : (
                <span>Select Variant</span>
              )}
            </div>
            <div className="product-header-title">{product?.product_name}</div>
            <div className="product-header-rediv">
              {product?.product_description}
            </div>
            <div className="product-header-store-name">
              by{" "}
              <span className="product-header-store-name-text">
                {storeFront?.details?.store_name}
              </span>
            </div>
          </div>
        </div>

        {variantValues &&
          Object.keys(variantValues).length > 0 &&
          !variantValues?.["No Variant"] && (
            <div className="product-details-size-box">
              {Object.entries(variantValues).map(
                ([variantCategory, variantCategoryDetail], index) => {
                  return (
                    <div key={index} style={{width: "100%"}}>
                      <div className="product-details-title-container">
                        <span className="product-details-variant-label">
                          {variantCategory}
                        </span>
                      </div>
                      <div className="product-details-size-selector">
                        <div className="product-details-size-selector-list">
                          {variantCategory === "Colour" ? (
                            <Slider
                              options={variantCategoryDetail?.map(
                                (variantCategoryValue: any) => {
                                  return {
                                    id: variantCategoryValue.name,
                                    value:
                                      colourCode?.[variantCategoryValue.name] ??
                                      variantCategoryValue.name,
                                    isAvailable: isVariantAvailable(
                                      variantCategoryValue.name,
                                      variantCategory,
                                    ),
                                  };
                                },
                              )}
                              renderOption={(option, isSelected) => (
                                <div
                                  className="color-option"
                                  style={{
                                    backgroundColor: option.value,
                                    border: isSelected
                                      ? "2px solid var(--surface-action2)"
                                      : "1px solid var(--border-primary)",
                                    opacity: option.isAvailable ? 1 : 0.5,
                                  }}
                                />
                              )}
                              onSelect={(selectedOption: any) => {
                                setSelectedVariantValue({
                                  ...selectedVariantValue,
                                  [variantCategory]: selectedOption.id,
                                });
                              }}
                            />
                          ) : (
                            <React.Fragment>
                              <Slider
                                options={variantCategoryDetail.map(
                                  (variantCategoryValue: any) => {
                                    return {
                                      id: variantCategoryValue.name,
                                      value: variantCategoryValue.name,
                                      isAvailable: isVariantAvailable(
                                        variantCategoryValue.name,
                                        variantCategory,
                                      ),
                                    };
                                  },
                                )}
                                renderOption={(
                                  option: any,
                                  isSelected: boolean,
                                ) => (
                                  <div
                                    className="size-option"
                                    style={{
                                      backgroundColor: option.isAvailable
                                        ? "var(--surface-primary)"
                                        : "var(--surface-secondary)",
                                      color: option.isAvailable
                                        ? "var(--content-primary)"
                                        : "var(--content-inactive)",
                                      borderColor: isSelected
                                        ? "var(--border-action)"
                                        : "var(--border-primary)",
                                      textDecoration: option.isAvailable
                                        ? "none"
                                        : "line-through",
                                      // textDecorationColor: option.isAvailable ? 'var(--content-primary)' : 'var(--content-primary)',
                                    }}>
                                    {option.value}
                                  </div>
                                )}
                                onSelect={selectedOption => {
                                  setSelectedVariantValue({
                                    ...selectedVariantValue,
                                    [variantCategory]: selectedOption.id,
                                  });
                                }}
                              />
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                },
              )}
            </div>
          )}

        <div className="product-detail-action-cta-container">
          {!location?.state?.hide_buy_now && (
            <>
              <button
                className={`product-detail-buy-now ${productData?.product_total_quantity < 1 ? "product-detail-button-cta-disabled" : ""}`}
                disabled={
                  selectedVariantIndex == -1 ||
                  productData?.product_total_quantity < 1
                }
                onClick={() => {
                  if (isSignedIn) {
                    if (streamId) {
                      navigate(-1);
                    } else {
                      setIsBuyNowInitiated(true);
                    }
                  } else {
                    setLoginModal(true);
                  }
                }}>
                <span className="product-detail-text">
                  {streamId ? "Back to Stream" : "Buy Now"}
                </span>
              </button>

              <div className="product-detail-action-cta-container-bottom">
                <button
                  className={`product-detail-add-cart ${productData?.product_total_quantity < 1 ? "product-detail-button-cta-disabled" : ""}`}
                  disabled={
                    selectedVariantIndex == -1 ||
                    productData?.product_total_quantity < 1
                  }
                  onClick={() => {
                    if (isSignedIn) {
                      if (addToCart) {
                        navigate("/checkout");
                      } else {
                        addProductTocart();
                      }
                    } else {
                      setLoginModal(true);
                    }
                  }}>
                  <span className="product-detail-text">
                    {loading ? <Loader /> : ""}
                    {addToCart ? "Go to Bag" : "Add to Bag"}
                  </span>
                </button>

                {/* <button className="product-detail-bargain">
                  <span className="product-detail-bargain-text">Bargain</span>
                </button> */}
              </div>
            </>
          )}
        </div>

        <div className="product-details-buyer-protection-container">
          <div
            className="product-details-buyer-protection-container-button"
            onClick={() => setIsBuyerProtectionOpen(!isBuyerProtectionOpen)}>
            <div className="product-details-buyer-protection-left">
              <div className="product-details-buyer-protection-left-icon">
                <BuyerProtectionVaultSvg height={24} width={24} />
              </div>
              <div className="product-details-buyer-protection-left-title">
                Zoop Buyer Protection
              </div>
            </div>
            <div className="product-details-buyer-protection-right">
              {!isBuyerProtectionOpen ? (
                <ExpandMoreSvg height={"20"} width={"20"} />
              ) : (
                <ExpandLessSvg height={"20"} width={"20"} />
              )}
            </div>
          </div>
          {isBuyerProtectionOpen && (
            <div className="product-details-drop-down-container-content">
              <span>
                Receive your item as described, or get your money back.{" "}
              </span>

              <span
                className="product-details-buyer-protection-container-content-learn-more"
                onClick={() => {
                  window.open(
                    "https://seller.zooplive.com/buyerprotectionpolicy/",
                    "_blank",
                  );
                }}>
                Learn more
              </span>
            </div>
          )}
        </div>
        <div className="product-details-product-details-container">
          <div className="product-details-product-details-container-title">
            Product Information
          </div>
          {product?.meta_data && (
            <div className="product-details-product-details-container-dropedown">
              <div
                className="product-details-product-details-container-dropedown-header"
                onClick={() => setIsKeyDetailsOpen(!isKeyDetailsOpen)}>
                <div className="product-details-product-details-container-dropedown-header-title">
                  Key details
                </div>
                <div className="product-details-product-details-container-dropedown-header-expand-svg">
                  {!isKeyDetailsOpen ? (
                    <ExpandMoreSvg height={"20"} width={"20"} />
                  ) : (
                    <ExpandLessSvg height={"20"} width={"20"} />
                  )}
                </div>
              </div>
              {isKeyDetailsOpen && (
                <div className="product-details-drop-down-container-content-key-details">
                  {Object.entries(product?.meta_data).map(
                    ([key, value], index) => (
                      <span
                        className="product-details-drop-down-container-content-key-details-item"
                        key={index}>
                        <span className="product-details-drop-down-container-content-key-details-key">
                          {key.charAt(0).toUpperCase() + key.slice(1)}:
                        </span>
                        <span className="product-details-drop-down-container-content-key-details-value">
                          {value}
                        </span>
                      </span>
                    ),
                  )}
                </div>
              )}
            </div>
          )}
          <div className="product-details-product-details-container-dropedown">
            <div
              className="product-details-product-details-container-dropedown-header"
              onClick={() =>
                setIsProductDescriptionOpen(!isProductDescriptionOpen)
              }>
              <div className="product-details-product-details-container-dropedown-header-title">
                Product Description
              </div>
              <div className="product-details-product-details-container-dropedown-header-expand-svg">
                {!isProductDescriptionOpen ? (
                  <ExpandMoreSvg height={"20"} width={"20"} />
                ) : (
                  <ExpandLessSvg height={"20"} width={"20"} />
                )}
              </div>
            </div>
          </div>
          {isProductDescriptionOpen && (
            <div className="product-details-drop-down-container-content-product">
              <span>{product?.product_description}</span>
            </div>
          )}
        </div>
        <div className="product-details-seller-details-container">
          <div className="product-details-seller-details-container-title">
            Store Details
          </div>
          <div className="product-details-seller-details-container-content">
            <div
              className="product-details-seller-details-container-content-left"
              onClick={() => {
                navigate(`/storefront/${storeFront.details?.store_id}`);
              }}>
              <img
                src={
                  storeFront?.details?.profile_url ||
                  "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"
                }
                alt="Seller Avatar"
                className="product-details-seller-details-container-content-left-avatar"
              />
              <div className="product-details-seller-details-container-content-left-name-ctn">
                <span className="product-details-seller-details-container-content-left-name">
                  {storeFront?.details?.store_name}
                </span>
                <span className="product-details-seller-details-container-content-left-name-text">
                  View store products
                </span>
              </div>
            </div>
            <div className="product-details-seller-details-container-content-right">
              <button className="product-details-seller-details-container-content-right-button">
                Message
              </button>
            </div>
          </div>
        </div>
        <div className="product-details-store-separator"></div>
        <div className="product-details-review-container">
          <div className="product-details-review-container-title">Reviews</div>
          {product?.product_rating ? (
            <div
              className="product-details-review-rating-card"
              // onClick={() => setReviewOverlayOpen(true)}
            >
              <div className="product-details-review-rating-card-heading">
                <div className="product-details-review-rating-card-heading-rating">
                  <div className="product-details-review-rating-card-heading-rating-star">
                    <StarFilledSvg height={"20"} width={"20"} />
                  </div>
                  <div className="product-details-review-rating-card-heading-rating-text">
                    {product?.product_rating
                      ? product?.product_rating.toFixed(2)
                      : "N/A"}
                  </div>
                </div>
                <div className="product-details-review-rating-card-heading-rating-separator">
                  •
                </div>
                <div className="product-details-review-rating-card-heading-summary">
                  {totalReviewCount || "N/A"} Reviews
                </div>
              </div>
              <div
                className="product-details-reviews-card-container"
                onClick={() => {
                  navigate(`/product/${params.id}/reviews`);
                }}>
                {productReviews.slice(0, 2).map((review, key) => (
                  <ProductReviewCard review={review} key={key} />
                  // <></>
                ))}
              </div>
              <div className="product-details-review-show-all-reviews-button-container">
                <button
                  className={`product-details-review-show-all-reviews-button`}
                  onClick={() => {
                    navigate(`/product/${params.id}/reviews`);
                  }}>
                  {/* {<Loader size={12}/> : "Unfollow"} */}
                  Show all reviews
                </button>
              </div>
            </div>
          ) : (
            <span className="product-details-review-no-reviews-text">
              This item and store have not been reviewed yet. Buy it now and
              leave a review :D
            </span>
          )}
        </div>

        <div className="product-details-policy-container">
          <div className="product-details-policy-container-item-container">
            <div
              className="product-details-policy-container-item"
              onClick={() => setIsShippingPolicyOpen(!isShippingPolicyOpen)}>
              <div className="product-details-policy-container-item-left">
                <div className="product-details-policy-container-item-left-icon">
                  <ShippingSvg
                    height={20}
                    width={20}
                    color="var(--content-primary)"
                  />
                </div>
                <div className="product-details-policy-container-item-left-title">
                  Shipping Policy
                </div>
              </div>
              <div className="product-details-policy-container-item-right">
                {!isShippingPolicyOpen ? (
                  <ExpandMoreSvg height={"20"} width={"20"} />
                ) : (
                  <ExpandLessSvg height={"20"} width={"20"} />
                )}
              </div>
            </div>
            {isShippingPolicyOpen && (
              <span className="product-details-drop-down-container-content-product">
                The seller is responsible for the shipment of the sold items to
                the customer. We urge all sellers to ship their products within
                5-7 days of receiving an order. Once the order is picked up from
                the seller, we rely on our courier partners to deliver the order
                at the earliest to the customer. The order is usually delivered
                between 3-5 days post pickup from the seller. We are not
                responsible for any delays caused by our courier partners. You
                can read their terms here . We are not liable for any damages
                (including, without limitation, any incidental or consequential
                damages) arising from the failure of a seller or any shipping
                carrier, or a contractor engaged by the seller or shipping
                carrier to deliver.
              </span>
            )}
          </div>
          <div className="product-details-policy-container-item-container">
            <div
              className="product-details-policy-container-item"
              onClick={() => setIsReturnPolicyOpen(!isReturnPolicyOpen)}>
              <div className="product-details-policy-container-item-left">
                <div className="product-details-policy-container-item-left-icon">
                  <ReturnArrowSvg
                    height={20}
                    width={20}
                    color="var(--content-primary)"
                  />
                </div>
                <div className="product-details-policy-container-item-left-title">
                  Return/Exchange Policy
                </div>
              </div>
              <div className="product-details-policy-container-item-right">
                {!isReturnPolicyOpen ? (
                  <ExpandMoreSvg height={"20"} width={"20"} />
                ) : (
                  <ExpandLessSvg height={"20"} width={"20"} />
                )}
              </div>
            </div>
            {isReturnPolicyOpen && (
              <div className="storefront-about-section-store-info-card-body-content">
                <span className="storefront-about-section-return-policy-description">
                  Acceptable reasons to claim an exchange
                </span>
                <ul className="storefront-about-section-return-policy-list">
                  <li>A defective product</li>
                  <li>Missing item / piece</li>
                  <li>No returns / refunds only exchange!</li>
                </ul>
                <span className="storefront-about-section-return-policy-description">
                  No cancellations once order is accepted and processed. Transit
                  delays are not in our hands; however, we will help you to get
                  the order delivered soon!
                </span>
              </div>
            )}
          </div>
        </div>

        {/* <SelectAddress
          {...(streamId ? {streamId: streamId} : {})}
          storeId={product.store_id}
          open={isBuyNowInitiated && product ? true : false}
          onClose={() => {
            setIsBuyNowInitiated(false);
          }}
          product={
            selectedVariantIndex != -1 &&
            product?.["productPricingDetails"]?.[selectedVariantIndex]
              ? product?.["productPricingDetails"]?.[selectedVariantIndex]
              : {}
          }
        /> */}

        <AddressBottomSheet
          isOpen={isAddAddressInitiated && product ? true : false}
          storeId={product.store_id}
          onClose={() => {
            setIsAddAddressInitiated(false);
          }}
          product={productData}
          cartId={cartId ?? 0}
        />
      </div>

      <BottomSheetV2
        isOpen={isBuyNowInitiated && product ? true : false}
        onClose={() => {
          setIsBuyNowInitiated(false);
        }}>
        <BuyNowModal
          variantValues={variantValues}
          selectedVariantValue={selectedVariantValue}
          setSelectedVariantValue={setSelectedVariantValue}
          onChangeSelectedVariantValue={onChangeSelectedVariantValue}
          onSuccess={() => {
            setIsBuyNowInitiated(false);
            setIsAddAddressInitiated(true);
            addProductTocart();
          }}
          isVariantAvailable={isVariantAvailable}
          buyNowAllowed={buyNowAllowed}
        />
      </BottomSheetV2>
      <BottomSheetV2
        isOpen={loginModal}
        onClose={() => {
          setLoginModal(false);
        }}>
        <LoginModal
          onSuccess={() => {
            setLoginModal(false);
          }}
        />
      </BottomSheetV2>
    </div>
  );
};

export default ProductDetails;
