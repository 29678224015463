import React from "react";
import {SvgProps} from "../ChatSvg/ChatSvg";

const StoreImgSvg: React.FC<SvgProps> = ({width, height, color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24">
      <g clipPath="url(#clip0_60_31166)">
        <path
          fill={color ? color : "#141414"}
          d="M21.75 9q0-.106-.028-.206l-1.345-4.706A1.51 1.51 0 0 0 18.937 3H5.063a1.51 1.51 0 0 0-1.439 1.088L2.28 8.794A.7.7 0 0 0 2.25 9v1.5a3.75 3.75 0 0 0 1.5 3v6.75a.75.75 0 0 0 .75.75h15a.75.75 0 0 0 .75-.75V13.5a3.75 3.75 0 0 0 1.5-3zM5.063 4.5h13.875l1.07 3.75H3.995zM9.75 9.75h4.5v.75a2.25 2.25 0 0 1-4.5 0zm-1.5 0v.75a2.25 2.25 0 0 1-3.293 1.993.7.7 0 0 0-.17-.1A2.25 2.25 0 0 1 3.75 10.5v-.75zm10.5 9.75H5.25v-5.325A3.75 3.75 0 0 0 9 12.75a3.75 3.75 0 0 0 6 0 3.75 3.75 0 0 0 3.75 1.425zm.462-7.106a.8.8 0 0 0-.169.098A2.25 2.25 0 0 1 15.75 10.5v-.75h4.5v.75a2.25 2.25 0 0 1-1.038 1.894"></path>
      </g>
      <defs>
        <clipPath id="clip0_60_31166">
          <path fill="#fff" d="M0 0h24v24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default StoreImgSvg;
