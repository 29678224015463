import React from "react";
import "./OrderDetailsSelfShip.style.css";
import {ContentCopy} from "../../../../../components/SvgImages/ContentCopy/ContentCopy";
import {SelfShipOrderDetails} from "../../../../../features/Order/OrderInterface";

interface OrderDetailsSelfShipProps {
  selfShip: SelfShipOrderDetails;
}

const OrderDetailsSelfShip: React.FC<OrderDetailsSelfShipProps> = ({
  selfShip,
}) => {
  // const [data, setData] = useState<OrderItem | null>(null);
  // const {orderItems} = useSelector((state: RootState) => state.order);
  // const {id} = useParams<{id: string}>();
  // useEffect(() => {
  //   orderItems.data.forEach(item => {
  //     if (item.id === Number(id)) {
  //       setData(item);
  //     }
  //   });
  // }, [orderItems]);

  // const handleCopy = () => {
  //     var copyText = document.getElementById("copy-tracking-number");
  //     copyText.select();
  //     copyText.setSelectionRange(0, 99999); // For mobile devices

  //     // Copy the text inside the text field
  //      navigator.clipboard.writeText(copyText.value);
  // }

  const handleTracking = () => {
    try {
      const trackingUrl = selfShip?.tracking_url;
      if (trackingUrl) {
        window.open(trackingUrl);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className="order-details-self-ship-content">
      <div className="order-details-self-ship-row">
        <div className="order-details-self-ship-row-left">
          Courier partner name
        </div>
        <div className="order-details-self-ship-row-right">
          {selfShip?.courier_partner_name}
        </div>
      </div>
      <div className="order-details-self-ship-row">
        <div className="order-details-self-ship-row-left">Tracking Link</div>
        <div
          className="order-details-self-ship-row-right-tracking-url"
          onClick={handleTracking}>
          {selfShip?.tracking_url}
        </div>
      </div>
      <div className="order-details-self-ship-row">
        <div className="order-details-self-ship-row-left">Tracking number</div>
        <button className="order-details-self-ship-row-right-ctn">
          <div
            className="order-details-self-ship-row-right-cc"
            onClick={event => {
              const targetElement = event.currentTarget as HTMLElement;
              if (targetElement && targetElement.textContent) {
                navigator.clipboard.writeText(targetElement.textContent);
                console.log(
                  "Text copied to clipboard:",
                  targetElement.textContent,
                );
              } else {
                console.error("Element or text content is undefined");
              }
            }}>
            {selfShip?.tracking_number}
          </div>
          {/* </div> */}
          <div className="order-details-self-ship-row-right-copy-svg">
            <ContentCopy width={12} height={12} />
          </div>
        </button>
      </div>
    </div>
  );
};

export default OrderDetailsSelfShip;
