import React, {useEffect, useRef, useState} from "react";
import Hls from "hls.js";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import "./HslPlayer.style.css";

interface HLSPlayerProps {
  data: {
    public_url: string;
  };
  isLoop?: boolean;
  isMuted?: boolean;
  hlsContainerStyle?: React.CSSProperties;
  hlsVideoStyle?: React.CSSProperties;
  onVideoEnd?: () => void;
  pauseVideo?: boolean;
  externalHeaders?: boolean;
}

const HLSPlayer: React.FC<HLSPlayerProps> = ({
  data,
  isLoop = false,
  isMuted = false,
  hlsContainerStyle = {},
  hlsVideoStyle = {},
  pauseVideo,
  onVideoEnd,
  externalHeaders = true,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    if (videoRef.current && !isPlaying) {
      const timer = setTimeout(() => {
        handlePlay();
      }, 500);
      return () => clearTimeout(timer);
    }

    if (pauseVideo && pauseVideo == true) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
  }, [videoRef.current, data?.public_url, pauseVideo]);

  const handlePlay = () => {
    const videoElement = videoRef.current;

    if (videoElement && Hls.isSupported() && data?.public_url) {
      let hls;
      if (externalHeaders) {
        hls = new Hls({
          xhrSetup: (xhr: XMLHttpRequest) => {
            xhr.setRequestHeader(
              "Cache-Control",
              "no-store, no-cache, must-revalidate, max-age=0",
            );
            xhr.setRequestHeader("Pragma", "no-cache");
            xhr.setRequestHeader("Expires", "0");
          },
        });
      } else {
        hls = new Hls({});
      }

      hls.loadSource(`${data.public_url}?t=${Date.now()}`);
      hls.attachMedia(videoElement);
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        videoElement
          .play()
          .then(() => setIsPlaying(true))
          .catch(error => {
            console.error("Playback error:", error);
          });
      });
      hls.on(Hls.Events.MEDIA_ATTACHED, () => {
        videoElement.addEventListener("ended", () => {
          setIsPlaying(false);
          if (onVideoEnd) onVideoEnd();
        });
      });
    } else if (
      videoElement?.canPlayType("application/vnd.apple.mpegurl") &&
      `${data.public_url}?t=${Date.now()}`
    ) {
      videoElement.src = `${data.public_url}?t=${Date.now()}`;
      videoElement.addEventListener("loadedmetadata", () => {
        videoElement
          .play()
          .then(() => setIsPlaying(true))
          .catch(error => {
            console.error("Playback error:", error);
            alert(`Playback error:,${error}`);
          });
      });
      videoElement.addEventListener("ended", () => {
        setIsPlaying(false);
        if (onVideoEnd) onVideoEnd();
      });
    } else {
      alert("No Fallback");
    }
  };

  return (
    <React.Fragment>
      <div className="hls-container" style={{...hlsContainerStyle}}>
        <video
          style={{...hlsVideoStyle}}
          ref={videoRef}
          className="hls-video"
          controls={false}
          playsInline
          loop={isLoop}
          muted={isMuted}
        />
      </div>
      {!isPlaying && (
        <div
          onClick={handlePlay}
          style={{
            position: "absolute",
            top: "45%",
            left: "50%",
            cursor: "pointer",
            zIndex: 10000,
          }}>
          <PlayCircleOutlineIcon
            style={{
              color: "#f4f5d7",
              transform: "translate(-50%, -50%)",
              height: "60px",
              width: "60px",
            }}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default HLSPlayer;
