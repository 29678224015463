import React, {useState} from "react";
import "react-toastify/dist/ReactToastify.css";
import "./SaleProducts.style.css";
import {useNavigate} from "react-router";
import {convertIntoINR} from "../../../utils/helpers";
import SelectAddress from "../SelectAddress/SelectAddress";
import {StreamVariantMapping} from "../../../features/LiveStream/LiveStreamInterface";
import cookieUtils from "../../../utils/cookies";
import BottomSheetV2 from "../../mobileV2/BottomSheetV2/BottomSheetV2";
import {LoginModal} from "../../mobileV2/LoginModal/useLoginModal";

interface SaleProductProps {
  product: StreamVariantMapping;
  storeId: string;
  status: string;
  streamId?: string;
}

interface SaleProductsProps {
  products: StreamVariantMapping[];
  storeId: string;
  status: string;
}

const SaleProduct: React.FC<SaleProductProps> = ({
  product,
  storeId,
  status,
  streamId,
}) => {
  const [isSelectAddressOpen, setIsSelectAddressOpen] = useState(false);
  const [initiateBuyNow, setInitiateBuyNow] = useState(false);
  const [loginModal, setLoginModal] = useState(false);

  const discount =
    parseInt(product.cost_price?.toString()) -
    parseInt(product.selling_price?.toString());

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="sale-product-container">
        <div className="sale-products-body">
          <div className="sale-product-image-item">
            <img
              src={
                product.image_url && product.image_url[0]
                  ? product.image_url[0]
                  : ""
              }
              className="sale-product-image"
              alt="Product"
            />
          </div>
          <div className="sale-product-item">
            <div className="sale-product-item-header">
              <h5 className="sale-product-title">{product.varient_name}</h5>
              <h5 className="sale-product-subtitle">
                {product.varient_count}{" "}
                {product.varient_count === 1 ? "piece" : "pieces"} left
              </h5>
            </div>
            <div className="sale-product-item-body">
              {discount > 0 ? (
                <span className="sale-product-cost-price">
                  ₹{convertIntoINR(product.cost_price)}
                </span>
              ) : null}
              <span className="sale-product-selling-price">
                ₹{convertIntoINR(product.selling_price)}
              </span>

              {discount > 0 ? (
                <span className="sale-product-discount">
                  {(
                    (discount / parseInt(product.cost_price?.toString())) *
                    100
                  ).toFixed(0)}
                  % off
                </span>
              ) : null}
            </div>

            <div className="sale-product-item-footer">
              {
                <button
                  className="sale-product-button-container"
                  onClick={() => {
                    if (!cookieUtils.getCookie("token")) {
                      setLoginModal(true);
                    } else {
                      setIsSelectAddressOpen(true);
                      setInitiateBuyNow(true);
                    }
                  }}>
                  <span className="sale-product-button-text">Buy Now</span>
                </button>
              }

              <button
                className="sale-product-secondary-button-container"
                onClick={() => {
                  navigate(`/product/${product.product_id}`, {
                    state: {
                      product_variant_id: product.varient_id,
                      hide_buy_now: status === "Stream Ended" ? false : true,
                    },
                  });
                }}>
                <span className="sale-product-secondary-button-text">
                  View Product
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {initiateBuyNow && (
        <SelectAddress
          storeId={storeId}
          open={isSelectAddressOpen}
          onClose={() => {
            setIsSelectAddressOpen(false);
          }}
          product={product}
          streamId={streamId ?? ""}
        />
      )}
      {loginModal ? (
        <BottomSheetV2
          isOpen={loginModal}
          onClose={() => {
            setLoginModal(false);
          }}>
          <LoginModal
            onSuccess={() => {
              setLoginModal(false);
            }}
          />
        </BottomSheetV2>
      ) : null}
    </React.Fragment>
  );
};

const SaleProducts: React.FC<SaleProductsProps> = ({
  products,
  storeId,
  status,
}) => {
  return (
    <div className="sale-products-container">
      <h5 className="sale-products-title">Products for sale</h5>
      <hr style={{border: "1px solid #CECCD3"}} />
      {products.map((product, index) => (
        <SaleProduct
          key={index}
          product={product}
          storeId={storeId}
          status={status}
        />
      ))}
    </div>
  );
};

export default SaleProducts;
