import React, {useEffect, useState} from "react";
import "./AddressForm.css";
import {
  addAddressThunk,
  getCityStateByPincodeThunk,
} from "../../features/User/UserSlice";
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {AppDispatch} from "../../interceptors/store";

interface AddressFormProps {
  onSubmit: (addressData: AddressData) => void;
  type?: string;
  initialDeliveryAddress?: AddressData;
  existingUser?: boolean;
}

export interface AddressData {
  receiver_name: string;
  address_line_1: string;
  address_line_2: string;
  pincode: string;
  state: string;
  city: string;
  save_as: string;
  id?: number;
}

export const defaultDeliveryAddress: AddressData = {
  receiver_name: "",
  address_line_1: "",
  address_line_2: "",
  pincode: "",
  state: "",
  city: "",
  save_as: "",
};

const AddressForm: React.FC<AddressFormProps> = ({
  onSubmit,
  type,
  initialDeliveryAddress = defaultDeliveryAddress,
  // existingUser,
}) => {
  const [addressData, setAddressData] = useState<AddressData>(
    initialDeliveryAddress,
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    const {name, value} = e.target;
    setAddressData(prevData => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const payload: any = {
      user_address_details: {
        address_name: addressData.save_as,
        address_details_line1: addressData.address_line_1,
        address_details_line2: addressData.address_line_2,
        pincode: addressData.pincode,
        city: addressData.city,
        state: addressData.state,
        receiver_name: addressData.receiver_name,
        country: "india",
        address_type: "DELIVERY",
      },
    };

    if (addressData?.id) {
      payload["user_address_details"]["address_id"] = addressData?.id ?? null;
    }

    // setLoading(true);
    try {
      const action = await dispatch(addAddressThunk(payload));
      if (addAddressThunk.fulfilled.match(action)) {
        onSubmit(addressData);
      } else {
        toast.error(
          `Error while ${type === "edit" ? "updating" : "adding"} address`,
        );
      }
    } catch (error) {
      console.error(
        `Error ${type === "edit" ? "updating" : "adding"} address:`,
        error,
      );
    }
  };

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (addressData.pincode && addressData.pincode.toString().length === 6) {
      fetchCityState();
    }
  }, [addressData.pincode]);

  const fetchCityState = async () => {
    try {
      const payload = {pincode: addressData.pincode.toString()};
      const action = await dispatch(getCityStateByPincodeThunk(payload));
      if (getCityStateByPincodeThunk.fulfilled.match(action)) {
        const data = action.payload;
        if (data && data[0]) {
          setAddressData(prev => ({
            ...prev,
            city: data[0].city,
            state: data[0].state,
          }));
        } else {
          toast.error("Invalid pincode");
        }
      } else {
        toast.error("Invalid pincode");
      }
    } catch {
      toast.error("Error while validating pincode");
    }
  };

  // Function to check if all fields are filled
  const isFormComplete = () => {
    return (
      addressData.receiver_name &&
      addressData.address_line_1 &&
      addressData.address_line_2 &&
      addressData.pincode &&
      addressData.state &&
      addressData.city &&
      addressData.save_as
    );
  };

  return (
    <form className="address-form" onSubmit={handleSubmit}>
      <div className="address-form-group">
        <label htmlFor="receiverName">Receiver name</label>
        <input
          type="text"
          id="receiver_name"
          name="receiver_name"
          placeholder="Enter here"
          value={addressData.receiver_name}
          onChange={handleChange}
        />
      </div>

      <div className="address-form-group">
        <label htmlFor="address_line_1">Address line 1</label>
        <input
          type="text"
          id="address_line_1"
          name="address_line_1"
          placeholder="Enter here"
          value={addressData.address_line_1}
          onChange={handleChange}
        />
      </div>

      <div className="address-form-group">
        <label htmlFor="address_line_2">Address line 2</label>
        <input
          type="text"
          id="address_line_2"
          name="address_line_2"
          placeholder="Enter here"
          value={addressData.address_line_2}
          onChange={handleChange}
        />
      </div>

      <div className="address-form-group">
        <label htmlFor="pincode">Pincode</label>
        <input
          type="text"
          id="pincode"
          name="pincode"
          placeholder="Enter here"
          value={addressData.pincode}
          onChange={handleChange}
        />
      </div>

      <div className="address-form-row">
        <div className="address-form-group address-form-half-width">
          <label htmlFor="state">State</label>
          <input
            type="text"
            id="state"
            name="state"
            placeholder="Enter here"
            value={addressData.state}
            onChange={handleChange}
          />
        </div>

        <div className="address-form-group address-form-half-width">
          <label htmlFor="city">City</label>
          <input
            type="text"
            id="city"
            name="city"
            placeholder="Enter here"
            value={addressData.city}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="address-form-group">
        <label htmlFor="saveAs">Save as</label>
        <input
          type="text"
          id="save_as"
          name="save_as"
          placeholder="Home, work, family, friend, other..."
          value={addressData.save_as}
          onChange={handleChange}
        />
      </div>

      {type !== "add" ? (
        <div className="address-form-submit-edit">
          <button
            className={"address-form-submit-cancel-button"}
            onClick={() => {}}>
            Cancel
          </button>
          <button
            type="submit"
            className={
              isFormComplete()
                ? "address-form-submit-update-button"
                : "address-form-submit-button"
            }
            disabled={!isFormComplete()} // Disable if form is not complete
          >
            Update
          </button>
        </div>
      ) : (
        <button
          type="submit"
          className={
            isFormComplete()
              ? "address-form-submit-button-active"
              : "address-form-submit-button"
          }
          disabled={!isFormComplete()} // Disable if form is not complete
        >
          Continue
        </button>
      )}
    </form>
  );
};

export default AddressForm;
