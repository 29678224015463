import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../interceptors/store";
import {getFeaturedStoresThunk} from "../../features/User/UserSlice";
import {useEffect} from "react";
import "./FeaturedStores.css";
import CaretRight from "../SvgImages/CaretRight/CaretRight";
import {useNavigate} from "react-router";
// import Carousel from "../mobile/Carousel/Carousel";

interface StoreCardProps {
  logo: string; // URL of the product image
  discount?: number; // Discount percentage (optional)
  tagLine: string;
  // rating: string; // Rating value
  // reviews?: number; // Number of reviews
  storeName: string; // Product name
  storeId: string;
}

const FeaturedStores: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getFeaturedStoresThunk({key_name: "store_id", limit: 8}));
  }, [dispatch]);

  const {featuredStores} = useSelector((state: RootState) => state.user);

  return (
    <div className="featured-stores-container">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <h2
          className="featured-stores-title"
          onClick={() => {
            // console.log("redirect to top live page");
          }}>
          Top Stores
        </h2>
        <span
          style={{
            marginTop: "12px",
          }}>
          <CaretRight width={16} height={16} />
        </span>
      </div>
      <div className="featured-stores-grid">
        {featuredStores.map((item, index) => {
          return (
            <div key={index}>
              <StoreCard
                logo={item.feature_media_details[0].image_url}
                // rating={4.2}
                tagLine={item.tag_line}
                // reviews={173}
                storeName={item?.seller_name}
                storeId={item?.key_value}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const StoreCard: React.FC<StoreCardProps> = ({
  logo,
  tagLine,
  storeName,
  storeId,
}) => {
  const navigate = useNavigate();

  return (
    <div className="featured-store-card">
      <div className="featured-store-logo">
        <img src={logo} alt={`${storeName} logo`} />
      </div>
      <h2 className="featured-store-name">{storeName}</h2>
      <p className="featured-store-description">{tagLine}</p>
      <button
        className="featured-visit-store-btn"
        onClick={() => {
          navigate(`/storefront/${storeId}`);
        }}>
        Visit Store
      </button>
    </div>
  );
};

export default FeaturedStores;
