import moment from "moment";

export const getStreamStatus = stream => {
  let timeLeft = "";
  const currentTime = moment();
  const scheduledTime = moment(stream?.scheduled_time);
  const duration = moment.duration(scheduledTime.diff(currentTime));

  if (
    duration.asSeconds() > 0 &&
    (stream.status === "open" || stream.status === "url_generated")
  ) {
    const hours = Math.floor(duration.asHours()).toString().padStart(2, "0");
    const minutes = Math.floor(duration.minutes()).toString().padStart(2, "0");
    const seconds = Math.floor(duration.seconds()).toString().padStart(2, "0");

    timeLeft = `${hours}:${minutes}:${seconds}`;
  } else {
    timeLeft = "Live Now";
  }

  return timeLeft === "Live Now"
    ? stream.status === "in_progress" || stream.status === "url_generated"
      ? "Live"
      : "Stream Ended"
    : "Upcoming";
};
