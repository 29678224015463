/* eslint-disable @typescript-eslint/no-explicit-any */
import {api, apiConfig} from "../../interceptors/api";
import {
  GetIndividualOrdersByUserResponse,
  GetOrderByOrderIdResponse,
  GetOrderByOrderRefIdResponse,
  GetOrderDeliveryStatusApiResponse,
  GetOrdersByUserResponse,
  GetOrdersPayload,
} from "./OrderInterface";

export const getOrdersApi = async (
  payload: any,
): Promise<GetOrdersByUserResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.orderUrl}/api/order/order_by_user`,
      {
        params: payload,
      },
    );
    return response.data;
  } catch (error: any) {
    console.log("error", error);
    throw new Error(error);
  }
};

export const getRawOrdersApi = async (
  payload: any,
): Promise<GetOrdersByUserResponse> => {
  try {
    const response = await api.get(`${apiConfig.orderUrl}/api/order`, {
      params: payload,
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error);
    throw new Error(error);
  }
};

export const getOrderByOrderIdApi = async (
  orderId: string,
): Promise<GetOrderByOrderIdResponse> => {
  try {
    const response = await api.get(`${apiConfig.orderUrl}/api/order`, {
      params: {order_id: orderId},
    });

    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getOrderDeliveryStatusApi = async (
  orderId: string,
): Promise<GetOrderDeliveryStatusApiResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.orderUrl}/api/order/order_delivery_status/${orderId}`,
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getOrderByOrderRefIdApi = async (
  orderRefId: string,
): Promise<GetOrderByOrderRefIdResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.orderUrl}/api/order/order_by_ref/${orderRefId}`,
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getIndividualOrdersApi = async (
  payload: GetOrdersPayload,
): Promise<GetIndividualOrdersByUserResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.orderUrl}/api/order/order_by_user`,
      {
        params: payload,
      },
    );
    return response.data;
  } catch (error: any) {
    console.log("error", error);
    throw new Error(error);
  }
};
