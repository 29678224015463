import React, {useState} from "react";
import "./ProductCard.style.css";
import SelectAddress from "../SelectAddress/SelectAddress";
import {convertIntoINR} from "../../../utils/helpers";
import {StreamVariantMapping} from "../../../features/LiveStream/LiveStreamInterface";

interface ProductCardProps {
  product: StreamVariantMapping;
  streamId: string;
  storeId: string;
}

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  streamId,
  storeId,
}) => {
  const [initiatePayment, setInitiatePayment] = useState<boolean>(false);
  const [isSelectAddressOpen, setIsSelectAddressOpen] =
    useState<boolean>(false);

  const discount =
    parseInt(product.cost_price?.toString()) -
    parseInt(product.selling_price?.toString());

  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    console.log("Image failed to load, setting fallback...");
    e.currentTarget.src =
      "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png";
  };

  return (
    <div className="product-card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          width: "100%",
        }}>
        <img
          className="productcard-image"
          src={
            product.image_url && product.image_url[0]
              ? product.image_url[0]
              : "https://buffer.com/library/content/images/size/w1200/2023/10/free-images.jpg"
          }
          alt={product.varient_name}
          onError={handleError}
        />
        <div className="productcard-details">
          <span className="productcard-details-item">
            {product.varient_name}
          </span>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              gap: "5px",
              alignItems: "baseline",
            }}>
            <span className="productcard-details-item-price">
              ₹
              {product.selling_price
                ? convertIntoINR(product.selling_price)
                : 0}{" "}
            </span>
            {discount > 0 ? (
              <React.Fragment>
                <s style={{color: "white", fontSize: "12px"}}>
                  ₹{product.cost_price ? convertIntoINR(product.cost_price) : 0}
                </s>
                <span style={{color: "#02de62", fontSize: "12px"}}>
                  (
                  {(
                    (discount / parseInt(product.cost_price?.toString())) *
                    100
                  ).toFixed(0)}
                  % off){" "}
                </span>
              </React.Fragment>
            ) : (
              <div />
            )}
          </div>
        </div>
      </div>
      {product.varient_count === 0 ? (
        <div className="product-card-sold-out-strip">
          <span style={{fontSize: "0.8rem"}}>Sold Out</span>
        </div>
      ) : (
        <button
          className="buy-button"
          disabled={product.is_marked_active !== 1}
          onClick={() => {
            setIsSelectAddressOpen(true);
            setInitiatePayment(true);
          }}>
          Buy Now
        </button>
      )}

      {initiatePayment && (
        <SelectAddress
          streamId={streamId}
          storeId={storeId}
          open={isSelectAddressOpen}
          onClose={() => setIsSelectAddressOpen(false)}
          product={product}
        />
      )}
    </div>
  );
};

export default ProductCard;
