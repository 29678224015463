import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const ChatSvg: React.FC<SvgProps> = ({height, width}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99994 1.875C8.59718 1.87469 7.21824 2.23757 5.99733 2.9283C4.77642 3.61904 3.75514 4.6141 3.03289 5.81664C2.31064 7.01918 1.91204 8.38822 1.87587 9.79052C1.8397 11.1928 2.16721 12.5806 2.82651 13.8188L1.93979 16.4789C1.86634 16.6992 1.85568 16.9355 1.909 17.1615C1.96233 17.3874 2.07753 17.5941 2.2417 17.7582C2.40587 17.9224 2.61252 18.0376 2.83848 18.0909C3.06444 18.1443 3.30079 18.1336 3.52104 18.0602L6.18119 17.1734C7.27086 17.753 8.47823 18.0767 9.71168 18.12C10.9451 18.1633 12.1722 17.925 13.2998 17.4232C14.4274 16.9215 15.4259 16.1694 16.2195 15.2241C17.013 14.2789 17.5808 13.1652 17.8797 11.9678C18.1786 10.7703 18.2008 9.52047 17.9446 8.31315C17.6883 7.10584 17.1604 5.97276 16.4009 4.99993C15.6414 4.02711 14.6702 3.24009 13.5611 2.69864C12.452 2.15718 11.2342 1.87551 9.99994 1.875ZM9.99994 16.875C8.79133 16.8758 7.60393 16.5575 6.55776 15.9523C6.48116 15.9079 6.396 15.8803 6.30791 15.8713C6.21983 15.8622 6.13083 15.872 6.04682 15.9L3.12494 16.875L4.09916 13.9531C4.12724 13.8692 4.13717 13.7802 4.12828 13.6921C4.11939 13.604 4.09189 13.5188 4.0476 13.4422C3.28976 12.132 2.9855 10.6083 3.182 9.10747C3.3785 7.60667 4.06478 6.21267 5.13439 5.14171C6.20399 4.07076 7.59713 3.38271 9.09767 3.18431C10.5982 2.98592 12.1223 3.28826 13.4335 4.04444C14.7447 4.80062 15.7697 5.96837 16.3494 7.36652C16.9292 8.76468 17.0314 10.3151 16.6401 11.7773C16.2488 13.2394 15.386 14.5316 14.1854 15.4533C12.9848 16.375 11.5135 16.8748 9.99994 16.875Z"
        fill="#FFF9C2"
      />
    </svg>
  );
};

export default ChatSvg;
