import React, {useState, useEffect} from "react";
import "./Stream.style.css";
import {useNavigate, useParams} from "react-router";
import Loader from "../../../Loader/Loader";
import {getLiveStreamProducts} from "../../../features/LiveStream/LiveStreamApi";
import LiveStream from "../../../components/mobile/LiveStream/LiveStream";
import {useDispatch, useSelector} from "react-redux";
import {setAuthentication} from "../../../features/Authentication/AuthenticationSlice";
import ReactGA from "react-ga4";
import {useLocation} from "react-router-dom";
import cookieUtils from "../../../utils/cookies";
import {LiveStreamProps} from "../../../features/LiveStream/LiveStreamInterface";
import {RootState} from "../../../interceptors/store";
import BottomSheetV2 from "../../../components/mobileV2/BottomSheetV2/BottomSheetV2";
import {LoginModal} from "../../../components/mobileV2/LoginModal/useLoginModal";

const Stream: React.FC = () => {
  const [stream, setStream] = useState<LiveStreamProps>();
  const [loading, setLoading] = useState<boolean>(false);
  const params = useParams<{id: string}>();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginModal, setLoginModal] = useState<boolean>(false);

  // const {isSignedIn} = useSelector((state: RootState) => state.authentication);
  const {user} = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (cookieUtils.getCookie("token")) {
      dispatch(setAuthentication(true));
    }
  }, [dispatch]);

  useEffect(() => {
    if (params.id) {
      fetchStreamProducts();
    }
  }, [params.id]);

  const fetchStreamProducts = async () => {
    setLoading(true);
    try {
      const payload = {
        stream_id: params.id,
      };
      const response = await getLiveStreamProducts(payload);
      if (response.data && response.data[0]) {
        const data = response.data[0];
        setStream(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  const handleEnterStream = () => {
    // if (!isSignedIn) {
    //   setLoginModal(true);
    //   return;
    // }

    ReactGA.event({
      category: "User",
      action: "Stream Clicked",
      label:
        "Enter Stream by " +
        localStorage.getItem("userId") +
        " on " +
        (stream ? stream.id : ""),
      value: 1,
      nonInteraction: true,
      // @ts-expect-error: Ignoring the type check for custom dimensions
      dimension1: location.pathname + location.search,
      dimension2: localStorage.getItem("userId") || null,
      dimension3: stream ? stream.id : "",
      dimension4: stream ? JSON.stringify(stream) : "",
    });
    if (stream) {
      navigate(`/stream/${stream.id}/live`);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div style={{maxWidth: "1000px", alignSelf: "center"}}>
      <div className="mobile-live-stream-container">
        {stream && (
          <LiveStream
            item={stream}
            showSaleProducts={true}
            showCountdown={false}
            streamId={stream.id.toString()}
            user={user}
          />
        )}

        {stream &&
          (stream.status === "in_progress" ||
            stream.status === "url_generated") && (
            <div className="enter-stream-button-container">
              <button
                className="enter-stream-button"
                onClick={handleEnterStream}>
                <span className="enter-stream-button-text">Enter Stream</span>
              </button>
            </div>
          )}
      </div>
      <BottomSheetV2
        isOpen={loginModal}
        onClose={() => {
          setLoginModal(false);
        }}>
        <LoginModal
          onSuccess={() => {
            setLoginModal(false);
          }}
        />
      </BottomSheetV2>
    </div>
  );
};

export default Stream;
