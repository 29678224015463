/* eslint-disable @typescript-eslint/no-explicit-any */
import {api, apiConfig} from "../../interceptors/api";
import {GetStreamDetailsResponse} from "./LiveStreamInterface";

export const getLiveStreams = async (): Promise<GetStreamDetailsResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.liveStreamUrl}/api/stream-details/form`,
      {
        params: {
          order_type: "desc",
          order_key: "id",
          limit: 8,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.log("error", error);
    throw new Error("Failed to fetch stream details");
  }
};

export const getLiveStreamProducts = async (
  payload: any,
): Promise<GetStreamDetailsResponse> => {
  try {
    const response = await api.get(
      `${apiConfig.liveStreamUrl}/api/stream-details/form`,
      {
        params: {
          stream_id: payload.stream_id,
        },
      },
    );

    return response.data;
  } catch (error) {
    console.log("Error", error);
    throw new Error("Failed to fetch departments data");
    // Add more API functions as needed
  }
};
