import {useState, useEffect} from "react";
import {
  getCouponsList,
  getCouponCalculatedAmount,
} from "../features/Coupon/CouponApi";

export interface Coupon {
  code: string;
  description?: string;
  discount_type: string;
  condition: string;
  discount_value: string;
  coupon_id?: string;
  final_amt: number;
  coupon_discount?: number;
  cart_value: number;
  max_discount_value: string;
  valid_until: string;
  status: string;
}

export interface AppliedCoupon {
  final_amt: number;
  cart_value: number;
  coupon_discount: number;
  code: string;
}

export interface CouponCardProps {
  cartValue: number;
  cartId: number;
}

export const useCoupons = (cartValue: number, cartId: number) => {
  const [coupon, setCoupon] = useState<AppliedCoupon | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [customCode, setCustomCode] = useState("");
  const [applyLoader, setApplyLoader] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await getCouponsList();
        setCoupons(response.data?.coupons || []);
      } catch (error) {
        console.error("Error fetching coupons:", error);
      }
    };

    fetchCoupons();
  }, []);

  const calculateDiscount = (coupon: Coupon): string => {
    if (coupon.discount_type === "FIXED") {
      return `₹${parseInt(coupon.discount_value)}`;
    }

    const calculatedDiscount =
      (parseInt(coupon.discount_value) * cartValue) / 100;
    const maxDiscount = Number(coupon?.max_discount_value || 0);
    const finalDiscount =
      maxDiscount > 0
        ? Math.min(calculatedDiscount, maxDiscount)
        : calculatedDiscount;

    return `₹${parseInt(finalDiscount.toString())}`;
  };

  const handleCouponSelect = async (selectedCoupon: Coupon) => {
    setApplyLoader(true);
    try {
      const payload = {
        cart_ids: [cartId],
        coupon_id: selectedCoupon.code,
        user_id: localStorage.getItem("userId"),
        cart_delivery_address_id: null,
      };

      const response = await getCouponCalculatedAmount(payload);

      if (response && response.final_amt !== undefined) {
        const updatedCoupon: AppliedCoupon = {
          final_amt: response.final_amt,
          cart_value: cartValue,
          coupon_discount: response.coupon_discount,
          code: selectedCoupon?.code || "",
        };

        setCoupon(updatedCoupon);
        setIsOpen(false);
        setErrorMessage("");
      } else {
        setErrorMessage("Coupon is not valid");
        console.log("Invalid response structure:", response);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log("Invalid response structure:", error);
      if (error.response || error.request) {
        setErrorMessage("Coupon is not valid");
      } else {
        setErrorMessage("Coupon is not valid");
      }
    } finally {
      setApplyLoader(false);
    }
  };

  const applyCustomCode = async (code: string) => {
    setApplyLoader(true); // Show loader when coupon is being applied

    try {
      // Prepare API request payload
      const payload = {
        cart_ids: [cartId],
        coupon_id: code.toUpperCase(),
        user_id: localStorage.getItem("userId"),
        cart_delivery_address_id: null,
      };

      const response = await getCouponCalculatedAmount(payload);

      // Check if response has the expected structure
      if (response && response.final_amt !== undefined) {
        // Update coupon object with new values
        const updatedCoupon = {
          code: code.toUpperCase(),
          final_amt: response.final_amt,
          cart_value: cartValue,
          coupon_discount: response.coupon_discount,
          description: "",
          discount_type: "",
          discount_value: "",
          max_discount_value: "",
          condition: "",
        };

        // Update state with the new coupon information
        setCoupon(updatedCoupon);
        setIsOpen(false);
        setErrorMessage("");
      } else {
        setErrorMessage("Coupon is not valid");
        // Handle case where the response doesn't have expected structure
        console.log("Invalid response structure:", response);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      // Handling different error types
      if (error.response) {
        setErrorMessage("Coupon is not valid");
      } else if (error.request) {
        setErrorMessage("Coupon is not valid");
      } else {
        setErrorMessage("Coupon is not valid");
      }
    } finally {
      // Hide loader after the process completes
      setApplyLoader(false);
    }
  };

  return {
    coupon,
    isOpen,
    setIsOpen,
    coupons,
    customCode,
    setCustomCode,
    applyLoader,
    errorMessage,
    calculateDiscount,
    handleCouponSelect,
    applyCustomCode,
  };
};
