import React, {useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../interceptors/store";
import {matchPath, useLocation, useNavigate} from "react-router";
import BottomSheet from "../BottomSheet/BottomSheet";
import MobileHeader from "./MobileHeader";
import DesktopHeader from "./DesktopHeader";
import {CategoryMenu} from "../../CategoryMenu/CategoryMenu";
import cookieUtils from "../../../utils/cookies";
// import useMobileSize from "../../../hooks/useMobileSize";
import "./Header.style.css";

const Header: React.FC = () => {
  const [isLogoutBottomSheetOpen, setIsLogoutBottomSheetOpen] =
    useState<boolean>(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const {isSignedIn} = useSelector((state: RootState) => state.authentication);

  // Handle search functionality
  const handleSearch = (query: string) => {
    navigate(`/search?query=${encodeURIComponent(query.trim())}`);
  };

  // Determine if we should render header based on the current path
  const renderHeader = ![
    "/profile",
    "/checkout",
    "/orders",
    "/stream",
    "/stream/:id",
  ].some(path => matchPath({path, end: true}, location.pathname));

  // const isMobile = useMobileSize();
  const isMobile = true;

  // Render mobile or desktop header based on screen size
  const renderHeaderContent = isMobile ? (
    <MobileHeader
      isSignedIn={isSignedIn}
      handleSearch={handleSearch}
      setIsLogoutBottomSheetOpen={setIsLogoutBottomSheetOpen}
      setIsPopupOpen={setIsPopupOpen}
    />
  ) : (
    <DesktopHeader
      isSignedIn={isSignedIn}
      handleSearch={handleSearch}
      setIsLogoutBottomSheetOpen={setIsLogoutBottomSheetOpen}
      setIsPopupOpen={setIsPopupOpen}
      isPopupOpen={isPopupOpen}
    />
  );

  // Logout confirmation modal
  const handleLogout = () => {
    localStorage.clear();
    cookieUtils.removeCookie("token");
    window.location.href = "/";
  };

  return (
    <>
      {renderHeader && renderHeaderContent}

      <BottomSheet
        header="Logout"
        isOpen={isLogoutBottomSheetOpen}
        onClose={() => setIsLogoutBottomSheetOpen(false)}>
        <div className="header-logout-content-container">
          <p style={{color: "black"}}>Are you sure you want to logout?</p>
          <div className="header-logout-actions">
            <button className="logout-yes-button" onClick={handleLogout}>
              Yes
            </button>
            <button
              className="logout-no-button"
              onClick={() => setIsLogoutBottomSheetOpen(false)}>
              No
            </button>
          </div>
        </div>
      </BottomSheet>

      <CategoryMenu
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
      />
    </>
  );
};

export default Header;
