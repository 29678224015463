import {createSlice, createAsyncThunk, PayloadAction} from "@reduxjs/toolkit";
import {
  CategoryProductsProps,
  // GetProductsByFilterPayload,
  GetProductsPayload,
  GetWishlistProductsApiPayload,
  GetWishlistsApiParams,
  Product,
  ProductState,
  SearchApiPayload,
  SearchProductsProps,
  WishlistProduct,
} from "./ProductInterface";
import {
  getProducts,
  getProductById,
  // getProductSkuById,
  searchApi,
  getWishlistsApi,
  getWishlistProductsApi,
  // getProductsByFilterApi,
} from "./ProductAPI";
import {removeDuplicatesByKey} from "../../utils/helpers";
// import {removeDuplicatesByKey} from "../../utils/helpers";

// Category data structure
// interface CategoryProductsData {
//   page: number;
//   products: Product[];
//   net_count: number;
//   limit: number;
// }

export const initialProductState: ProductState = {
  storeProducts: {
    data: [],
    page: 1,
    limit: 5,
    count: 0,
  },
  categoryProducts: {
    data: [],
    page: 1,
    limit: 5,
    count: 0,
  },
  searchProducts: {
    data: [],
    page: 1,
    limit: 5,
    count: 10,
  },
  product: {
    data: null,
    loading: false,
  },
  storeCollection: {
    data: [],
    loading: false,
    error: null,
  },
  wishlistProducts: {
    data: [],
    page: 1,
    limit: 5,
    count: 10,
    loading: false,
    error: null,
  },
  loading: false,
  error: null,
};

// Filter products and remove duplicates
// const getFilteredProducts = (
//   previousProducts: Product[],
//   data: CategoryProductsData,
// ): Product[] => {
//   let tempProducts =
//     data.page === 1
//       ? [...data.products]
//       : [...previousProducts, ...data.products];

//   tempProducts = removeDuplicatesByKey(tempProducts, "id");

//   return tempProducts;
// };

export const getCategoryProductsThunk = createAsyncThunk(
  "getCategoryProducts",
  async (payload: GetProductsPayload, {rejectWithValue}) => {
    try {
      const response = await getProducts(payload);
      return response;
    } catch (error) {
      console.error("Error fetching products:", error); // Log the error
      return rejectWithValue("Failed to fetch products");
    }
  },
);

export const getProductByIdThunk = createAsyncThunk(
  "getProductById",
  async (productId: number, {rejectWithValue}) => {
    try {
      const response = await getProductById(productId);
      return response;
    } catch (error) {
      console.error("Error fetching products:", error); // Log the error
      return rejectWithValue("Failed to fetch product");
    }
  },
);

export const getSearchThunk = createAsyncThunk(
  "products/getSearch",
  async (payload: SearchApiPayload, {rejectWithValue}) => {
    try {
      const response = await searchApi(payload);
      return response;
    } catch (error) {
      console.error("Error fetching products:", error); // Log the error
      return rejectWithValue("Failed to fetch product");
    }
  },
);

export const getStoreCollectionThunk = createAsyncThunk(
  "geStoreCollectons",
  async (params: GetWishlistsApiParams = {}, {rejectWithValue}) => {
    try {
      const response = await getWishlistsApi(params);
      return response;
    } catch (error) {
      console.error("Error fetching wishlists:", error); // Log the error
      return rejectWithValue("Failed to fetch wishlists");
    }
  },
);

export const getWishlistProductsThunk = createAsyncThunk(
  "getWishlistProducts",
  async (payload: GetWishlistProductsApiPayload, {rejectWithValue}) => {
    try {
      const response = await getWishlistProductsApi(payload);
      return response;
    } catch (error) {
      console.error("Error fetching wishlist products:", error);
      return rejectWithValue("Failed to fetch wishlist products");
    }
  },
);

const getFilteredWishlistProducts = (
  previousProducts: WishlistProduct[],
  data: WishlistProduct[],
  page: number,
  duplicateKey = "id",
): WishlistProduct[] => {
  let tempProducts = page === 1 ? [...data] : [...previousProducts, ...data];

  tempProducts = removeDuplicatesByKey(tempProducts, duplicateKey);

  return tempProducts;
};

export const productSlice = createSlice({
  name: "product",
  initialState: initialProductState,
  reducers: {
    reset: () => initialProductState,
    setSearchProducts: (state, action: PayloadAction<SearchProductsProps>) => {
      state.searchProducts = action.payload;
    },
    setCategoryProducts: (
      state,
      action: PayloadAction<CategoryProductsProps>,
    ) => {
      state.categoryProducts = action.payload;
    },
  },
  extraReducers: builder => {
    builder

      // Get Category products
      .addCase(getCategoryProductsThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCategoryProductsThunk.fulfilled, (state, action) => {
        const {data} = action.payload;

        state.loading = false;
        state.categoryProducts = {
          data: data.products as Product[],
          count: data.net_count,
          page: data.page,
          limit: data.limit,
        };
        state.error = null;
      })
      .addCase(getCategoryProductsThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Get Product By ID
      .addCase(getProductByIdThunk.pending, state => {
        state.product.loading = true;
        state.error = null;
      })
      .addCase(getProductByIdThunk.fulfilled, (state, action) => {
        const {data} = action.payload;
        state.product.loading = false;
        state.product.data = data.product;
        state.error = null;
      })
      .addCase(getProductByIdThunk.rejected, (state, action) => {
        state.product.loading = false;
        state.error = action.payload as string;
      })
      .addCase(getStoreCollectionThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getStoreCollectionThunk.fulfilled, (state, action) => {
        const {data} = action.payload;
        state.loading = false;
        state.storeCollection.data = data.wishlists;
        state.error = null;
      })
      .addCase(getStoreCollectionThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Get Wishlist Products
      .addCase(getWishlistProductsThunk.pending, state => {
        state.wishlistProducts.loading = true;
        state.wishlistProducts.error = null;
      })
      .addCase(getWishlistProductsThunk.fulfilled, (state, action) => {
        state.wishlistProducts.loading = false;

        const {data} = action.payload;
        state.wishlistProducts.data = getFilteredWishlistProducts(
          state.wishlistProducts.data,
          data.wishlist.wishlist_items,
          data.page,
          "id",
        );
        state.wishlistProducts.page = data.page;
        state.wishlistProducts.limit = data.limit;
        state.wishlistProducts.count = data.net_count
          ? data.net_count
          : data.wishlist.wishlist_items.length;
        state.error = null;
      })
      .addCase(getWishlistProductsThunk.rejected, (state, action) => {
        state.wishlistProducts.loading = false;
        state.wishlistProducts.error = action.payload as string;
      });
  },
});

export default productSlice.reducer;
export const {reset, setSearchProducts, setCategoryProducts} =
  productSlice.actions;
