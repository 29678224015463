import React, {useEffect, ReactNode} from "react";
import "./BottomSheet.style.css";
import {IoCloseCircleOutline} from "react-icons/io5";

interface BottomSheetProps {
  header: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  footer?: ReactNode;
}

const BottomSheet: React.FC<BottomSheetProps> = ({
  header,
  isOpen,
  onClose,
  children,
  footer,
}) => {
  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLElement).classList.contains("bottom-sheet-overlay")) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  return (
    <div
      className={`bottom-sheet-overlay ${isOpen ? "open" : ""}`}
      onClick={handleOverlayClick}>
      <div className={`bottom-sheet ${isOpen ? "open" : ""}`}>
        <div className="bottom-sheet-header">
          <span className="bottom-sheet-header-text">{header}</span>
          <button className="close-button" onClick={onClose}>
            <IoCloseCircleOutline size={20} />
          </button>
        </div>
        <div className="bottom-sheet-content">{children}</div>
        {footer && <div className="bottom-sheet-footer">{footer}</div>}
      </div>
    </div>
  );
};

export default BottomSheet;
