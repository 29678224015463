import React from "react";
import "./OrderProductCard.style.css";
import {Variant} from "../../features/Order/OrderInterface";

interface OrderProductCardProps {
  productName?: string;
  productDescription?: string;
  productQuantity?: number;
  variants?: Variant[];
  productImage?: string;
}

const OrderProductCard = ({
  productName,
  productDescription,
  productQuantity,
  variants,
  productImage,
}: OrderProductCardProps) => {
  const handleError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    console.log("Image failed to load, setting fallback...");
    e.currentTarget.src =
      "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png";
  };

  return (
    <div className="orders-page-product-container">
      <div className="orders-page-left-container">
        <img
          src={productImage}
          alt={productName}
          className="orders-page-image"
          onError={handleError}
        />
      </div>
      <div className="orders-page-right-container">
        <div className="orders-page-right-container-top">
          <div className="orders-page-name">{productName}</div>
          <div className="orders-page-description">{productDescription}</div>
        </div>
        <div className="orders-page-button-container">
          <div className="orders-page-quantity-container">
            <div className="orders-page-dropdown-button">
              <span>
                Quantity -{" "}
                <span className="orders-page-dropdown-button-value">
                  {productQuantity}
                </span>
              </span>
            </div>
          </div>
          {variants &&
            variants.length > 0 &&
            variants?.[0] &&
            variants?.[0].label !== "No Variant" && (
              <div
                className="orders-page-dropdown-button-container"
                style={{display: "flex"}}>
                {variants.map(
                  (variant, index) =>
                    index < 2 && (
                      <div key={index} className="orders-page-dropdown-button">
                        <span>
                          {variant?.label?.toLowerCase().includes("size")
                            ? "Size"
                            : variant.label}{" "}
                          -{" "}
                          <span className="orders-page-dropdown-button-value">
                            {variant.value}
                          </span>
                        </span>
                      </div>
                    ),
                )}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default OrderProductCard;
