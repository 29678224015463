import React from "react";
export interface SvgProps {
  height: number | string;
  width: number | string;
}

const SignOut: React.FC<SvgProps> = ({height, width}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.6875 16.875C9.6875 17.1236 9.58873 17.3621 9.41291 17.5379C9.2371 17.7137 8.99864 17.8125 8.75 17.8125H3.75C3.50136 17.8125 3.2629 17.7137 3.08709 17.5379C2.91127 17.3621 2.8125 17.1236 2.8125 16.875V3.125C2.8125 2.87636 2.91127 2.6379 3.08709 2.46209C3.2629 2.28627 3.50136 2.1875 3.75 2.1875H8.75C8.99864 2.1875 9.2371 2.28627 9.41291 2.46209C9.58873 2.6379 9.6875 2.87636 9.6875 3.125C9.6875 3.37364 9.58873 3.6121 9.41291 3.78791C9.2371 3.96373 8.99864 4.0625 8.75 4.0625H4.6875V15.9375H8.75C8.99864 15.9375 9.2371 16.0363 9.41291 16.2121C9.58873 16.3879 9.6875 16.6264 9.6875 16.875ZM18.1633 9.33672L15.0383 6.21172C14.8622 6.0356 14.6233 5.93665 14.3742 5.93665C14.1251 5.93665 13.8863 6.0356 13.7102 6.21172C13.534 6.38784 13.4351 6.62671 13.4351 6.87578C13.4351 7.12485 13.534 7.36372 13.7102 7.53984L15.2344 9.0625H8.75C8.50136 9.0625 8.2629 9.16127 8.08709 9.33709C7.91127 9.5129 7.8125 9.75136 7.8125 10C7.8125 10.2486 7.91127 10.4871 8.08709 10.6629C8.2629 10.8387 8.50136 10.9375 8.75 10.9375H15.2344L13.7094 12.4617C13.5333 12.6378 13.4343 12.8767 13.4343 13.1258C13.4343 13.3749 13.5333 13.6137 13.7094 13.7898C13.8855 13.966 14.1244 14.0649 14.3734 14.0649C14.6225 14.0649 14.8614 13.966 15.0375 13.7898L18.1625 10.6648C18.2499 10.5778 18.3194 10.4743 18.3667 10.3604C18.4141 10.2465 18.4386 10.1243 18.4386 10.0009C18.4387 9.87755 18.4144 9.75537 18.3672 9.64138C18.3199 9.5274 18.2506 9.42387 18.1633 9.33672Z"
        fill="#B32318"
      />
    </svg>
  );
};

export default SignOut;
