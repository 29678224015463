import React from "react";
import sign from "jwt-encode";
import Config from "../app/config";
import moment from "moment";
import cookieUtils from "./cookies";
import {toast} from "react-toastify";

export const logoutUser = async () => {
  toast.error("Your authentication has expired");
  setTimeout(() => {
    localStorage.clear();
    cookieUtils.removeCookie("token");
    window.location.href = "/";
  }, 2000);
};

/**
 *
 * @param array
 * @param key
 * @param value
 * @returns
 */
export const findIndexByKey = (array, key, value) => {
  for (let i = 0; i < array.length; i++) {
    if (array[i][key] == value) {
      return i;
    }
  }
  return -1;
};

/**
 *
 * @param {*} timestamp
 * @returns
 */
export const formatTimestamp = timestamp => {
  const date = moment(timestamp);
  const formattedDate = date.format("dddd DD MMMM, h:mm A");
  return formattedDate;
};

/**
 * Method to convert number into Indian Rupees.
 * @param {*} number
 */
export const convertIntoINR = number => {
  if (!number) {
    return number ? number.toString() : ""; // Assuming the input number is stringified if falsy
  }

  number = parseFloat(number.toString());

  const ans = number.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return ans.substring(1);
};

export const convertTimestamp = (
  timestamp,
  isDotRemove = false,
  shouldIncludeTime = true,
) => {
  if (!timestamp) {
    return "";
  }

  const dateObj = new Date(timestamp);

  const day = dateObj.getDate();
  const month = dateObj.toLocaleString("default", {month: "short"});
  const year = dateObj.getFullYear().toString().slice(-2);
  let hours = dateObj.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours || 12; // Handle midnight
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");

  return `${day} ${month} ${year}${
    shouldIncludeTime
      ? ` ${isDotRemove ? " " : "•"} ${hours}:${minutes} ${ampm}`
      : ""
  }`;
};

export const convertTimestampIntoTime = timestamp => {
  if (!timestamp) {
    return "";
  }

  const dateObj = new Date(timestamp);

  let hours = dateObj.getHours();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours || 12; // Handle midnight
  const minutes = dateObj.getMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes} ${ampm}`;
};

export const getAddressStringFromObject = address => {
  if (address == null) {
    return "";
  }
  return `${address?.receiver_name}, ${address?.address_details_line1}, ${address?.address_details_line2}`;
};

export const findByKey = (array, key, value) => {
  for (const element of array) {
    if (element[key] == value) {
      return element;
    }
  }
  return null;
};

export const generatePublicToken = () => {
  const jwtSignedToken = sign(
    {
      timestamp: Date.now(),
      type: "public",
      role: "buyer",
      scope: "read",
      origin: "website",
    },
    Config.JWT_PUBLIC_KEY,
    {
      alg: "HS256",
    },
  );

  return jwtSignedToken;
};

export const getSecondsPassed = createdAt => {
  if (!createdAt) {
    return 100;
  }

  const createdAtTime = new Date(createdAt);
  const currentTime = new Date();

  // Calculate the difference in milliseconds
  const timeDifference = currentTime - createdAtTime;

  // Convert the difference to seconds
  return Math.floor(timeDifference / 1000);
};

/**
 *
 * @param {*} details store front details
 * @returns calculate delivery charge
 */
export const calculateDeliveryCharges = async (store_details, cartProduct) => {
  if (store_details == null) {
    return 0;
  }

  if (
    store_details.delivery_charge_config &&
    store_details.delivery_charge_config != null &&
    store_details.delivery_charge_config.use_custom_delivery_charges === true
  ) {
    if (cartProduct) {
      const cart_quantity = cartProduct.products.reduce(
        (total, product) => total + product.quantity,
        0,
      );

      const config = store_details.delivery_charge_config.delivery_charges;

      for (let i = 0; i < config.length; i++) {
        const {item_count, charge} = config[i];

        // If quantity matches item_count, return the charge
        if (cart_quantity === item_count) {
          return charge;
        }

        // If quantity is greater than item_count, store the last charge
        if (cart_quantity > item_count && i === config.length - 1) {
          return charge;
        }
      }
    }
  }
  const deliveryDiscount = store_details.delivery_discount
    ? parseInt(store_details.delivery_discount.toString())
    : 0;
  const freeDeliveryLowerLimit = store_details.free_delivery_amount
    ? parseInt(store_details.free_delivery_amount.toString())
    : 1000000000;
  const genericDeliveryFee = store_details.generic_delivery_fee
    ? parseInt(store_details.generic_delivery_fee.toString())
    : 0;
  let tempDeliveryCharge = 0;

  const totalProductPrice = cartProduct?.products.reduce(
    (sum, product) => sum + parseFloat(product.price),
    0,
  );

  if (parseInt(totalProductPrice?.toString())) {
    if (
      parseInt(totalProductPrice?.toString()) <
      parseInt(freeDeliveryLowerLimit?.toString())
    ) {
      tempDeliveryCharge = genericDeliveryFee - deliveryDiscount;
    }
  }
  return tempDeliveryCharge || 0;
};

export const calculateDeliveryChargesBuyNow = async (
  store_details,
  product,
) => {
  if (store_details == null) {
    return 0;
  }

  const deliveryDiscount = store_details.delivery_discount
    ? parseInt(store_details.delivery_discount.toString())
    : 0;
  const freeDeliveryLowerLimit = store_details.free_delivery_amount
    ? parseInt(store_details.free_delivery_amount.toString())
    : 1000000000;
  const genericDeliveryFee = store_details.generic_delivery_fee
    ? parseInt(store_details.generic_delivery_fee.toString())
    : 0;

  let tempDeliveryCharge = 0;
  if (product?.selling_price) {
    if (parseInt(product?.selling_price.toString()) < freeDeliveryLowerLimit) {
      tempDeliveryCharge = genericDeliveryFee - deliveryDiscount;
    }
  }
  return tempDeliveryCharge;
};

export const removeDuplicatesByKey = (array, key) => {
  const seen = new Set();

  return array.filter(item => {
    const keyValue = item[key];
    if (seen.has(keyValue)) {
      return false;
    }
    seen.add(keyValue);
    return true;
  });
};

export const groupProductsByStore = products => {
  // Object to store the grouped products by store id
  const groupedStores = {};

  products.forEach(product => {
    if (!product) {
      return; // Skip null or undefined products
    }
    const storeId = product?.seller_store_id;

    // If the store is not already in the groupedStores object, add it
    if (!groupedStores[storeId]) {
      groupedStores[storeId] = {
        store_id: storeId,
        store_name: product?.store_name,
        store_profile_url: product?.store_profile_url || "", // Use an empty string if no URL is present
        products: [],
      };
    }

    // Push the current product into the appropriate store's product list
    groupedStores[storeId].products.push({
      id: product?.id,
      cartId: product.cartId,
      product_id: product.product_id,
      product_pricing_details_id: product.product_pricing_details_id,
      quantity: product.quantity,
      price: product.price,
      product_price: product.product_price,
      product_selling_price: product.product_selling_price,
      product_name: product.product_name,
      product_description: product.product_description,
      image: product.image,
      gst: product.gst,
      weight: product.weight,
      seller_id: product.seller_id,
      cart_delivery_address_id: product.cart_delivery_address_id,
      variants: product.variants,
    });
  });

  // Convert the groupedStores object to an array
  return Object.values(groupedStores);
};

// Convert the price to INR (Indian Rupee)
export const convertIntoINRV2 = price => {
  const numberPrice = Number(price);

  // Format the price in INR currency style
  const formattedPrice = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(numberPrice);

  // Remove decimal part if it's ".00"
  return formattedPrice.endsWith(".00")
    ? formattedPrice.slice(0, -3)
    : formattedPrice;
};

// * Removes duplicate objects from an array based on a specific key.
//  *
//  * @param array - The array of objects to be filtered.
//  * @param key - The key to check for duplicates.
//  * @returns A new array with duplicates removed.
//  */

export const renderStars = rating => {
  const fullStars = Math.floor(rating); // Number of full stars
  const halfStar = rating % 1 >= 0.5; // Check for half-star
  const emptyStars = 5 - fullStars - (halfStar ? 1 : 0); // Remaining stars

  return (
    <>
      {"★".repeat(fullStars)} {/* Full stars */}
      {halfStar && "☆"} {/* Half star */}
      {"☆".repeat(emptyStars)} {/* Empty stars */}
    </>
  );
};

export const formatRatingType = ratingType => {
  return ratingType
    .replace(/_/g, " ") // Replace underscores with spaces
    .split(" ") // Split the string into words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
    .join(" "); // Join the words back together
};

export const capitalizeFirstLetter = string => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const timeAgo = dateString => {
  const now = new Date();
  const pastDate = new Date(dateString);
  const seconds = Math.floor((now - pastDate) / 1000);

  const intervals = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  for (let key in intervals) {
    const count = Math.floor(seconds / intervals[key]);
    if (count > 0) {
      return `${count} ${key}${count > 1 ? "s" : ""} ago`;
    }
  }
  return "just now";
};
