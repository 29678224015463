import React from "react";
import {convertIntoINR} from "../../../../../utils/helpers";
import "./OrderDetailsCard.style.css"; // Import the CSS file

interface OrderDetailsCardProps {
  totalItems?: number;
  priceIncludingGST?: number;
  discount?: number;
  deliveryCharge?: number;
  orderTotal?: number;
}

const OrderDetailsCard: React.FC<OrderDetailsCardProps> = ({
  totalItems,
  priceIncludingGST,
  discount,
  deliveryCharge,
  orderTotal,
}) => {
  return (
    <div className="order-details-page-card-container">
      <div className="order-details-page-card-body-container">
        <div className="order-details-page-card-row-container">
          <div className="order-details-page-card-row-left-container">
            <span className="order-details-page-card-left-text">Items</span>
          </div>
          <div className="order-details-page-card-row-right-container">
            <span className="order-details-page-card-right-text">
              {totalItems}
            </span>
          </div>
        </div>
        <div className="order-details-page-card-row-container">
          <div className="order-details-page-card-row-left-container">
            <span className="order-details-page-card-left-text">Price</span>
          </div>
          <div className="order-details-page-card-row-right-container">
            <span className="order-details-page-card-right-text">
              ₹{convertIntoINR(priceIncludingGST)}
            </span>
          </div>
        </div>
        {(discount ?? 0) > 0 && (
          <div className="order-details-page-card-row-container">
            <div className="order-details-page-card-row-left-container">
              <span className="order-details-page-card-left-text content-success">
                Discount
              </span>
            </div>
            <div className="order-details-page-card-row-right-container">
              <span className="order-details-page-card-right-text content-success">
                - ₹{convertIntoINR(discount)}
              </span>
            </div>
          </div>
        )}
        <div className="order-details-page-card-row-container">
          <div className="order-details-page-card-row-left-container">
            <span className="order-details-page-card-left-text">
              Delivery charge
            </span>
          </div>
          <div className="order-details-page-card-row-right-container">
            <span
              className={`order-details-page-card-right-text ${
                deliveryCharge === 0 ? "content-success" : ""
              }`}>
              {deliveryCharge === 0
                ? "Free"
                : `₹${convertIntoINR(deliveryCharge)}`}
            </span>
          </div>
        </div>
        <div className="order-details-page-card-row-container order-total-container">
          <div className="order-details-page-card-row-left-container">
            <span className="order-total-heading">Order Total</span>
          </div>
          <div className="order-details-page-card-row-right-container">
            <span className="order-total-text">
              ₹{convertIntoINR(orderTotal)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetailsCard;
