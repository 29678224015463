import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
}

const OrderPackageSvg: React.FC<SvgProps> = ({height, width}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.97 6.2017L12.72 1.68764C12.4996 1.56584 12.2518 1.50195 12 1.50195C11.7482 1.50195 11.5004 1.56584 11.28 1.68764L3.03 6.20358C2.7944 6.33249 2.59772 6.52229 2.46052 6.75316C2.32331 6.98404 2.25061 7.24751 2.25 7.51608L2.25 16.4823C2.25061 16.7509 2.32331 17.0144 2.46052 17.2452C2.59772 17.4761 2.7944 17.6659 3.03 17.7948L11.28 22.3108C11.5004 22.4326 11.7482 22.4965 12 22.4965C12.2518 22.4965 12.4996 22.4326 12.72 22.3108L20.97 17.7948C21.2056 17.6659 21.4023 17.4761 21.5395 17.2452C21.6767 17.0144 21.7494 16.7509 21.75 16.4823L21.75 7.51701C21.7499 7.24797 21.6774 6.98391 21.5402 6.7525C21.403 6.52108 21.206 6.33084 20.97 6.2017ZM12 3.00014L19.5319 7.12514L16.7409 8.65326L9.20813 4.52826L12 3.00014ZM12 11.2501L4.46812 7.12514L7.64625 5.38514L15.1781 9.51014L12 11.2501ZM3.75 8.43764L11.25 12.542V20.5848L3.75 16.4833L3.75 8.43764ZM20.25 16.4795L12.75 20.5848V12.5458L15.75 10.9042V14.2501C15.75 14.4491 15.829 14.6398 15.9697 14.7805C16.1103 14.9211 16.3011 15.0001 16.5 15.0001C16.6989 15.0001 16.8897 14.9211 17.0303 14.7805C17.171 14.6398 17.25 14.4491 17.25 14.2501V10.083L20.25 8.43764L20.25 16.4786V16.4795Z"
        fill="#141414"
      />
    </svg>
  );
};

export default OrderPackageSvg;
