/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useState} from "react";
import {
  BuyerDetails,
  SelfShipOrderDetails,
} from "../../../../../features/Order/OrderInterface";
import {
  capitalizeFirstLetter,
  convertTimestamp,
  findByKey,
} from "../../../../../utils/helpers";
import "./OrderStatusCard.style.css";
import IndicatorSvg from "../../../../../components/SvgImages/IndicatorSvg/IndicatorSvg";
import InProgressIndicatorSvg from "../../../../../components/SvgImages/InProgressIndicatorSvg/InProgressIndicatorSvg";
import {AppDispatch} from "../../../../../interceptors/store";
import {useDispatch} from "react-redux";
import {getOrderDeliveryStatusThunk} from "../../../../../features/Order/OrderSlice";
import DropdownSvg from "../../../../../components/SvgImages/DropdownSvg/DropdownSvg";
import {DropdownUpSvg} from "../../../../../components/SvgImages/DropdownUpSvg/DropdownUpSvg";
import OrderDetailsSelfShip from "../OrderDetailsSelfShip/OrderDetailsSelfShip";
import OrderDetailsCard from "../OrderDetailsCard/OrderDetailsCard";
import {apiConfig} from "../../../../../interceptors/api";

interface OrderStatusCardProps {
  orderItemCount: number | undefined;
  orderNum?: string;
  buyerDetails?: BuyerDetails;
  currentStatus?: string;
  selfShipDetails: boolean;
  selfShip?: SelfShipOrderDetails;
  statusType: "OrderDetails" | "OrderStatus";
  orderID?: string;
  totalItems?: number;
  priceIncludingGST?: number;
  discount?: number;
  deliveryCharge?: number;
  orderTotal?: number;
  status?: string;
  invoiceUrl?: string;
}

const statusConfig = {
  picked_up: {
    name: "Picked Up",
    status: "picked_up",
  },
  in_transit: {
    name: "In Transit",
    status: "in_transit",
  },
  shipped: {
    name: "Shipped",
    status: "shipped",
  },
  out_for_delivery: {
    name: "Out for Delivery",
    status: "out_for_delivery",
  },
  delivered: {
    name: "Delivered",
    status: "delivered",
  },
};

interface OrderStatusIndicatorCardProps {
  item: {
    status: string;
    name: string;
  };
  index: number;
}

const OrderStatusCard: React.FC<OrderStatusCardProps> = ({
  orderItemCount = 0,
  orderNum,
  buyerDetails,
  status,
  selfShipDetails,
  selfShip,
  statusType,
  orderID,
  priceIncludingGST,
  discount,
  deliveryCharge,
  orderTotal,
  invoiceUrl,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isOrdersDropdownOpen, setIsOrdersDropdownOpen] = useState(false);
  const [isStatusDropdownOpen, setIsStatusDropdownOpen] = useState(false);
  const [orderStatusData, setOrderStatusData] = useState<any[]>([]);

  const dispatch = useDispatch<AppDispatch>();

  // console.log("selfShipDetails", selfShipDetails);
  // console.log("selfShip", selfShip);
  useEffect(() => {
    if (orderID && !selfShipDetails) {
      console.log("callingDeliveryStatus");
      getOrderDeliveryStatus(String(orderID));
    }
  }, [orderID, selfShipDetails]);

  const getOrderDeliveryStatus = async (orderId: string) => {
    // // setLoading(true)
    try {
      const action = await dispatch(getOrderDeliveryStatusThunk({orderId}));
      if (getOrderDeliveryStatusThunk.fulfilled.match(action)) {
        // success
        // setLoading(false)
        const responseOrder = action.payload.data.delivery_status || [];
        const mappedData =
          responseOrder?.map((status, index) => ({
            shipment_id: status.shipment_id || `${index}`,
            status: status.status || "No Status Available",
            status_date: status.status_date || "",
          })) || [];

        setOrderStatusData(mappedData);
      } else {
        // setLoading(false)
      }
    } catch (error) {
      // setLoading(false)
      console.log("error", error);
    }
  };

  const handletoggleDropdown = () => {
    if (statusType === "OrderDetails") {
      setIsOrdersDropdownOpen(!isOrdersDropdownOpen);
    } else {
      setIsStatusDropdownOpen(!isStatusDropdownOpen);
    }
    setIsDropdownOpen(!isDropdownOpen);
  };

  const OrderStatusIndicatorCard: React.FC<OrderStatusIndicatorCardProps> = ({
    item,
    index,
  }) => {
    const orderStatusItem = findByKey(orderStatusData, "status", item.status);
    const isPending: boolean = !orderStatusItem || !orderStatusItem.status_date;

    return (
      <div
        className="order-status-card-dropdown-content-container"
        key={`status.shipment_id-${index}`}>
        {/* Left */}
        <div className="order-status-card-dropdown-content-left-container">
          <div className="order-status-card-dropdown-content-left-svg-container">
            {isPending ? (
              <InProgressIndicatorSvg
                width={32}
                height={32}
                inProgress={isPending}
              />
            ) : (
              <IndicatorSvg width={32} height={32} />
            )}
            {index < Object.keys(statusConfig).length - 1 &&
              (isPending ? (
                <div className="order-status-card-vertical-line" />
              ) : (
                <div className="order-status-card-vertical-line-pending" />
              ))}
          </div>
        </div>

        {/* Right */}
        <div className="order-status-card-dropdown-content-right-container">
          <div className="order-status-card-dropdown-content-right-text-container">
            <span className="order-status-card-dropdown-content-right-row-one-text">
              {item.name}
            </span>
            {orderStatusItem?.status_date && (
              <span className="order-status-card-dropdown-content-right-row-two-text">
                {convertTimestamp(orderStatusItem.status_date, true)}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  const getBase64Url = async () => {
    try {
      const response = await fetch(
        `${apiConfig.inventoryUrl}/api/inventory/url-encoded`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({invoiceUrl: invoiceUrl, outputType: "base64"}),
        },
      );

      if (!response.ok) {
        throw new Error("Failed to fetch invoice");
      }

      const result = await response.json();
      return result.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadInvoice = async () => {
    const handleDownload = async (downloadLink: string) => {
      // Create a programmatic anchor element

      try {
        // Convert base64 to blob
        const byteCharacters = atob(downloadLink);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {type: "application/pdf"});

        // Create download link
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${orderNum || "invoice"}.pdf`;

        // Trigger download
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error("Error downloading invoice:", error);
        alert("Failed to download invoice. Please try again.");
      }
    };

    await getBase64Url()
      .then(downloadLink => {
        handleDownload(downloadLink);
      })
      .catch(error => {
        console.error("Error downloading invoice:", error);
        alert("Failed to download invoice. Please try again.");
      });
  };

  return (
    <div className="order-status-card-container">
      <div className="order-status-card-header-container">
        <div className="order-status-card-header">
          {orderItemCount} {orderItemCount === 1 ? "Item" : "Items"}
          {status && (
            <>
              {" - "}
              <span className={`${status}`}>
                {capitalizeFirstLetter(
                  status === "pending" ||
                    status === "accepted" ||
                    status === "pickup"
                    ? "Order Confirmed"
                    : status,
                )}
              </span>
            </>
          )}
        </div>
        <div className="order-status-card-order-id">#{orderNum}</div>
      </div>
      <div className="order-status-card-header-container">
        <div className="order-status-card-buyer-name">
          {buyerDetails?.buyer_name}
        </div>
        <div className="order-status-card-buyer-address">
          {buyerDetails?.buyer_address}
        </div>
      </div>
      {statusType === "OrderDetails" ? (
        <div
          className="order-status-dropdown-card-header-left"
          onClick={handletoggleDropdown}>
          <div>Order Details</div>
          <div className="dropdown-svg-container">
            {isOrdersDropdownOpen ? (
              <DropdownUpSvg
                width={16}
                height={16}
                color={"var(--content-action)"}
              />
            ) : (
              <DropdownSvg
                width={16}
                height={16}
                color={"var(--content-action)"}
              />
            )}{" "}
          </div>
        </div>
      ) : (
        <>
          <div
            className="order-status-dropdown-card-header-left"
            onClick={handletoggleDropdown}>
            <div>Details</div>
            <div className="dropdown-svg-container">
              {isDropdownOpen ? (
                <DropdownUpSvg
                  width={16}
                  height={16}
                  color={"var(--content-action)"}
                />
              ) : (
                <DropdownSvg
                  width={16}
                  height={16}
                  color={"var(--content-action)"}
                />
              )}{" "}
            </div>
          </div>
        </>
        // selfShipDetails ? (
        //   <div>Self Ship Details</div>
        // ) : (
        //   <div>No Self Ship Details</div>
        // )
      )}
      {/* <div
        className="order-status-dropdown-card-header-left"
        onClick={handletoggleDropdown}>
        <div>
          Order Details
        </div>
        <div className="dropdown-svg-container">
          {isDropdownOpen ? (
            <DropdownUpSvg width={16} height={16} color={"var(--content-action)"} />
          ) : (
            <DropdownSvg width={16} height={16} color={"var(--content-action)"} />
          )}{" "}
        </div>
      </div> */}
      {isStatusDropdownOpen &&
        statusType === "OrderStatus" &&
        !selfShipDetails && (
          <div className="order-status-card-dropdown-content">
            {Object.entries(statusConfig).map(([, item], index) => (
              <OrderStatusIndicatorCard item={item} index={index} key={index} />
            ))}
          </div>
        )}
      {isStatusDropdownOpen &&
        statusType === "OrderStatus" &&
        selfShipDetails &&
        selfShip && (
          <div className="order-status-card-dropdown-content">
            <OrderDetailsSelfShip selfShip={selfShip} />
          </div>
        )}
      {isOrdersDropdownOpen && statusType === "OrderDetails" && (
        <OrderDetailsCard
          totalItems={orderItemCount}
          priceIncludingGST={priceIncludingGST}
          discount={discount}
          deliveryCharge={deliveryCharge}
          orderTotal={orderTotal}
        />
      )}
      {statusType === "OrderDetails" && selfShipDetails && invoiceUrl && (
        <button
          className="download-invoice-button"
          onClick={handleDownloadInvoice}>
          Download Invoice
        </button>
      )}
    </div>
  );
};

export default OrderStatusCard;
