import React from "react";
import {User} from "../../features/User/UserInterface";

interface ProfileHeaderProps {
  user: User | null;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({user}) => {
  if (!user) return null; // Early return if no user is provided

  const {user_name, country_code, mobile, following_count = 0} = user;
  const formattedMobile = country_code
    ? `${country_code} ${mobile}`
    : `+91 ${mobile}`;

  // Format the following_count to always show two digits
  const formattedFollowingCount =
    following_count < 10 ? `0${following_count}` : following_count;

  return (
    <div className="profile-main">
      <h3>Profile</h3>
      <div className="profile-header">
        <div className="profile-info">
          <img
            src="https://img.freepik.com/free-vector/colorful-bird-illustration-gradient_343694-1741.jpg"
            alt="User Avatar"
            className="profile-avatar"
          />
          <div className="profile-details">
            <h3>{user_name}</h3>
            <p>{formattedMobile}</p>
          </div>
        </div>
        <div className="profile-following">
          <span>{formattedFollowingCount}</span>
          <p>Following</p>
        </div>
        {/* <button className="edit-button">✏ Edit</button> */}
      </div>
    </div>
  );
};

export default ProfileHeader;
