import React from "react";

export interface BackArrowProps {
  height: number | string;
  width: number | string;
  color?: string;
}

export const Arrowleft = function Arrowleft(props: BackArrowProps) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8125 9.99987C17.8125 10.2485 17.7137 10.487 17.5379 10.6628C17.3621 10.8386 17.1236 10.9374 16.875 10.9374H5.39062L9.41562 14.9616C9.59174 15.1377 9.69069 15.3766 9.69069 15.6257C9.69069 15.8747 9.59174 16.1136 9.41562 16.2897C9.2395 16.4658 9.00063 16.5648 8.75156 16.5648C8.50249 16.5648 8.26362 16.4658 8.0875 16.2897L2.4625 10.6647C2.3751 10.5776 2.30575 10.4741 2.25844 10.3602C2.21112 10.2462 2.18676 10.124 2.18676 10.0006C2.18676 9.87726 2.21112 9.75509 2.25844 9.64113C2.30575 9.52718 2.3751 9.42368 2.4625 9.33659L8.0875 3.71159C8.17471 3.62438 8.27823 3.55521 8.39217 3.50801C8.50611 3.46081 8.62823 3.43652 8.75156 3.43652C8.87489 3.43652 8.99701 3.46081 9.11095 3.50801C9.22489 3.55521 9.32842 3.62438 9.41562 3.71159C9.50283 3.79879 9.57201 3.90232 9.6192 4.01626C9.6664 4.1302 9.69069 4.25232 9.69069 4.37565C9.69069 4.49898 9.6664 4.6211 9.6192 4.73504C9.57201 4.84898 9.50283 4.95251 9.41562 5.03971L5.39062 9.06237H16.875C17.1236 9.06237 17.3621 9.16114 17.5379 9.33696C17.7137 9.51277 17.8125 9.75123 17.8125 9.99987Z"
        fill={props.color ? props.color : "#F6F6F6"}
      />
    </svg>
  );
};
