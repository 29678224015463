import React, {
  useState,
  useEffect,
  CSSProperties,
  ReactNode,
  TouchEvent,
} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import "./Carousel.css";

type CarouselProps = {
  children: ReactNode;
  jumpToIndex?: number;
  carouselStyle?: CSSProperties;
  showIndicator?: boolean;
  showNavigation?: boolean;
};

const Carousel: React.FC<CarouselProps> = ({
  children,
  jumpToIndex,
  carouselStyle = {},
  showIndicator = false,
  showNavigation = true,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [touchStart, setTouchStart] = useState<number | null>(null);
  const [touchEnd, setTouchEnd] = useState<number | null>(null);
  const childrenCount = React.Children.count(children);

  // Required minimum distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 50;

  useEffect(() => {
    if (
      jumpToIndex !== undefined &&
      jumpToIndex >= 0 &&
      jumpToIndex < childrenCount
    ) {
      setCurrentIndex(jumpToIndex);
    }
  }, [jumpToIndex, childrenCount]);

  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % childrenCount);
  };

  const handlePrev = () => {
    setCurrentIndex(
      prevIndex => (prevIndex - 1 + childrenCount) % childrenCount,
    );
  };

  const onTouchStart = (e: TouchEvent) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: TouchEvent) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe) {
      handleNext();
    } else if (isRightSwipe) {
      handlePrev();
    }
  };

  return (
    <div className="carousel" style={carouselStyle}>
      {showNavigation && (
        <>
          <button onClick={handlePrev} className="carousel-button prev">
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </>
      )}
      <div
        className="carousel-content"
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}>
        {React.Children.map(children, (child, index) => (
          <div
            className={`carousel-item ${index === currentIndex ? "active" : ""}`}
            style={{display: index === currentIndex ? "" : "none"}}>
            {child}
          </div>
        ))}
      </div>
      {showNavigation && (
        <>
          <button onClick={handleNext} className="carousel-button next">
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </>
      )}
      {showIndicator && childrenCount > 1 && (
        <div className="carousel-indicator">
          <div className="dots-container">
            {React.Children.map(children, (_, index) => (
              <div
                className={`dot ${index === currentIndex ? "active" : ""}`}
                key={index}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
