import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const SearchSvgV2: React.FC<SvgProps> = ({height, width, color}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={color ? color : "#D9D9D9"}
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_68_3388)">
        <path
          d="M21.5306 20.4693L16.8366 15.7762C18.1971 14.1428 18.8755 12.0478 18.7307 9.92691C18.5859 7.80604 17.629 5.82265 16.0591 4.38932C14.4892 2.95599 12.4272 2.18308 10.3019 2.23138C8.17666 2.27968 6.15184 3.14547 4.64867 4.64864C3.1455 6.15181 2.27971 8.17663 2.23141 10.3019C2.18311 12.4271 2.95602 14.4892 4.38935 16.0591C5.82268 17.629 7.80607 18.5859 9.92694 18.7307C12.0478 18.8755 14.1428 18.1971 15.7762 16.8365L20.4694 21.5306C20.5391 21.6003 20.6218 21.6556 20.7128 21.6933C20.8039 21.731 20.9015 21.7504 21 21.7504C21.0985 21.7504 21.1961 21.731 21.2872 21.6933C21.3782 21.6556 21.4609 21.6003 21.5306 21.5306C21.6003 21.4609 21.6556 21.3782 21.6933 21.2871C21.731 21.1961 21.7504 21.0985 21.7504 21C21.7504 20.9014 21.731 20.8038 21.6933 20.7128C21.6556 20.6218 21.6003 20.539 21.5306 20.4693ZM3.75 10.5C3.75 9.16495 4.14588 7.8599 4.88758 6.74987C5.62928 5.63984 6.68349 4.77467 7.91689 4.26378C9.15029 3.75289 10.5075 3.61922 11.8169 3.87967C13.1262 4.14012 14.329 4.78299 15.273 5.727C16.217 6.671 16.8599 7.87374 17.1203 9.18311C17.3808 10.4925 17.2471 11.8497 16.7362 13.0831C16.2253 14.3165 15.3601 15.3707 14.2501 16.1124C13.1401 16.8541 11.835 17.25 10.5 17.25C8.7104 17.248 6.99466 16.5362 5.72922 15.2707C4.46378 14.0053 3.75199 12.2896 3.75 10.5Z"
          fill="#6B6B6B"
        />
      </g>
      <defs>
        <clipPath id="clip0_68_3388">
          <rect
            width={width}
            height={height}
            fill={color ? color : "#D9D9D9"}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchSvgV2;
