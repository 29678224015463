import React from "react";

interface BottomButtonProps {
  onButtonClick: () => void;
  label: string;
  color: string;
}

export const BottomButton: React.FC<BottomButtonProps> = ({
  onButtonClick,
  label,
  color,
}) => {
  return (
    <div>
      <button
        onClick={onButtonClick}
        style={{
          alignItems: "center",
          background: color ? color : "#EEFBFD",
          borderRadius: 8,
          paddingTop: 0,
          width: "100%",
          padding: "10px",
          fontFamily: "var(--font-family-inter)",
          border: "aliceblue",
          cursor: "pointer",
          marginTop: "5px",
          marginBottom: "8px",
        }}>
        <span
          style={{
            color: "#1E799C",
            fontSize: "12px",
            fontWeight: "600",
          }}>
          {label}
        </span>
      </button>
    </div>
  );
};
