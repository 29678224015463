/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useCallback, useState} from "react";
import "./AddressBottomSheet.css";
import ProgressBar from "../ProgressBar/ProgressBar";
import AddressForm from "./AddressForm";
import OrderReview from "../OrderReview/OrderReview";
import {useSelector} from "react-redux";
import {RootState} from "../../interceptors/store";

interface AddressBottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
  product: any;
  storeId: string;
  cartId: number;
}

export interface Address {
  receiver_name: string;
  address_line_1: string;
  address_line_2: string;
  pincode: string;
  state: string;
  city: string;
  save_as: string;
  id?: number;
}

const AddressBottomSheet: React.FC<AddressBottomSheetProps> = ({
  isOpen,
  onClose,
  product,
  storeId,
  cartId,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [isOpenAddAddress, setIsAddAddressOpen] = useState<boolean>(false);
  const [existingUser, setExistingUser] = useState<boolean>(false);
  const [addressType, setAddressType] = useState("add");
  const [address, setAddress] = useState<Address | undefined>(undefined);
  const {user} = useSelector((state: RootState) => state.user);

  useEffect(() => {
    if (user && user?.address?.length > 0) {
      const today = new Date().setHours(0, 0, 0, 0);

      const isRecent = user.address.some(address => {
        const createdDate = new Date(address.created_at).setHours(0, 0, 0, 0);
        return createdDate >= today;
      });

      if (isRecent) {
        setExistingUser(false);
        setCurrentStep(1);
      } else {
        setExistingUser(true);
        setCurrentStep(1);
      }
    }
  }, [user]);

  useEffect(() => {
    if (isOpenAddAddress) {
      setCurrentStep(0);
    }
  }, [isOpenAddAddress]);

  const steps = [
    {label: "Address", completed: currentStep > 0, active: currentStep === 0},
    {
      label: "Order Summary",
      completed: currentStep > 1,
      active: currentStep === 1,
    },
    {label: "Payment", completed: currentStep > 2, active: currentStep === 2},
  ];

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(prevStep => prevStep + 1);
    }
  };

  const handleOverlayClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (
        (e.target as HTMLElement).classList.contains(
          "address-bottom-sheet-overlay",
        )
      ) {
        onClose();
      }
    },
    [onClose],
  );

  // const handleKeyDown = useCallback(
  //   (e: KeyboardEvent) => {
  //     if (e.key === "Escape") {
  //       onClose();
  //     }
  //   },
  //   [onClose],
  // );

  return (
    <div
      className={`address-bottom-sheet-overlay ${isOpen ? "open" : ""}`}
      onClick={handleOverlayClick}>
      <div
        className={`address-bottom-sheet ${isOpen ? "open" : ""}`}
        role="dialog"
        aria-modal="true">
        <div className="address-bottom-sheet-header">
          <button
            className="address-close-button"
            onClick={onClose}
            aria-label="Close">
            &times;
          </button>
          <span className="address-bottom-sheet-header-text">
            {currentStep === 0
              ? addressType === "add"
                ? "Add Address"
                : "Edit Address"
              : "Review Order"}
          </span>
        </div>

        {addressType !== "edit" && existingUser == false && (
          <ProgressBar steps={steps} currentStep={currentStep} />
        )}

        <div className="address-bottom-sheet-content">
          {currentStep === 0 ? (
            <AddressForm
              type={addressType}
              onSubmit={data => {
                setIsAddAddressOpen(false);
                handleNext();
                setAddress(data);
              }}
              initialDeliveryAddress={address}
              existingUser={existingUser}
            />
          ) : (
            <OrderReview
              product={product}
              setIsAddAddressOpen={setIsAddAddressOpen}
              setAddressType={setAddressType}
              address={address}
              setAddress={setAddress}
              storeId={storeId}
              onSubmit={handleNext}
              cartId={cartId}
            />
          )}
        </div>

        <div className="address-bottom-sheet-footer" />
      </div>
    </div>
  );
};

export default AddressBottomSheet;
