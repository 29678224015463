import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const ShippingSvg: React.FC<SvgProps> = ({height, width, color}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_1258)">
        <path
          fill={color ? color : "#141414"}
          d="m23.946 10.969-1.313-3.281a1.49 1.49 0 0 0-1.391-.938H18V6a.75.75 0 0 0-.75-.75H3a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h1.594a3 3 0 0 0 5.812 0h4.688a3 3 0 0 0 5.812 0H22.5a1.5 1.5 0 0 0 1.5-1.5v-6a.7.7 0 0 0-.054-.281M18 8.25h3.242l.9 2.25H18zM3 6.75h13.5v6H3zM7.5 19.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m7.594-2.25h-4.688a3 3 0 0 0-5.812 0H3v-3h13.5v1.154a3 3 0 0 0-1.406 1.846M18 19.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m4.5-2.25h-1.594A3.01 3.01 0 0 0 18 15v-3h4.5z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_1258">
          <path fill="#fff" d="M0 0h24v24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShippingSvg;
