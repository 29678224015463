import React, {useEffect} from "react";
import "./BuyNowModal.style.css";
import Slider from "../../Slider/Slider";
import {colourCode} from "../../../constants/colourCode";

interface variantValuesType {
  [key: string]: variantCategoryDetailType[];
}

interface variantCategoryDetailType {
  name: string;
  value: string;
  variant_value_id: number;
}

export const BuyNowModal = ({
  variantValues,
  selectedVariantValue,
  setSelectedVariantValue,
  onChangeSelectedVariantValue,
  onSuccess,
  isVariantAvailable,
  buyNowAllowed,
}: {
  variantValues: variantValuesType;
  selectedVariantValue: {[key: string]: string | number};
  setSelectedVariantValue: (value: {[key: string]: string | number}) => void;
  onChangeSelectedVariantValue: () => void;
  onSuccess: () => void;
  isVariantAvailable: (
    VarientValue: number | string,
    VariantName: string,
  ) => boolean;
  buyNowAllowed: boolean;
}) => {
  useEffect(() => {
    onChangeSelectedVariantValue();
  }, [selectedVariantValue]);

  const selectedVariantIndex = (
    variantCategory: string,
    varientValue: string | number,
  ) => {
    const variantCategoryDetail = variantValues[variantCategory];
    const index = variantCategoryDetail.findIndex(
      variant => variant.name === varientValue,
    );
    return index;
  };

  return (
    <div className="buy-now-modal-container">
      {variantValues &&
        Object.keys(variantValues).length > 0 &&
        !variantValues?.["No Variant"] && (
          <div className="buy-now-modal-size-box">
            {Object.entries(variantValues).map(
              ([variantCategory, variantCategoryDetail], index) => {
                return (
                  <div key={index} style={{width: "100%"}}>
                    <div className="product-details-title-container">
                      <span className="buy-now-modal-variant-label">
                        {variantCategory}
                      </span>
                    </div>
                    <div className="product-details-size-selector">
                      <div className="product-details-size-selector-list">
                        {variantCategory === "Colour" ? (
                          <Slider
                            options={variantCategoryDetail?.map(
                              (
                                variantCategoryValue: variantCategoryDetailType,
                              ) => {
                                return {
                                  id: variantCategoryValue.name,
                                  value:
                                    colourCode?.[variantCategoryValue.name] ??
                                    variantCategoryValue.name,
                                  isAvailable: isVariantAvailable(
                                    variantCategoryValue.name,
                                    variantCategory,
                                  ),
                                };
                              },
                            )}
                            renderOption={(option, isSelected) => (
                              <div
                                className="color-option"
                                style={{
                                  opacity: option.isAvailable ? 1 : 0.5,
                                  backgroundColor: option.value,
                                  border: isSelected
                                    ? "2px solid var(--surface-action2)"
                                    : "1px solid var(--border-primary)",
                                }}
                              />
                            )}
                            onSelect={selectedOption => {
                              setSelectedVariantValue({
                                ...selectedVariantValue,
                                [variantCategory]: selectedOption.id,
                              });
                            }}
                            selectedVarientIndex={selectedVariantIndex(
                              variantCategory,
                              selectedVariantValue[variantCategory],
                            )}
                          />
                        ) : (
                          <React.Fragment>
                            <Slider
                              options={variantCategoryDetail?.map(
                                (
                                  variantCategoryValue: variantCategoryDetailType,
                                ) => {
                                  return {
                                    id: variantCategoryValue.name,
                                    value: variantCategoryValue.name,
                                    isAvailable: isVariantAvailable(
                                      variantCategoryValue.name,
                                      variantCategory,
                                    ),
                                  };
                                },
                              )}
                              renderOption={(option, isSelected) => (
                                <div
                                  className="size-option"
                                  style={{
                                    backgroundColor: option.isAvailable
                                      ? "var(--surface-primary)"
                                      : "var(--surface-secondary)",
                                    color: option.isAvailable
                                      ? "var(--content-primary)"
                                      : "var(--content-inactive)",
                                    borderColor: isSelected
                                      ? "var(--border-action)"
                                      : "var(--border-primary)",
                                    textDecoration: option.isAvailable
                                      ? "none"
                                      : "line-through",
                                    // textDecorationColor: option.isAvailable ? 'var(--content-primary)' : 'var(--content-primary)',
                                  }}>
                                  {option.value}
                                </div>
                              )}
                              onSelect={selectedOption => {
                                setSelectedVariantValue({
                                  ...selectedVariantValue,
                                  [variantCategory]: selectedOption.id,
                                });
                              }}
                              selectedVarientIndex={selectedVariantIndex(
                                variantCategory,
                                selectedVariantValue[variantCategory],
                              )}
                            />
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                );
              },
            )}
          </div>
        )}
      <div className="buy-now-modal-button-container">
        <button
          disabled={!buyNowAllowed}
          className={`buy-now-modal-button ${!buyNowAllowed ? "product-detail-button-cta-disabled" : ""}`}
          onClick={() => {
            onSuccess();
          }}>
          Next
        </button>
      </div>
    </div>
  );
};
