import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../interceptors/store";
import {
  GetWishlistProductsApiPayload,
  WishlistDetails,
} from "../../../features/Product/ProductInterface";
import {getWishlistProductsThunk} from "../../../features/Product/ProductSlice";
import RatingStarSvg from "../../../components/SvgImages/RatingStarSvg/RatingStarSvg";
import "./CollectionDetailsPage.style.css";
export const CollectionDetailsPage = () => {
  const [page] = useState<number>(1);
  const [wishlistDetails, setWishlistDetails] =
    useState<WishlistDetails | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const {wishlistId} = useParams<{wishlistId: string}>();
  const navigate = useNavigate();
  // const [currentPage, setCurrentPage] = useState<number>(1);
  // const [totalPages, setTotalPages] = useState<number>(1);

  const getWishlistProducts = async (page: number) => {
    if (!wishlistId) {
      return;
    }
    const payload: GetWishlistProductsApiPayload = {
      wishlistId,
      page,
      limit: 6,
    };
    try {
      const action = await dispatch(getWishlistProductsThunk(payload));
      if (getWishlistProductsThunk.fulfilled.match(action)) {
        // success
        setWishlistDetails(action.payload.data.wishlist);
        // setTotalPages(Math.ceil(action.payload.data.wishlist.item_count ))
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const calculateDiscount = ({
    min_product_price,
    min_selling_price,
  }: {
    min_product_price: string;
    min_selling_price: string;
  }) => {
    return (
      ((parseInt(min_product_price) - parseInt(min_selling_price)) /
        parseInt(min_product_price)) *
      100
    );
  };

  useEffect(() => {
    getWishlistProducts(page);
  }, [page]);

  return (
    <div className="collection-details-page-container">
      <div className="collection-details-page-header">
        <div className="collection-details-page-header-title">
          {wishlistDetails?.name}
        </div>
        <div className="collection-details-page-header-count">
          {wishlistDetails?.wishlist_items.length} items
        </div>
      </div>
      <div className="collection-details-product-section-product-grid">
        {/* Sample products */}
        {wishlistDetails?.wishlist_items.map((wishlistItem, index) => (
          <div
            key={index}
            className="collection-details-product-section-product-card"
            onClick={() => {
              navigate(`/product/${wishlistItem.id}`);
            }}>
            <div className="collection-details-product-section-product-image-box">
              {calculateDiscount({
                min_product_price: wishlistItem.min_product_price,
                min_selling_price: wishlistItem.min_selling_price,
              }) > 0 && (
                <div className="collection-details-product-section-tag-container">
                  <div
                    className="collection-details-product-section-tag-cover"
                    style={{
                      backgroundColor: "var(--surface-status)",
                    }}>
                    <span
                      className="collection-details-product-section-tag-cover-text"
                      style={{
                        color: "#fef2f2",
                      }}>
                      {calculateDiscount({
                        min_product_price: wishlistItem.min_product_price,
                        min_selling_price: wishlistItem.min_selling_price,
                      }).toFixed(0) + "% off"}
                    </span>
                  </div>
                </div>
              )}
              {wishlistItem.avg_rating && wishlistItem.review_count && (
                <div className="collection-details-product-section-rating">
                  <div className="collection-details-product-section-rating-star">
                    <RatingStarSvg height={16} width={16} />
                    <span className="collection-details-product-section-rating-text">
                      {wishlistItem.avg_rating.toFixed(2)}
                    </span>
                  </div>
                  <div className="collection-details-product-section-rating-count">
                    <span className="collection-details-product-section-rating-text">
                      {`(${wishlistItem.review_count})`}
                    </span>
                  </div>
                </div>
              )}
              <img
                // src={
                //   product.image_url &&
                //     product.image_url.length !== 0
                //     ? product.image_url
                //     : "https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"
                // }
                src="https://storage.googleapis.com/zoop-public-bucket/ZOOP-RECOMMENDS/productstock.png"
                alt="Product"
                className="collection-details-product-section-product-image"
              />
            </div>
            <div className="collection-details-product-section-info">
              <div className="collection-details-product-section-name">
                {wishlistItem.product_name}
              </div>
              {/* <div className="storefront-product-section-description">
                  {product.product_description}
                </div> */}
              <div className="collection-details-product-section-price">
                ₹{wishlistItem.min_selling_price}{" "}
                {parseInt(wishlistItem.min_product_price) -
                  parseInt(wishlistItem.min_selling_price) >
                  0 && (
                  <span className="collection-details-product-section-original-price">
                    ₹{wishlistItem.min_product_price}
                  </span>
                )}
              </div>
            </div>
            {/* <div className="storefront-product-section-rating">
                <span className='storefront-product-section-product-rating'>★ 4.2 (173)</span>
              </div> */}
          </div>
        ))}
      </div>

      {/* {(wishlistDetails?.wishlist_items.length ?? 0) > 0 && (
        <div className="storefront-pagination-container">
          <button
            className="storefront-pagination-arrow"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev: number) => prev - 1)}>
            &#8249;
          </button>
          <div className="storefront-pagination-numbers">
            Page
            <span
              className={`storefront-pagination-number ${currentPage === 1 ? "active" : ""}`}
              onClick={() => setCurrentPage(1)}>
              1
            </span>
            {currentPage > 3 && (
              <span className="storefront-pagination-dots">...</span>
            )}
            {currentPage > 2 && (
              <span
                className="storefront-pagination-number"
                onClick={() => setCurrentPage(currentPage - 1)}>
                {currentPage - 1}
              </span>
            )}
            {currentPage !== 1 && currentPage !== totalPages && (
              <span className="storefront-pagination-number active">
                {currentPage}
              </span>
            )}
            {currentPage < totalPages - 1 && (
              <span
                className="storefront-pagination-number"
                onClick={() => setCurrentPage(currentPage + 1)}>
                {currentPage + 1}
              </span>
            )}
            {currentPage < totalPages - 2 && (
              <span className="storefront-pagination-dots">...</span>
            )}
            {totalPages > 1 && (
              <span
                className={`storefront-pagination-number ${currentPage === totalPages ? "active" : ""
                  }`}
                onClick={() => setCurrentPage(totalPages)}>
                {totalPages}
              </span>
            )}
          </div>
          <button
            className="storefront-pagination-arrow"
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((prev: number) => prev + 1)}>
            &#8250;
          </button>
        </div>
      )} */}
    </div>
  );
};
