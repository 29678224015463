import React, {useState} from "react";
import "./AboutSection.style.css";
import StoreImgSvg from "../../../../../components/SvgImages/StoreImgSvg/StoreImgSvg";
import DeliverySvg from "../../../../../components/SvgImages/DeliverySvg/DeliverySvg";
// import StoreHomeSvg from "../../../../../components/SvgImages/StoreHomeSvg/StoreHomeSvg";
import PhoneIconSvg from "../../../../../components/SvgImages/PhoneIconSvg/PhoneIconSvg";
import EmailSvg from "../../../../../components/SvgImages/EmailSvg/EmailSvg";
import {RatingCount, Review} from "../../../../../features/User/UserInterface";
import ExpandMoreSvg from "../../../../../components/SvgImages/ExpandMoreSvg/ExpandMoreSvg";
import StarFilledSvg from "../../../../../components/SvgImages/StarFilledSvg/StarFilledSvg";
import ExpandLessSvg from "../../../../../components/SvgImages/ExpandLessSvg/ExpandLessSvg";
import StoreReviewCard from "../StoreReviewCard/StoreReviewCard";
import StoreIdImgSvg from "../../../../../components/SvgImages/StoreIdImgSvg/StoreImgSvg";
import ReturnArrowSvg from "../../../../../components/SvgImages/ReturnArrowSvg/ReturnArrowSvg";
import {useNavigate} from "react-router-dom";
interface StoreAboutProps {
  storeName?: string;
  freeDeliveryAmount?: number | undefined;
  email: string | undefined;
  deliveryDiscount: number | undefined;
  storeId: string | undefined;
  address1: string | undefined;
  address2: string | undefined;
  city: string | undefined;
  pincode: number | undefined;
  phoneNumber: string | undefined;
  setReviewOverlayOpen: (value: boolean) => void;
  profilePhoto: string | undefined;
  reviews: Review[];
  reviewCount: number;
  productCount: number;
  storeRating: number | undefined;
  ratings: RatingCount[];
}

const AboutSection: React.FC<StoreAboutProps> = ({
  // storeName,
  email,
  deliveryDiscount,
  storeId,
  // address1,
  // address2,
  // city,
  // pincode,
  phoneNumber,
  // setReviewOverlayOpen,
  // profilePhoto,
  reviewCount,
  // productCount,
  storeRating,
  // ratings,
  reviews,
}) => {
  const [isStoreInfoOpen, setIsStoreInfoOpen] = useState(false); // Store Info initially open
  const [isReturnPolicyOpen, setIsReturnPolicyOpen] = useState(false); // Return Policy initially closed
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (reviewRef.current) {
  //     const isOverflowing =
  //       reviewRef.current.scrollHeight >
  //       reviewRef.current.clientHeight;
  //     setIsOverflowing(isOverflowing);
  //   }
  // }, [reviews]);

  const toggleSection = (section: string) => {
    if (section === "storeInfo") {
      setIsStoreInfoOpen(!isStoreInfoOpen);
    } else if (section === "returnPolicy") {
      setIsReturnPolicyOpen(!isReturnPolicyOpen);
    }
  };

  return (
    <section className="storefront-about-section">
      {/* <div className="storefront-about-section-store-info-card">
        <div className="storefront-about-section-review-card">
          <div className="storefront-about-section-store-profile">
            <img
              src={profilePhoto}
              alt="Store Profile Picture"
              className="storefront-about-section-store-image"
            />
            <h2 className="storefront-about-section-store-name">{storeName}</h2>
          </div>
          <div className="storefront-about-section-store-stats">
            <div className="storefront-about-section-stat-item store-front-bottom-line">
              <span className="storefront-about-section-stat-value">
                {reviewCount || "N/A"}
              </span>
              <span className="storefront-about-section-stat-label">
                Reviews
              </span>
            </div>
            <div className="storefront-about-section-stat-item store-front-bottom-line">
              <span className="storefront-about-section-stat-value">
                {storeRating || "N/A"}
              </span>
              <span className="storefront-about-section-stat-label">
                Ratings
              </span>
            </div>
            <div className="storefront-about-section-stat-item">
              <span className="storefront-about-section-stat-value">
                {productCount}
              </span>
              <span className="storefront-about-section-stat-label">
                Listings
              </span>
            </div>
          </div>
        </div>
      </div> */}

      <div
        className="storefront-about-section-rating-card"
        // onClick={() => setReviewOverlayOpen(true)}
      >
        <div className="storefront-about-section-rating-card-heading">
          <div className="storefront-about-section-rating-card-heading-rating">
            <div className="storefront-about-section-rating-card-heading-rating-star">
              <StarFilledSvg height={"20"} width={"20"} />
            </div>
            <div className="storefront-about-section-rating-card-heading-rating-text">
              {storeRating || "N/A"}
            </div>
          </div>
          <div className="storefront-about-section-rating-card-heading-rating-separator">
            •
          </div>
          <div className="storefront-about-section-rating-summary">
            {reviewCount || "N/A"} Reviews
          </div>
        </div>
      </div>
      {reviewCount > 0 && (
        <div className="storefront-about-section-reviews-card-container">
          {reviews.map((review, key) => (
            <StoreReviewCard review={review} key={key} />
          ))}
        </div>
      )}
      {reviewCount > 0 && (
        <div className="storefront-about-section-show-all-reviews-button-container">
          <button
            className={`storefront-about-section-show-all-reviews-button`}
            onClick={() => {
              navigate(`/storefront/${storeId}/reviews`, {
                state: {reviews, reviewCount, storeRating},
              });
            }}>
            {/* {<Loader size={12}/> : "Unfollow"} */}
            Show all reviews
          </button>
        </div>
      )}

      <div className="storefront-about-section-store-info-card-container">
        <div
          className="storefront-about-section-store-info-card"
          onClick={() => toggleSection("storeInfo")}>
          <div className="storefront-about-section-store-info-card-body">
            <div className="storefront-about-section-flex-box">
              <div className="storefront-about-section-store-info-card-body-title">
                <div className="storefront-about-section-flex-item">
                  <StoreImgSvg height={20} width={20} />
                </div>
                <div className="storefront-about-section-flex-item-text">
                  Store info
                </div>
              </div>
              <div className="storefront-about-section-store-expand-svg">
                {!isStoreInfoOpen ? (
                  <ExpandMoreSvg height={"20"} width={"20"} />
                ) : (
                  <ExpandLessSvg height={"20"} width={"20"} />
                )}
              </div>
            </div>

            {isStoreInfoOpen && (
              <div className="storefront-about-section-store-info-card-body-content">
                {deliveryDiscount && deliveryDiscount > 0 ? (
                  <div className="storefront-about-section-store-info-card-body-content-item">
                    <div className="storefront-about-section-store-info-card-body-content-item-title-icon">
                      <DeliverySvg height={20} width={20} color="#6B6B6B" />
                    </div>
                    <div className="storefront-about-section-store-info-card-body-content-item-title-text">
                      Rs. {deliveryDiscount} off on delivery fees!
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="storefront-about-section-store-info-card-body-content-item">
                  <div className="storefront-about-section-store-info-card-body-content-item-title-icon">
                    <StoreIdImgSvg height={20} width={20} color="#6B6B6B" />
                  </div>
                  <div className="storefront-about-section-store-info-card-body-content-item-title-text">
                    Store unique ID - {storeId}
                  </div>
                </div>
                <div className="storefront-about-section-store-info-card-body-content-item">
                  <div className="storefront-about-section-store-info-card-body-content-item-title-icon">
                    <PhoneIconSvg height={20} width={20} color="#6B6B6B" />
                  </div>
                  <div className="storefront-about-section-store-info-card-body-content-item-title-text">
                    {phoneNumber || "N/A"}
                  </div>
                </div>
                <div className="storefront-about-section-store-info-card-body-content-item">
                  <div className="storefront-about-section-store-info-card-body-content-item-title-icon">
                    <EmailSvg height={20} width={20} />
                  </div>
                  <div className="storefront-about-section-store-info-card-body-content-item-title-text">
                    {email || "N/A"}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          className="storefront-about-section-store-info-card"
          onClick={() => toggleSection("returnPolicy")}>
          <div className="storefront-about-section-store-info-card-body">
            <div className="storefront-about-section-flex-box">
              <div className="storefront-about-section-store-info-card-body-title">
                <div className="storefront-about-section-flex-item">
                  <ReturnArrowSvg height={20} width={20} />
                </div>
                <div className="storefront-about-section-flex-item-text">
                  Return policy
                </div>
              </div>
              <div className="storefront-about-section-store-expand-svg">
                {!isReturnPolicyOpen ? (
                  <ExpandMoreSvg height={"20"} width={"20"} />
                ) : (
                  <ExpandLessSvg height={"20"} width={"20"} />
                )}
              </div>
            </div>
          </div>
          {isReturnPolicyOpen && (
            <div className="storefront-about-section-store-info-card-body-content">
              <span className="storefront-about-section-return-policy-description">
                Acceptable reasons to claim an exchange
              </span>
              <ul className="storefront-about-section-return-policy-list">
                <li>A defective product</li>
                <li>Missing item / piece</li>
                <li>No returns / refunds only exchange!</li>
              </ul>
              <span className="storefront-about-section-return-policy-description">
                No cancellations once order is accepted and processed. Transit
                delays are not in our hands; however, we will help you to get
                the order delivered soon!
              </span>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
