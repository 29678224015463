import React, {useEffect, useState} from "react";
import {setAuthentication} from "../../../features/Authentication/AuthenticationSlice";
import cookieUtils from "../../../utils/cookies";
import {AppDispatch, RootState} from "../../../interceptors/store";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";
import {getOrderByOrderRefIdThunk} from "../../../features/Order/OrderSlice";
import {OrderProduct} from "../../../features/Order/OrderInterface";
import OrderStatusCard from "./components/OrderStatusCard/OrderStatusCard";
import "./OrderDetailsPage.style.css";
import OrderDetailsPageSkeleton from "./components/Skeleton/OrderDetailsPageSkeleton";
import OrderProductCard from "../../../components/OrderProductCard/OrderProductCard";

const OrderDetailsPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  const params = useParams<{orderId: string}>();
  const {data} = useSelector((state: RootState) => state.order.RefOrder);

  useEffect(() => {
    if (cookieUtils.getCookie("token")) {
      dispatch(setAuthentication(true));
    } else {
      navigate("/");
    }
  }, [dispatch, navigate]);

  // useEffect(() => {
  //   if (orderId) {
  //     getOrderDetails();
  //   }
  // }, [dispatch, orderId, orderItems]);

  // const getOrderDetails = async () => {
  //   setLoading(true);
  //   await dispatch(getOrderByOrderIdThunk({orderId: orderId ?? ""})); // Fetch order details
  //   setLoading(false);
  // };

  useEffect(() => {
    if (params?.orderId) {
      getOrderByOrderRefId();
    }
  }, [dispatch, params?.orderId]);

  const getOrderByOrderRefId = async () => {
    setLoading(true);
    try {
      const action = await dispatch(
        getOrderByOrderRefIdThunk({orderRefId: params?.orderId ?? ""}),
      );
      if (getOrderByOrderRefIdThunk.fulfilled.match(action)) {
        // success
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      console.log("error", err);
    }
  };

  return (
    <div className="order-details-page-container">
      <div className="order-details-heading">Order details</div>
      {loading ? (
        // Skeleton loader while loading the orders
        Array.from({length: 5}).map((_, index) => (
          <OrderDetailsPageSkeleton key={`stream-${index}`} />
        ))
      ) : (
        <div className="order-details-page-content-container">
          <OrderStatusCard
            orderItemCount={data?.order_item_details.length}
            orderNum={data?.order_ref_id}
            buyerDetails={data?.buyer_details}
            selfShipDetails={
              Object.keys(
                data?.order_item_details[0]?.self_shipping_details || {},
              ).length > 0
            }
            statusType="OrderDetails"
            totalItems={data?.order_item_details.length}
            priceIncludingGST={data?.total_item_amount}
            discount={
              (data?.total_item_amount ?? 0) - (data?.discounted_amount ?? 0)
            }
            deliveryCharge={data?.delivery_charges}
            orderTotal={data?.net_amount}
            invoiceUrl={
              data?.order_item_details[0]?.self_shipping_details?.invoice_url
            }
          />
          {data?.order_item_details.map((item: OrderProduct) => (
            <div className="order-status-product-container" key={item?.id}>
              <OrderProductCard
                key={item?.id}
                productName={item?.product_name}
                productDescription={item?.product_description}
                productQuantity={item?.quantity}
                variants={item?.variants}
                productImage={item?.product_image_details[0]}
              />
              <div className="order-status-view-order-button">
                <div
                  className="order-status-view-order-button-btn"
                  onClick={() => {
                    navigate(`/orders/status/${item?.id}`);
                  }}>
                  View status
                </div>
              </div>
            </div>
          ))}
          <a
            className="orderdetailspage-alert-header-container"
            href="https://seller.zooplive.com/contact/"
            target="_blank"
            rel="noopener noreferrer" // For security, when using target="_blank"
          >
            <div className="orderdetailspage-alert-header">
              If you have any queries, please contact us here
            </div>
          </a>
        </div>
      )}
    </div>
  );
};

export default OrderDetailsPage;
