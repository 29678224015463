import React from "react";

export interface DropdownUpSvgprops {
  width: number;
  height: number;
  color?: string;
}

export const DropdownUpSvg: React.FC<DropdownUpSvgprops> = ({
  width,
  height,
  color,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 7" fill="none">
      <path
        d="M5.99999 2.43842L1.92689 6.51153C1.78844 6.64999 1.6144 6.72083 1.40479 6.72403C1.19519 6.72723 1.01795 6.65639 0.873087 6.51153C0.728204 6.36666 0.655762 6.19103 0.655762 5.98463C0.655762 5.77821 0.728204 5.60257 0.873087 5.4577L5.36731 0.963475C5.4609 0.869892 5.55961 0.803867 5.66346 0.765401C5.7673 0.726951 5.87947 0.707726 5.99999 0.707726C6.1205 0.707726 6.23268 0.726951 6.33651 0.765401C6.44036 0.803867 6.53908 0.869892 6.63266 0.963475L11.1269 5.4577C11.2654 5.59617 11.3362 5.7702 11.3394 5.9798C11.3426 6.18942 11.2718 6.36666 11.1269 6.51153C10.982 6.65639 10.8064 6.72883 10.6 6.72883C10.3936 6.72883 10.218 6.65639 10.0731 6.51153L5.99999 2.43842Z"
        fill={color ? color : "#938E9E"}
      />
    </svg>
  );
};
