import React from "react";

export interface SvgProps {
  height: number | string;
  width: number | string;
  color?: string;
}

const ExpandLessSvg: React.FC<SvgProps> = ({height, width, color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 20 20">
      <g clipPath="url(#clip0_60_31191)">
        <path
          fill={color ? color : "#AFAFAF"}
          d="m3.087 11.837 6.25-6.25a.94.94 0 0 1 1.328 0l6.25 6.25a.94.94 0 0 1-1.328 1.328L10 7.578l-5.587 5.588a.94.94 0 0 1-1.328-1.328z"></path>
      </g>
      <defs>
        <clipPath id="clip0_60_31191">
          <path fill="#fff" d="M20 20H0V0h20z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ExpandLessSvg;
